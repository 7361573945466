/** @format */

import { TextField, TextFieldProps } from "@mui/material";
import { handleCPFChange } from "../../helpers/handleCPFChange";
import { getIn } from "formik";
import { ClearCPF } from "../../helpers/clearString";
import { GenericError } from "../../helpers/genericError";
import { GetInstance } from "../../services/api";

interface FieldProps extends Omit<TextFieldProps, "ref"> {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
}

export function InputCPFField(props: FieldProps) {
  const { formik, id, label, disabled } = props;
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);
  return (
    <TextField
      {...props}
      fullWidth
      id={id}
      placeholder={"000.000.000-00"}
      inputProps={{
        maxLength: 14,
      }}
      disabled={disabled}
      name={id}
      label={label}
      value={value}
      onChange={(event: any) => handleCPFChange(event, formik, id)}
      onBlur={(e) => {
        formik.handleBlur(e);
        GetInstance(null, "/users_cpf/" + ClearCPF(value))
          .then((r: any) => {
            if (!r.data.data.valid) {
              formik.setFieldError(id, "CPF inválido!");
            }
          })
          .catch((e: any) => GenericError(e));
      }}
      error={Boolean(error)}
      helperText={error}
    />
  );
}
