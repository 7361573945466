/** @format */

import { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import {
  ClearCurrency,
  ClearCurrencyInt,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { LabelFormat } from "../../../../../helpers/labelFormat";
import { GradeItemPositionPercentage } from "../../../../../helpers/gradeitemPositionPercentage";

export function FormEdit({ formik, data, edit, companySettings }: any) {
  const user = useSelector((state: any) => state.user.data);
  const isRh = user.role === "rh";
  const isAccountant = user.role === "accountant";

  const { fields: requiredFields = [] } =
    companySettings?.transfer_requisitions_rules || {};

  const onChangePosition = (position_id: any) => {
    const position = data.options.positions.find(
      (position: any) => position.id === position_id
    );
    formik.setFieldValue("position_id", position_id);
    formik.setFieldValue("department_id", position.department_id);
    formik.setFieldValue("job_role_id", position.job_role_id);
    formik.setFieldValue("sector_id", position.sector_id);
    formik.setFieldValue("cost_center_id", position.cost_center_id);
    formik.setFieldValue("business_unit_id", position.business_unit_id);
  };

  useEffect(() => {
    const jobRole = data.options?.job_role?.find(
      (item: any) => item.value === formik.values?.job_role_id
    );

    const salary = Number(
      ClearCurrencyInt(formik.values?.new_remuneration_value)
    );
    const gradeItemsPercentage = GradeItemPositionPercentage(
      jobRole?.grade_items,
      salary
    );

    if (
      formik.values?.grade_item_position_percentage !== gradeItemsPercentage
    ) {
      formik.setFieldValue(
        "grade_item_position_percentage",
        gradeItemsPercentage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values?.job_role_id,
    formik.values?.new_remuneration_value,
    data.options?.job_role,
  ]);

  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} gap={2}>
        <SelectField
          formik={formik}
          disabled
          id={"with_position"}
          label="Transferência com a posição ?"
          options={aviso_e_social}
        />
        <InputDateField
          formik={formik}
          disabled
          id="transfer_date"
          label="Data da movimentação"
        />
        <InputTextField
          formik={formik}
          disabled
          id="new_manager_name"
          label="Gerente de destino"
        />
        <SelectField
          formik={formik}
          disabled={!edit}
          id={"with_promotion"}
          label="Transferência com promoção ?"
          options={aviso_e_social}
        />

        {!formik.values.with_position ? (
          <AutoCompleteField
            options={data.options.positions.map((item: any) => ({
              value: item.id,
              label: `${item.code} - ${item.job_role_name}`,
            }))}
            formik={formik}
            id="position_id"
            disabled={!edit}
            label="Posição destino"
            onChange={(event: any, newValue: any) =>
              onChangePosition(newValue?.value)
            }
          />
        ) : (
          <TextField
            fullWidth
            disabled
            value={`${data.transfer_data.position_code} - ${data.transfer_data.position_job_role_name}`}
            label="Posição destino"
          />
        )}
        <AutoCompleteField
          formik={formik}
          id="department_id"
          disabled={!edit}
          label={LabelFormat("department") + " destino"}
          options={data.options.department}
        />
        <AutoCompleteField
          formik={formik}
          id="job_role_id"
          disabled={!edit}
          label="Cargo destino"
          options={data.options?.job_role?.map((jobRole: any) => ({
            label: jobRole?.code
              ? `${jobRole?.code} - ${jobRole.label}`
              : jobRole.label,
            value: jobRole.value,
          }))}
        />
        <AutoCompleteField
          formik={formik}
          id="business_unit_id"
          disabled={!edit}
          labelcode={true}
          label="Un. de negócio destino"
          options={data.options.business_unit}
        />
        <AutoCompleteField
          formik={formik}
          id="sector_id"
          disabled={!edit}
          labelcode={true}
          label="Setor / Lotação destino"
          options={data.options.sector}
        />
        <AutoCompleteField
          formik={formik}
          id="cost_center_id"
          disabled={!edit}
          labelcode={true}
          label="Centro de custo destino"
          options={data.options.cost_center}
        />
        <InputTextField
          formik={formik}
          id="new_remuneration_percentage"
          type={"number"}
          disabled={!edit}
          label="Porcentagem (%)"
          onChange={(event: any) => {
            formik.setFieldValue(
              "new_remuneration_value",
              MaskCurrency(
                ((parseFloat(event.target.value) + 100) *
                  parseFloat(formik.values.actual_salary)) /
                  100
              )
            );
          }}
        />
        <InputCurrencyField
          formik={formik}
          id="new_remuneration_value"
          disabled={!edit}
          label="Salário futuro"
          onChange={(event: any) => {
            formik.setFieldValue(
              "new_remuneration_percentage",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
          }}
        />

        <InputTextField
          formik={formik}
          id="grade_item_position_percentage"
          disabled={true}
          label="Posicionamento da faixa"
        />

        {(isRh || isAccountant) && (
          <>
            {requiredFields.includes("has_medical_examination") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_medical_examination"}
                label="Exame médico ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_benefits_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_benefits_change"}
                label="Mudança de benefícios ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_union_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_union_change"}
                label="Mudança de sindicato ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_union_change") &&
              formik?.values?.has_union_change && (
                <AutoCompleteField
                  formik={formik}
                  disabled={!edit}
                  labelcode={true}
                  id="union_id"
                  label="Sindicato"
                  options={data.options.union}
                />
              )}
            {requiredFields.includes("has_work_schedule_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_work_schedule_change"}
                label="Mudança na jornada de trabalho?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_work_schedule_change") &&
              formik?.values?.has_work_schedule_change && (
                <AutoCompleteField
                  formik={formik}
                  disabled={!edit}
                  labelcode={true}
                  id="work_schedule_id"
                  label="Jornada de trabalho"
                  options={data.options.work_schedule}
                />
              )}
          </>
        )}
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          id="observations"
          label="Comentários"
          multiline={true}
          rows={4}
          disabled={!edit}
        />
      </Grid>
    </>
  );
}
