/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { onSubmit } from "../helpers/onSubmit";
import { DialogNew } from "../helpers/dialogNew";

export function DismissalsNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [valide, setValide] = useState(false);
  const [user, setUser]: any = useState({});
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    dismissal_reasons: [],
    dismissal_types: [],
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      comment: "",
      dismissal_reason_id: "",
      dismissal_type_id: "",
      files: [],
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      onSubmit(values, dispatch, id, formik, router);
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/dismissals_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);

        setOptions({
          dismissal_reasons:
            response.data.data.dismissal_reasons.group_1.filter(
              (d: any) => d.associated
            ),
          dismissal_types: response.data.data.dismissal_types.group_1.filter(
            (d: any) => d.associated
          ),
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogNew open={open} setOpen={setOpen} formik={formik} />
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/dismissals" },
            {
              label: "Desligamentos",
              value: "/request/dismissals",
            },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={user}
          instruction={
            "Nessa solicitação você está criando seu desligamento da compania. Preencha os campos abaixo e anexe a carta de desligamento."
          }
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar desligamento
              </Typography>
            </Grid>
            <Form formik={formik} options={options} />
            <InputFile formik={formik} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                color='error'
                endIcon={<Cancel />}>
                Cancelar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={async () => {
                  setValide(true);
                  const errors = await formik.validateForm();
                  if (Object.keys(errors).length === 0) {
                    setOpen(true);
                  } else {
                    formik.setTouched(
                      Object.keys(errors).reduce((acc: any, key) => {
                        acc[key] = true;
                        return acc;
                      }, {})
                    );
                  }
                }}  
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    
    </form>
  );
}
