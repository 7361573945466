/** @format */

import { LabelFormat } from "../../../../../helpers/labelFormat";

export const optionsType = [
  {
    label: "Telefone",
    value: "phone",
  },
  {
    label: "E-mail",
    value: "email",
  },
  {
    label: "Nome",
    value: "name",
  },
  {
    label: "Cargo",
    value: "job_role_name",
  },
  {
    label: LabelFormat("business_unit"),
    value: "unit_business",
  },
  {
    label: "Botões",
    value: "icons",
  },
  {
    label: "Foto",
    value: "photo",
  },
];
