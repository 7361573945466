import { Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateLoading } from "../../../../../redux/reducers/common";
import { toast } from "react-toastify";
import { GenericError } from "../../../../../helpers/genericError";
import {
  PostInstante,
  PostInstanteFormData,
} from "../../../../../services/api";
import {
  time_type,
  validationSchema,
} from "./constants";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { GetYears, GetTimes } from "../../../../../helpers/getYears";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { Cancel, UploadFile } from "@mui/icons-material";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { useState } from "react";
import { filesAccount } from "../../../../../config/constants";

export function FormComponent({ data }: any) {
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      upload_type: "1",
      document_type: null,
      time_type: null,
      files: [],
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values: any) => {
      const sendValues: any = { ...values };
      sendValues.date = values?.time;
      delete sendValues?.upload_type;
      delete sendValues?.time_type;
      delete sendValues?.time;
      dispatch(updateLoading(true));
      PostInstante(null, values, "/my_documents")
        .then((response: any) => {
          const formData: any = new FormData();
          formik.values.files.map((file: any) => formData.append("file", file));
          PostInstanteFormData(
            null,
            formData,
            "/my_documents" + response.data.data.record.uuid + "/upload_file"
          )
            .then((response: any) => {
              dispatch(updateLoading(false));
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              GenericError(e, dispatch);
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <Grid width={"100%"}>
      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ p: 2, gap: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Upload de documento
            </Typography>
          </Grid>
          <Grid>
            <Grid
              mt={2}
              gap={2}
              gridTemplateColumns={"1fr 1fr 1fr"}
              display={"grid"}
            >
              <AutoCompleteField
                formik={formik}
                label="Tipo de documento"
                onChange={(e: any, newValue: any) => {
                  formik.setFieldValue("time_type", newValue.calendar);
                }}
                id="document_type"
                options={filesAccount.filter(
                  (option: any) =>
                    data.options.document_types.indexOf(option.value) !== -1
                )}
              />
              <AutoCompleteField
                formik={formik}
                disabled
                label="Tipo do período"
                id="time_type"
                options={time_type}
              />
              <AutoCompleteField
                formik={formik}
                label="Período"
                disabled={formik.values.time_type === null}
                id="time"
                options={
                  formik.values.time_type === "year" ? GetYears() : GetTimes()
                }
              />
            </Grid>
            <InputFile formik={formik} limit={1} />
          </Grid>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <ButtonComponent
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              color="error"
              endIcon={<Cancel />}
            >
              Cancelar
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              type="submit"
              onClick={() => {
                setValide(true);
              }}
              endIcon={<UploadFile />}
            >
              Fazer Upload
            </ButtonComponent>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
}
