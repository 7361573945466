/** @format */

import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";

import {
  AddCircleOutlined,
  CheckCircle,
  Edit,
  HighlightOff,
  Restore,
  Save,
  Undo,
} from "@mui/icons-material";
import { IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { GenericError } from "../../../../../helpers/genericError";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { EmptyData } from "../../../../../components/emptyData";
import { DialogModal } from "./helpers/dialogModal";
import { validationSchema } from "./helpers/constants";

export function JobRoleLevels() {
  const [items, setItems] = useState<any[]>([]);
  const [valide, setValide] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState({ open: false, type: "" });
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      name: "",
      status: "active",
      id: null,
      index: null,
    },
    validateOnChange: valide,
    validateOnBlur: valide,
    validationSchema: validationSchema,
    onSubmit: () => {
      if (openModal.type === "create") {
        const newLevel = {
          name: formik.values.name,
          status: formik.values.status,
        };
        setItems((prevItems) => [...prevItems, newLevel]);
      } else {
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[formik.values.index] = {
            id: formik.values.id,
            name: formik.values.name,
            status: formik.values.status,
          };
          return updatedItems;
        });
      }
      setOpenModal({ open: false, type: "" });
      formik.setValues({ name: "", id: null, index: null });
    },
  });

  const handleClickEdit = (item: any, index: number) => {
    formik.setValues({
      description: item.description,
      name: item.name,
      id: item.id,
      status: item.status,
      index,
    });
    setOpenModal({ open: true, type: "edit" });
  };

  const handleSendData = () => {
    dispatch(updateLoading(true));
    PostInstante(null, { job_role_levels: items }, "/job_role_levels")
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/job_role_levels")
      .then((response: any) => {
        if (Array.isArray(response?.data?.data)) setItems(response.data.data);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {openModal.open && (
        <DialogModal
          formik={formik}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setValide={setValide}
        />
      )}
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Cargos", value: "/jobRoles/levels" },
          { label: "Níveis de cargos" },
        ]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            endIcon={<AddCircleOutlined />}
            onClick={() => {
              setOpenModal({ open: true, type: "create" });
            }}
          >
            Novo nível
          </ButtonComponent>
        }
      />
      <InfoComponent instruction="Cadastre todas as os níveis que serão utilizados nos cargos" />
      {items.length === 0 ? (
        <EmptyData />
      ) : (
        <>
          <Paper>
            <Grid container p={2} mt={2}>
              <Grid
                width={"100%"}
                display={"grid"}
                gridTemplateColumns={"1fr 2fr 1fr"}
              >
                <Grid item alignItems={"center"}>
                  <Typography fontWeight={800}>Ações</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={800}>Nome</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={800}>Status</Typography>
                </Grid>
              </Grid>
              <Grid item width={"100%"}>
                <Divider />
              </Grid>
              {items.map((item, index) => (
                <Grid
                  width={"100%"}
                  display={"grid"}
                  pt={2}
                  gridTemplateColumns={"1fr 2fr 1fr"}
                  key={index}
                >
                  <Grid item gap={2} display={"flex"}>
                    <Tooltip title={"Editar"}>
                      <IconButton
                        variant="ghost"
                        radius="full"
                        onClick={() => handleClickEdit(item, index)}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={item.status === "active" ? "Desativar" : "Ativar"}
                    >
                      <IconButton
                        variant="ghost"
                        radius="full"
                        onClick={() =>
                          setItems(
                            items.map((x, i) =>
                              i === index
                                ? {
                                  ...x,
                                  status:
                                    x.status === "active"
                                      ? "inactive"
                                      : "active",
                                }
                                : x
                            )
                          )
                        }
                      >
                        {item.status === "active" ? (
                          <HighlightOff />
                        ) : (
                          <CheckCircle />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>{item.name}</Grid>
                  <Grid item>
                    <Chip
                      size={"small"}
                      color={item?.status === "active" ? "success" : "error"}
                      label={FormatStatus(item.status)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <ButtonComponent
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              endIcon={<Undo />}
            >
              Voltar
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              color={"info"}
              onClick={() => {
                window.location.reload();
              }}
              endIcon={<Restore />}
            >
              Limpar modificações
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              endIcon={<Save />}
              onClick={handleSendData}
            >
              Salvar modificações
            </ButtonComponent>
          </Grid>
        </>
      )}
    </Box>
  );
}
