/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import {
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { position_type } from "../../../../../config/constants";
import { ClearCurrencyInt, FormatCurrency } from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { LabelFormat } from "../../../../../helpers/labelFormat";
import { ConfirmSalaryDialog } from "../helpers/confirmSalaryDialog";
import { getSalaryRangeByJobRole } from '../helpers/getSalaryRangeByJobRole';

export function PositionNew() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.data);
  const [options, setOptions] = useState<any>({
    manager: [],
    business_unit: [],
    department: [],
    cost_center: [],
    reason_solicitation: [],
    job_role: [],
  });
  const [salaryDialogSettings, setSalaryDialogSettings]: any = useState({});

  const [valide, setValide] = useState(false);
  const formik = useFormik({
    initialValues: {
      function: "",
      salary: "",
      quantity: "",
      description: "",
      business_unit_id: "",
      cost_center_id: "",
      reason_solicitation_id: "",
      manager_id: user.role === "manager" ? user.id : null,
      department_id: "",
      job_role_id: "",
      position_type: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = () => {
        setValide(false);
        dispatch(updateLoading(true));
        PostInstante(
          null,
          {
            ...values,
            position_type: Number(values.position_type),
            salary: ClearCurrencyInt(values.salary),
          },
          "/new_positions"
        )
          .then((response: any) => {
            dispatch(updateLoading(false));
            window.history.back();
            toast.success(response?.data?.message);
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      }

      const { maximum, minimum } = getSalaryRangeByJobRole(formik.values.job_role_id, options?.job_role);
      if (user.role === "rh" &&
        ((minimum && Number(ClearCurrencyInt(`${minimum}`)) > Number(ClearCurrencyInt(formik.values.salary))) ||
          (maximum && Number(ClearCurrencyInt(`${maximum}`)) < Number(ClearCurrencyInt(formik.values.salary))))) {
  
        setSalaryDialogSettings({
          open: true,
          maximum: FormatCurrency({ target: { value: `${maximum}` } }),
          minimum: FormatCurrency({ target: { value: `${minimum}` } }),
          onConfirm: () => request()
        });
      }
      else {
        request();
      }
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/new_positions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          ...response.data.data.options,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/reason_solicitations")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          reason_solicitation: response.data,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.role === "manager") {
      formik.setFieldValue("manager_id", user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Requisições", value: "/request/position" },
            { label: "Posições", value: "/request/position" },
            { label: "Nova solicitação" },
          ]}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar nova posição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <AutoCompleteField
                disabled={user.role === "manager"}
                id="manager_id"
                label="Gestor"
                formik={formik}
                options={options.manager.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
              <AutoCompleteField
                id="department_id"
                label={LabelFormat("department")}
                formik={formik}
                options={options.department.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="cost_center_id"
                label="Centro de custo"
                formik={formik}
                options={options.cost_center.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.description}`,
                }))}
              />
              <InputTextField formik={formik} id="function" label="Função" />
              <InputTextField
                type="number"
                formik={formik}
                id="quantity"
                label="Quantidade"
              />
              <AutoCompleteField
                id="business_unit_id"
                label={LabelFormat("business_unit")}
                formik={formik}
                options={options.business_unit?.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />
              {user.role === "rh" && (
                <>
                  <AutoCompleteField
                    id="job_role_id"
                    label="Cargo"
                    onChange={(_e: any, newValue: any) => {
                      const { minimum = null } = getSalaryRangeByJobRole(newValue.value, options?.job_role);
                      formik.setFieldValue('salary', FormatCurrency({ target: { value: `${minimum}` } }));
                    }}
                    formik={formik}
                    options={options?.job_role?.map((jobRole: any) => ({
                      ...jobRole,
                      label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}` : jobRole.label,
                      value: jobRole.value,
                    }))}
                  />
                  <InputCurrencyField
                    disabled={!formik?.values?.job_role_id}
                    formik={formik}
                    id="salary"
                    label="Remuneração"
                  />
                </>
              )}
              <AutoCompleteField
                id="reason_solicitation_id"
                label="Motivo da solicitação"
                formik={formik}
                options={options.reason_solicitation?.map((item: any) => ({
                  value: item.id,
                  label: `${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="position_type"
                label="Tipo de posição"
                formik={formik}
                options={position_type}
              />
            </Grid>

            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <InputTextField
                formik={formik}
                multiline={true}
                rows={4}
                id="description"
                label="Responsabilidades"
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>

              <ButtonComponent
                variant={"contained"}
                onClick={formik.handleSubmit}
                endIcon={<AddCircle />}
              >
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ConfirmSalaryDialog
        open={salaryDialogSettings?.open}
        onConfirm={salaryDialogSettings?.onConfirm}
        minSalary={salaryDialogSettings?.minimum}
        maxSalary={salaryDialogSettings?.maximum}
        onClose={() => setSalaryDialogSettings({})} />
    </form>
  );
}
