/** @format */

import {Grid, TextField, Typography} from "@mui/material";
import {InputTextField} from "../../../../../components/inputs/inputTextField";
import axios from "axios";
import {toast} from "react-toastify";
import {handleCEPChange} from "../../../../../helpers/handleCEPChange";
import {useEffect} from "react";

export function Form({formik, edit}: any) {
  useEffect(() => {
    if (formik.values.postal_code.replace(/\D/g, "").length === 8) {
      axios
      .get(
        `https://viacep.com.br/ws/${formik.values.postal_code.replace(
          /\D/g,
          ""
        )}/json/`
      )
      .then((response) => {
        formik.setFieldValue("street", response.data.logradouro);
        formik.setFieldValue("city", response.data.localidade);
        formik.setFieldValue(
          "neighborhood",
          response.data.bairro
        );
        formik.setFieldValue("state", response.data.uf);
        formik.setFieldTouched("street");
        formik.setFieldTouched("city");
        formik.setFieldTouched("neighborhood");
        formik.setFieldTouched("state");
      })
      .catch(() => {
        toast.error(
          "CEP não encontrado. Verifique o CEP digitado."
        );
      });
    } else {
      formik.setFieldValue("city", "");
      formik.setFieldValue("neighborhood", "");
      formik.setFieldValue("state", "");
      formik.setFieldTouched("street");
      formik.setFieldTouched("city");
      formik.setFieldTouched("neighborhood");
      formik.setFieldTouched("state");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.postal_code]);

  return (
    <>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr"}
        display={"grid"}
        gap={2}>
        <InputTextField
          formik={formik}
          id='code'
          label='Código externo'
        />
        <InputTextField
          formik={formik}
          id='description'
          label='Nome'
        />
        <InputTextField
          formik={formik}
          id='cnpj'
          label='CNPJ da instituição'
        />
      </Grid>
      <Grid mt={2} id={"address"}>
        <Typography fontSize={16} fontWeight={500}>
          Endereço da instituição
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 2fr "} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='postal_code'
          placeholder={"00.000-000"}
          label='CEP'
          value={formik.values.postal_code}
          onChange={(event: any) => {
            formik.setFieldValue("postal_code", handleCEPChange(event));
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
          helperText={formik.touched.zip_code && formik.errors.zip_code}
        />
        <InputTextField
          formik={formik}
          disabled
          id='street'
          label='Logradouro'
        />
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 2fr 2fr 2fr 1fr"}
        mt={2}
        display={"grid"}
        gap={2}>
        <InputTextField
          formik={formik}
          id='number'
          type={"number"}
          label='Número'
        />
        <InputTextField
          formik={formik}
          id='complement'
          label='Complemento'
        />

        <InputTextField
          formik={formik}
          disabled
          id='neighborhood'
          label='Bairro'
        />
        <InputTextField formik={formik} disabled id='city' label='Cidade'/>
        <InputTextField formik={formik} disabled id='state' label='UF'/>
      </Grid>
    </>

  );
}
