import { UploadFileOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../components/buttonComponent";
import { useNavigate } from "react-router-dom";
import { TabsComponent } from "../../../components/tabsComponent";
import { TabUpload } from "./helpers/tabUpload";
import { TabUploadError } from "./helpers/tabUploadError";

export function Upload() {
  const router = useNavigate();

  return (
    <Grid container>
      <BreadcrumbsComponent
        links={[{ label: "Inicio" }, { label: "Importações" }]}
        buttons={
          <ButtonComponent
            endIcon={<UploadFileOutlined />}
            variant={"contained"}
            onClick={() => {
              router("/upload/new");
            }}
          >
            Nova importação
          </ButtonComponent>
        }
      />
      <TabsComponent
        tabs={[
          { label: "Importações", component: <TabUpload /> },
          { label: "Documentos com erro", component: <TabUploadError /> },
        ]}
        keyString={"upload"}
      />
    </Grid>
  );
}
