/** @format */

import { Grid } from "@mui/material";

import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { TabsComponent } from "../../../components/tabsComponent";
import { ChangePassword } from "./changePassword";
import { EmailSettings } from "./emailSettings";
import { PhotoPage } from "./photo";
import { SecretAnswers } from "./secretAnswers";
import { OrganizationalSettings } from "./organizational";

export function SettingsAccount() {
  return (
    <Grid container gap={4}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio", value: "/" },
            { label: "Configurações da conta" },
          ]}
        />
        <TabsComponent
          keyString="settingsAccount"
          tabs={[
            {
              label: "Alteração de senha",
              component: <ChangePassword />,
            },
            {
              label: "Perguntas secretas",
              component: <SecretAnswers />,
            },
            {
              label: "Notificações por e-mail",
              component: <EmailSettings />,
            },
            {
              label: "Foto de perfil",
              component: <PhotoPage />,
            },
            {
              label: "Organograma",
              component: <OrganizationalSettings />,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
