import { Grid, Tooltip } from "@mui/material";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { Block, Download, Undo } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import { optionsTable } from "../../../../config/optionsTable";
import { columns } from "../helpers/constants";
import { Filters } from "../helpers/filters";
import { useFormik } from "formik";
import { validationSchema } from "../helpers/constants";
import { GetTimes, GetYears } from "../../../../helpers/getYears";
import { InfoComponent } from "../../../../components/infoComponent";
import { useParams } from "react-router-dom";
import { filesAccount } from "../../../../config/constants";
import { ButtonComponent } from "../../../../components/buttonComponent";
import useUploadView from "../../../../services/protected/hooks/useUploadView";
import { useSelector } from "react-redux";

export function FilesAccountView() {
  let { calendar, id } = useParams();
  const userState = useSelector((state: any) => state.user.data);
  const {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    searchCall,
    setSearch,
  } = useUploadView(userState.uuid);
  const formik: any = useFormik({
    initialValues: {
      times:
        calendar === "year" ? GetYears().splice(1, 1) : GetTimes().splice(0, 3),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const send = {
        times: values.times.map((item: any) => item.value),
        document_type: id ?? "",
      };
      if (send.document_type.length === 0 && send.times.length === 0) {
        setSearch(null);
      } else {
        setSearch(send);
      }
    },
  });
  return (
    <Grid container gap={4}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio", value: "/" },
            { label: "Meus documentos", value: "/filesAccount" },
            {
              label:
                filesAccount.find((item) => item.value === id)?.label ??
                "Tipo de documento",
            },
          ]}
        />
        <InfoComponent instruction="Filtre abaixo pelos periodos que deseja" />
        <Filters formik={formik} calendar={calendar} />
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container gap={1}>
                      {data[dataIndex].file === "Não possui" ? (
                        <Tooltip title={"Arquivo corrompido"}>
                          <Block color="primary" fontSize={"small"} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={"Baixar arquivo"}>
                          <a
                            title={data[dataIndex].file.name}
                            href={data[dataIndex].file.url}
                            target="_blank"
                            style={{
                              padding: 8,
                              fontSize: 12,
                              borderRadius: 24,
                              fontWeight: 800,
                              textDecorationLine: "none",
                              display: "flex",
                              gap: 4,
                              alignItems: "center",
                              cursor: "download",
                            }}
                            download={data[dataIndex].file.name}
                            rel="noreferrer"
                          >
                            <Download color="primary" fontSize={"small"} />
                          </a>
                        </Tooltip>
                      )}
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por tipo de documento",
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page: page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
        <Grid />
        <Grid container gap={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Grid>
  );
}
