
import store from "../redux/store";

export function FormatStatus(value: any, overrideRole: any = undefined) {
  const role = overrideRole || store.getState().user.data.role;

  const statusDict: any = getStatusListByRole(role);
  return statusDict[value] || "Não possui";
}

function getStatusListByRole(role: any) {
  switch (role) {
    case "collaborator":
      return collaboratorStatusList;
    case "manager":
      return managerStatusList;
    case "rh":
      return rhStatusList;
    case "accountant":
      return rhStatusList;
    default:
      return rhStatusList;
  }
}

const rhStatusList = {
  finished: 'Finalizado',
  pending: "Pendente",
  approved: "Aprovado",
  approved_on_hold: "Aprovado aguardando prazo de envio",
  disapproved: "Reprovada",
  archived: "Arquivado",
  active: "Ativo",
  on_leave: "Afastado",
  accepted: "Férias aceita",
  inactive: "Inativo",
  open: "Aberta",
  awaiting_acceptance: "Aguardando aceite",
  scheduled: "Agendada",
  waiting_schedule: "Aguardando agendamento",
  vacations_scheduled: "Férias agendadas",
  vacations_waiting_schedule: "Férias aguardando agendamento",
  returned: "Retornada",
  occupied: "Ocupada",
  hiring: "Em contratação",
  processed: "Processado",
  failed: "Não processado",
  waiting_manager_user: "Aguardando gestor",
  waiting_rh_user: "Aguardando RH",
  waiting_transfer_approval: "Aguardando RH",
  cancelled: "Cancelado",
  completed: "Concluído",
  accounting_return: "Devolução folha",
  accounting_disapproved: "Reprovado folha"
};

const managerStatusList = {
  finished: 'Finalizado',
  pending: "Pendente",
  approved: "Aguardando RH",
  approved_on_hold: "Aguardando RH",
  disapproved: "Reprovada",
  archived: "Arquivado",
  active: "Ativo",
  on_leave: "Afastado",
  accepted: "Férias aceita",
  inactive: "Inativo",
  open: "Aberta",
  awaiting_acceptance: "Aguardando aceite",
  scheduled: "Agendada",
  waiting_schedule: "Aguardando agendamento",
  vacations_scheduled: "Férias agendadas",
  vacations_waiting_schedule: "Férias aguardando agendamento",
  returned: "Retornada",
  occupied: "Ocupada",
  hiring: "Em contratação",
  processed: "Processado",
  failed: "Não processado",
  waiting_manager_user: "Aguardando gestor",
  waiting_rh_user: "Aguardando RH",
  waiting_transfer_approval: "Aguardando RH",
  cancelled: "Cancelado",
  completed: "Concluído",
  accounting_return: "Aguardando RH",
  accounting_disapproved: "Reprovada"
};

const collaboratorStatusList = {
  finished: 'Finalizado',
  pending: "Pendente",
  approved: "Aguardando RH",
  approved_on_hold: "Aguardando RH",
  disapproved: "Reprovada",
  archived: "Arquivado",
  active: "Ativo",
  on_leave: "Afastado",
  accepted: "Férias aceita",
  inactive: "Inativo",
  open: "Aberta",
  awaiting_acceptance: "Aguardando aceite",
  scheduled: "Agendada",
  waiting_schedule: "Aguardando agendamento",
  vacations_scheduled: "Férias agendadas",
  vacations_waiting_schedule: "Férias aguardando agendamento",
  returned: "Retornada",
  occupied: "Ocupada",
  hiring: "Em contratação",
  processed: "Processado",
  failed: "Não processado",
  waiting_manager_user: "Aguardando gestor",
  waiting_rh_user: "Aguardando RH",
  waiting_transfer_approval: "Aguardando RH",
  cancelled: "Cancelado",
  completed: "Concluído",
  accounting_return: "Aguardando RH",
  accounting_disapproved: "Reprovada"
};
