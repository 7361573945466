/** @format */
import * as yup from "yup";
import { FormatCurrency, ClearCurrencyInt } from "../../../../../helpers/formatCurrency";

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "job_role_name", label: "Cargo destino" },
  { name: "created_by_name", label: "Gestor" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = (companySettings: any, user: any, options: any) => {
  const {
    promotion_requisitions_rules = {},
    promotions_min_percent,
    promotions_max_percent
  } = companySettings || {};
  const { fields: requiredFields = [] } = promotion_requisitions_rules;

  return yup.object({
    promote_position: yup.string().nullable().required("Campo é obrigatório"),
    movement_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Campo é obrigatório"),
    target_job_role_id: yup.string().nullable().required("Campo é obrigatório"),
    target_position_id: yup.string().nullable().required("Campo é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .min(promotions_min_percent, `Reajuste não pode ser menor de ${promotions_min_percent}%`)
      .max(promotions_max_percent, `Reajuste não pode ser maior de ${promotions_max_percent}%`)
      .required("Porcentagem é obrigatório"),
    target_salary: yup.string().nullable().required("Campo é obrigatório").test("invalid-salary", "Salário fora da faixa salarial!", (value, context) => {
      const target_job_role = options?.job_role.find((jobRole: any) => 
        context?.options?.context?.target_job_role_id && jobRole.id === context?.options?.context?.target_job_role_id
      );

      const activeItems = target_job_role?.grade_items?.filter((gradeItem: any) => gradeItem?.grade_column?.status === "active")
      if (activeItems?.length > 0) {
        const { minSalary, maxSalary } = activeItems.reduce(
          (acc: any, currentItem: any) => {
            const currentSalary = Number(currentItem.value);

            return ({
              minSalary: Math.min(acc.minSalary, currentSalary),
              maxSalary: Math.max(acc.maxSalary, currentSalary),
            })
          },
          {
            minSalary: Number(activeItems[0].value),
            maxSalary: Number(activeItems[0].value),
          }
        )

        if (Number(ClearCurrencyInt(value)) < Number(ClearCurrencyInt(FormatCurrency({ target: { value: `${minSalary}`}})))){
          return context.createError({ message: `Salário fora da faixa salarial, o valor deve ser superior a ${FormatCurrency({ target: { value: `${minSalary}`}})}`})
        }

        if (Number(ClearCurrencyInt(value)) > Number(ClearCurrencyInt(FormatCurrency({ target: { value: `${maxSalary}`}})))){
          return context.createError({ message: `Salário fora da faixa salarial, o valor deve ser inferior a ${FormatCurrency({ target: { value: `${maxSalary}`}})}`})
        }
      };

      return true;
    }),
    observations: yup.string().nullable().required("Campo é obrigatório"),
    has_medical_examination: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes('has_medical_examination') || requiredIfRole(value, user.role, "rh")),
    has_benefits_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes('has_benefits_change') || requiredIfRole(value, user.role, "rh")),
    has_union_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes('has_union_change') || requiredIfRole(value, user.role, "rh")),
    has_work_schedule_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes('has_work_schedule_change') || requiredIfRole(value, user.role, "rh")),
    union_id: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value, context) => !context.options?.context?.has_union_change || requiredIfRole(value, user.role, "rh")),
    work_schedule_id: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value, context) => !context.options?.context?.has_work_schedule_change || requiredIfRole(value, user.role, "rh")),
  });
}

function requiredIfRole(value: any, role1: string, role2: string) {
  if (role1 === role2 && (!value || value === '')) {
    return false;
  }
  return true;
}

