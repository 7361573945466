/** @format */

import { Restore, Save } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../components/buttonComponent";
import { EmptyData } from "../../../components/emptyData";
import { optionsTable } from "../../../config/optionsTable";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { PostInstante } from "../../../services/api";
import useTableSalary from "../../../services/protected/hooks/useTableSalary";
import { validationSchema } from "./helpers/constants";
import { DialogModal } from "./helpers/dialog";
import { InfoComponent } from "../../../components/infoComponent";
import { ClearCurrencyInt } from "../../../helpers/formatCurrency";

export function TableSalaryDashboardPage() {
  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    values: {},
  });
  const [valide, setValide] = useState<boolean>(false);

  function openModalCallback(values: any) {
    setOpenModal({ open: true, type: "", values: values });
    formik.setValues(values);
  }

  const {
    data,
    columns,
    setItems,
    items,
    searchCall,
    options,
    setOrder,
    page,
    setPage,
    setPerPage,
    perPage,
    total,
  } = useTableSalary(openModalCallback);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      item_value: null,
      grade_id: null,
      grade_column_id: null,
    },
    validateOnChange: valide,
    validateOnBlur: valide,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const index = await items.findIndex(
        (item: any) =>
          item.grade_id === formik.values.grade_id &&
          item.grade_column_id === formik.values.grade_column_id
      );
      const arrayNew = [...items];
      if (index === -1) {
        arrayNew.push({
          item_value: ClearCurrencyInt(formik.values.item_value),
          grade_id: formik.values.grade_id,
          grade_column_id: formik.values.grade_column_id,
        });
        setItems(arrayNew);
      } else {
        arrayNew[index] = {
          ...arrayNew[index],
          item_value: ClearCurrencyInt(formik.values.item_value),
          grade_id: formik.values.grade_id,
          grade_column_id: formik.values.grade_column_id,
        };
        setItems(arrayNew);
      }
      setOpenModal({ open: false, type: "", values: {} });
      setValide(false);
      formik.handleReset();
    },
  });

  const handleSendData = () => {
    dispatch(updateLoading(true));
    PostInstante(null, { grade_items: items }, "/grade_items")
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <Grid container gap={2}>
      {openModal.open && (
        <DialogModal
          formik={formik}
          options={options}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setValide={setValide}
        />
      )}
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Tabela salarial", value: "/tableSalary/dashboard" },
          { label: "Tabela" },
        ]}
      />
      <InfoComponent
        instruction="Para que a tabela funcione corretamente, certifique-se que a siga as regras abaixo."
        instructionMap={[
          "Na primeira faixa precisa ter o menor valor da linha",
          "Na última faixa precisa ter o maior valor da linha",
          "Os valores das faixas precisam ser crescentes",
        ]}
      />
      {data.length === 0 ? (
        <EmptyData />
      ) : (
        <>
          <MUIDataTable
            title={"Tabela salarial"}
            data={data}
            columns={columns}
            options={{
              ...optionsTable,
              searchPlaceholder: "Pesquise por nome",
              download: false,
              onSearchChange: (value: string) => {
                searchCall(value);
              },
              searchAlwaysOpen: false,
              onColumnSortChange: (value: string, direction: string) => {
                setOrder(`${value} ${direction}`);
              },
              count: total,
              page,
              search: false,
              rowsPerPageOptions: [total],
              onChangePage: (page: number) => {
                setPage(page);
              },
              rowsPerPage: perPage,
              onChangeRowsPerPage: (perPage: number) => {
                setPage(0);
                setPerPage(perPage);
              },
            }}
          />
          <Grid container gap={2} mt={1} justifyContent={"end"}>
            <ButtonComponent
              variant={"contained"}
              color={"info"}
              onClick={() => {
                window.location.reload();
              }}
              endIcon={<Restore />}
            >
              Limpar modificações
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              endIcon={<Save />}
              onClick={handleSendData}
            >
              Salvar modificações
            </ButtonComponent>
          </Grid>
        </>
      )}
    </Grid>
  );
}
