/** @format */

import { EmptyData } from "../helpers/emptyData";
import { FormatDate } from "../helpers/formatDate";

export interface VacationProps {
  opt: {
    acquisition_period_start: string;
    acquisition_period_end: string;
    concessive_period_start: string;
    concessive_period_end: string;
  }[];
  formik: any;
}
interface Props {
  acquisition_period_start: string;
  acquisition_period_end: string;
  concessive_period_start: string;
  concessive_period_end: string;
  formik: any;
}

export const vacationInfo = ({
  acquisition_period_start,
  acquisition_period_end,
  concessive_period_start,
  concessive_period_end,
  formik,
}: Props) => {
  return [
    {
      title: "Período aquisitivo",
      value:
        FormatDate(acquisition_period_start) +
        " - " +
        FormatDate(acquisition_period_end),
    },
    {
      title: "Período concessivo",
      value:
        FormatDate(concessive_period_start) +
        " - " +
        FormatDate(concessive_period_end),
    },
    {
      title: "Dias disponíveis",
      value: EmptyData(formik?.values?.days_available ?? 0),
    },
    {
      title: "Dias utilizados",
      value: EmptyData(formik?.values?.days_used ?? 0),
    },
  ];
};
