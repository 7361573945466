/** @format */

import { Edit, Today, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { updateLoading } from "../../../../../redux/reducers/common";

import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { DownloadFile } from "../../../../../components/donwloadFile";
import { InfoComponent } from "../../../../../components/infoComponent";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { GenericError } from "../../../../../helpers/genericError";
import {
  GetInstance,
  PostInstanteFormData,
  PutInstante,
} from "../../../../../services/api";
import {
  validationSchemaAprove,
  validationSchemaEdit,
} from "../helpers/constants";
import { Form } from "../helpers/form";
import { HistoryComponent } from "../helpers/historyComponent";

export function LeaveDetails() {
  const { id } = useParams();
  const [data, setData]: any = useState({});
  const [showDate, setShowDate] = useState(false);
  const [aprove, setAprove] = useState(false);
  const permissions = useSelector((state: any) => state.user.permissions);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.data);
  const hasNewReturnButton =
    data?.solicitation_status === "approved" && user.id === data.created_by_id;
  const isResendable =
    data?.solicitation_status === "returned" && user.id === data.created_by_id;
  const edit =
    (permissions !== null &&
      permissions.indexOf("requestLeaveDetailsEdit") !== -1 &&
      (data?.solicitation_status === "pending" ||
        (user.role === "rh" &&
          (data?.solicitation_status === "waiting_rh_user" ||
            data?.solicitation_status === "accounting_return")))) ||
    isResendable;

  const formik: any = useFormik({
    initialValues: {
      absence_date: null,
      return_date: null,
      e_social_motive: "",
      observations: "",
      files: [],
    },
    validationSchema: aprove ? validationSchemaAprove : validationSchemaEdit,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/absences/${data.uuid}`)
        .then((response: any) => {
          if (values.files.length > 0) {
            const formData = new FormData();
            // eslint-disable-next-line array-callback-return
            formik.values.files.map((file: any) => {
              formData.append("files[]", file);
            });
            PostInstanteFormData(
              null,
              formData,
              "/absences/" +
                response.data.data.absence_item_id +
                "/upload_files"
            )
              .then(() => {
                dispatch(updateLoading(false));
                window.history.back();
                toast.success(response?.data?.message);
              })
              .catch((e: any) => {
                GenericError(e, dispatch);
              });
          } else {
            dispatch(updateLoading(false));
            window.history.back();
            toast.success(response?.data?.message);
          }
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/absences/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
        formik.setValues({
          absence_date: dayjs(formData.absence_date),
          return_date: formData.return_date
            ? dayjs(formData.return_date)
            : null,
          e_social_motive: formData.e_social_motive,
          observations: formData.last_observations,
          files: formData.solicitation_files,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const buttonResend = () => (
    <ActionResend
      id={id}
      url={"absences"}
      hasObservation
      disabled={!formik.isValid}
      onSubmit={(resendActionCallback: any) => {
        dispatch(updateLoading(true));
        PutInstante(null, { ...formik.values }, `/absences/${data.uuid}`)
          .then(() => {
            resendActionCallback();
            dispatch(updateLoading(false));
            window.history.back();
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      }}
    />
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/leave" },
            { label: "Afastamentos", value: "/request/leave" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          user={{
            name: data.collaborator_name,
            job_role_code: data.collaborator_role_code,
            job_role_name: data.collaborator_role,
            status: data.collaborator_status,
            position_name: data.collaborator_position,
            remuneration_value: data.collaborator_remuneration,
          }}
          solicitation={{
            code: data.solicitation_code,
            date: data.solicitation_date,
            created_by: data.created_by_name,
            status: data.solicitation_status,
            action_justification: data.action_justification,
          }}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do afastamento
              </Typography>
            </Grid>
            <Form formik={formik} edit={!edit} showDate={showDate} />
            {edit ? (
              <InputFile formik={formik} />
            ) : (
              <DownloadFile formik={formik} />
            )}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              {hasNewReturnButton && (
                <ButtonComponent
                  variant="outlined"
                  endIcon={<Today />}
                  onClick={() => setShowDate(true)}
                >
                  Nova data de retorno
                </ButtonComponent>
              )}
              {isResendable && buttonResend()}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                status={data?.solicitation_status}
              >
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}
                >
                  Editar
                </ButtonComponent>
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_status}
              >
                <ActionReturned id={id} url={"absences"} />
                <ActionDisapproved id={id} url={"absences"} />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_status}
              >
                <ActionAproved
                  id={id}
                  beforeAction={(onSubmit) => {
                    setAprove(true);
                    formik
                      .validateForm()
                      .then((e: any) => {
                        onSubmit();
                      })
                      .catch(() => {
                        toast.error("Preencha os campos obrigatórios");
                      });
                  }}
                  url={"absences"}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_status}
              >
                {buttonResend()}
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_status}
              >
                <ActionCompleted id={id} url={"absences"} />
                <ActionAccountingDisapproved id={id} url={"absences"} />
              </StatusPermittedByRoleContainer>
            </Grid>
            <HistoryComponent data={data} />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
