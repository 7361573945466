/** @format */
import * as yup from "yup";
import { validationCPF } from "../../../../../helpers/validationCPF";
import { type_of_disability } from "../../../../../config/constants";

export const columns = [
  { name: "solicitation_id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "solicitation_status", label: "Status" },
];

const contractTypeValidation = (value: any, context: any) => {
  const position_type = context.from[0].value.position_type;

  if (value === null) return true;
  if (
    (value === "999" && position_type !== "pj") ||
    (value !== "999" && position_type === "pj")
  ) {
    return false;
  }
  return true;
};

export const validationSchema = yup.object({
  cpf: yup
    .string()
    .nullable()
    .required("CPF é obrigatório")
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  father_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF do pai está inválido inválido!", (cpf) =>
      validationCPF(cpf)
    ),
  mother_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF da mãe está inválido!", (cpf) =>
      validationCPF(cpf)
    ),
  business_unit_contract_type: yup
    .string()
    .nullable()
    .test(
      "invalid-contract_type",
      "Tipo de contrato inválido!",
      (value, context) => contractTypeValidation(value, context)
    ),
  phone: yup.string().nullable().min(15, "Telefone inválido"),
  corporate_phone: yup.string().nullable().min(15, "Telefone inválido"),
  name: yup.string().nullable().required("Nome é obrigatório"),
  birth_date: yup
    .string()
    .nullable()
    .required("Data de nascimento é obrigatório"),
  dependents: yup.array().of(
    yup.object({
      name: yup.string().required("Campo é obrigatório"),
      // mother_name: yup.string().required("Campo é obrigatório"),
      birth_date: yup.string().required("Campo é obrigatório"),
      degree_of_kinship: yup.string().required("Campo é obrigatório"),
      // cpf: yup.string().required("Campo é obrigatório").min(14, "CPF inválido"),
      gender: yup.string().required("Campo é obrigatório"),
      ir: yup.string().required("Campo é obrigatório"),
      family_salary: yup.string().required("Campo é obrigatório"),
      is_disability: yup.string().required("Campo é obrigatório"),
    })
  ),
  emergency_contacts: yup.array().of(
    yup.object({
      name: yup.string().required("Campo é obrigatório"),
      phone: yup
        .string()
        .required("Campo é obrigatório")
        .min(15, "Telefone inválido"),
      relationship_code: yup.string().required("Campo é obrigatório"),
    })
  ),
  pensions: yup.array().of(
    yup.object({
      process_number: yup.string().required("Campo é obrigatório"),
      discount_type: yup.string().required("Campo é obrigatório"),
      discount_value: yup.string().required("Campo é obrigatório"),
      base_calculation: yup.string().required("Campo é obrigatório"),
      init_date: yup.string().required("Campo é obrigatório"),
      final_date: yup.string().required("Campo é obrigatório"),
      bank_code: yup.string().required("Campo é obrigatório"),
      bank_account_type: yup.string().required("Campo é obrigatório"),
      bank_agency: yup.string().required("Campo é obrigatório"),
      bank_account: yup.string().required("Campo é obrigatório"),
      files: yup
        .array()
        .min(1, `Precisa ter 1 anexo`)
        .nullable()
        .required("Arquivo é obrigatório"),
      dependents: yup
        .array()
        .min(1, `Precisa ter 1 dependente`)
        .nullable()
        .required("Dependente é obrigatório"),
    })
  ),
});

export const formats = [
  {
    key: "dependents",
    title: "Dados de dependentes solicitados para alteração",
    label: "Dependente",
  },
  {
    key: "user_stabilities",
    title: "Dados de estabilidade solicitados para alteração",
    label: "Estabilidade",
  },
  {
    key: "emergency_contacts",
    title: "Dados de contato de emergência solicitados para alteração",
    label: "Contato de emergência",
  },
];

export function formatDataDetails(data: any) {
  if (data?.type_of_disability) {
    data.type_of_disability = type_of_disability
      .filter((item: any) => data.type_of_disability.includes(item.value))
      .map((item: any) => item.label)
      .toString()
      .replaceAll(",", ", ");
  }
  if (data?.dependents) {
    data.dependents = data.dependents.map((dep: any) => {
      delete dep?.id;
      delete dep?.created_at;
      delete dep?.edit;
      delete dep?.updated_at;
      delete dep?.user_id;
      delete dep?.dependent_type;
      delete dep?.income_tax;
      return dep;
    });
  }
  if (data?.emergency_contacts) {
    data.emergency_contacts = data.emergency_contacts.map((dep: any) => {
      delete dep?.id;
      delete dep?.created_at;
      delete dep?.edit;
      delete dep?.updated_at;
      delete dep?.user_id;
      delete dep?.dependent_type;
      delete dep?.income_tax;
      return dep;
    });
  }
  if (data?.user_stabilities) {
    data.user_stabilities = data.user_stabilities.map((dep: any) => {
      delete dep?.id;
      delete dep?.created_at;
      delete dep?.edit;
      delete dep?.updated_at;
      delete dep?.user_id;
      return dep;
    });
  }
  return data;
}

export const discount_types = [
  { label: "Percentual", value: "percentual" },
  { label: "Valor fixo", value: "currency" },
];

export const base_calculations = [
  { label: "Salário base", value: "base_salary" },
  { label: "Salário líquido", value: "liquid_salary" },
  { label: "Remuneração bruta", value: "gross_remuneration" },
];
