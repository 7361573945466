/** @format */

import { Delete, Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante, RemoveInstance } from "../../../../../services/api";
import { Form } from "../helpers/form";
import { handleCEPChange } from "../../../../../helpers/handleCEPChange";

export function EducationalInstitutionDetails() {
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      code: "",
      description: "",
      postal_code: "",
      number: "",
      complement: "",
      cnpj: "",
    },
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(id, values, "/educational_institutions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/educationalInstitution");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/educational_institutions/" + id)
      .then((response: any) => {
        formik.setValues({
          description: response.data.data.description,
          code: response.data.data.code,
          postal_code: handleCEPChange({ target: { value: response.data.data.postal_code } }),
          number: response.data.data.number,
          cnpj: response.data.data.cnpj,
          complement: response.data.data.complement,
        });

        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    dispatch(updateLoading(true));
    RemoveInstance(id, "/educational_institutions")
      .then(() => {
        dispatch(updateLoading(false));
        toast.success("Instituição removida com sucesso");
        router("/settings/educationalInstitution");
      })
      .catch((e) => {
        GenericError(e, dispatch);
      });
  }

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/educationalInstitution" },
            { label: "Instituição de ensino" },
          ]}
        />
        <InfoComponent instruction='Nesta tela é permitido a configuração de dias para salvar da auditória.' />

        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Cadastrar nova Instituição de ensino
              </Typography>
            </Grid>
            <Form formik={formik} />
          </Paper>
          <Grid container gap={2} justifyContent={"end"} mt={2}>
            <ButtonComponent
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              endIcon={<Undo />}>
              Voltar
            </ButtonComponent>
            <ButtonComponent
              variant={"outlined"}
              color='error'
              onClick={handleDelete}
              endIcon={<Delete />}>
              Remover
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              onClick={formik.handleSubmit}
              endIcon={<Save />}>
              Salvar modificações
            </ButtonComponent>
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
}
