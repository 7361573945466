/** @format */

import {
  admission_type_e_social,
  banks_e_social,
  contract_type_e_social,
  country_e_social,
  dependents_e_social,
  education_level_e_social,
  gender_e_social,
  level_cnh_e_social,
  marital_status_e_social,
  nature_e_social,
  overtime_type_e_social,
  race_e_social,
  salary_e_social,
  security_type_e_social,
  social_security_regime_e_social,
  status_e_social,
  type_of_account,
} from "../../../../../config/constants";
import { DataFormat } from "../../../../../helpers/emptyData";

export const formatField = (key: any, value: any, options: any) => {
  const fieldKeys: any = {
    marital_status: marital_status_e_social,
    race: race_e_social,
    education_level: education_level_e_social,
    gender: gender_e_social,
    nationality: country_e_social,
    country: country_e_social,
    father_country_of_birth: country_e_social,
    mother_country_of_birth: country_e_social,
    bank_account_type: type_of_account,

    business_unit_contract_type: contract_type_e_social,
    business_unit_job_security_type: security_type_e_social,
    contract_status: status_e_social,
    contract_nature: nature_e_social,
    contract_salary: salary_e_social,
    admission_type: admission_type_e_social,
    social_security_regime: social_security_regime_e_social,
    overtime_type: overtime_type_e_social,
    bank_code: banks_e_social,
    driver_license_quantity: level_cnh_e_social,
    degree_of_kinship: dependents_e_social,

    manager_id: options?.managers || [],
    department_id: options?.departments || [],
    job_role_id: options?.job_role || [],
    union_id: options?.union || [],
    health_insurance_id: options?.health_insurance || [],
    food_voucher_id: options?.food_voucher || [],
    business_unit_id: options?.business_unit || [],
    sector_id: options?.sector || [],
    work_section_id: options?.work_section || [],
    cost_center_id: options?.cost_center || [],
    managerial_cost_center_id: options?.managerial_cost_center || [],
    category_id: options?.work_category || [],
    work_schedule_id: options?.work_schedule || [],
    salary_payment_method_id: options?.salary_payment_method || [],
  };

  const mappedField =
    fieldKeys[key] && fieldKeys[key].find((item: any) => item.value === value);

  return mappedField
    ? `${mappedField.value} - ${mappedField.label}`
    : DataFormat(key,value);
};
