/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { useEffect, useState } from "react";

export function StabilityTypeNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [unions, setUnions] = useState([]);

  const formik: any = useFormik({
    initialValues: {
      description: "",
      duration: "",
      union_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/stability_types")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/stabilityTypes");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/stability_types_form")
      .then((response: any) => {
        dispatch(updateLoading(false));

        setUnions(response.data.data.map((item: any) => (
          { value: item.id, label: item.name })
        ));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Tipos de estabilidade",
              value: "/settings/stabilityTypes",
            },
            { label: "Novo tipo de estabilidade" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar novo grupo
            </Typography>
          </Grid>
          <Form
            formik={formik}
            unions={unions}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
