/** @format */
import * as yup from "yup";
import { LabelFormat } from "../../../../../../../helpers/labelFormat";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "job_role_name", label: "Cargo" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "initial_date", label: "Data do início pretendida" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "department_name", label: LabelFormat("department") },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = (config: any) => {
  const requiredFields = config.userRole === "manager"
    ? config?.companySettings?.personnel_requisitions_rules?.manager_fields ?? []
    : config?.companySettings?.personnel_requisitions_rules?.rh_fields ?? [];

  return yup.object({
    initial_date: yup
      .date()
      .min(new Date(), "Data é igual ou anterior a data de hoje")
      .nullable()
      .test('required-if-company-settings', 'Data é obrigatório',
        (value :any) => !requiredFields.includes('initial_date') || value), 
    job_role_id: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => requiredIfRole(value, config.userRole, "rh")),
    salary: yup
      .string()
      .nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => requiredIfRole(value, config.userRole, "rh")),
    name: yup.string().nullable().required("Nome da função é obrigatório"),
    quantity: yup
      .number()
      .nullable()
      .min(1, "Quantidate mínima: 1")
      .required("Quantidade é obrigatório"),
    recruitment_type: yup.string().required("Esse campo é obrigatório"),
    observations: yup
      .string()
      .nullable()
      .required("Responsabilidades é obrigatório"),
    reason_solicitation_id: yup
      .string()
      .nullable()
      .required("Esse campo é obrigatório"),
    manager_id: yup.string().nullable().required("Esse campo é obrigatório"),
    department_id: yup.string().nullable().required("Esse campo é obrigatório"),
    business_unit_id: yup
      .string()
      .nullable()
      .required("Esse campo é obrigatório"),
    cost_center_id: yup.string().nullable().required("Esse campo é obrigatório"),
  })
};

function requiredIfRole(value: any, role1: string, role2: string) {
  if (role1 === role2 && (!value || value === '')) {
    return false;
  }
  return true;
}
