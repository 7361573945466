/** @format */

import { filesAccount } from "../config/constants";
import { permissionsMock } from "../config/permissionsMock";
import { FormatDate, FormatDateComplete } from "../helpers/formatDate";
import { FormatStatus } from "../helpers/formatStatus";
import { FormatCPF } from "./formatCPF";
import { FormatSolicitation } from "./formatSolicitation";

export function isBoolean(value: any): boolean {
  return typeof value === "boolean";
}
export function EmptyData(value: any) {
  return isBoolean(value) ? FormatBool(value) : value ? value : "Não possui";
}

export function FormatBool(value: boolean) {
  return value ? "Sim" : "Não";
}

export function FormatDocumentType(value: string) {
  return filesAccount.filter((item: any) => item.value === value)[0]?.label;
}

export function DataFormat(chave: string, value: any) {
  switch (chave) {
    case "cpf":
      return FormatCPF(value);
    case "active":
      return FormatBool(value);
    case "document_type":
      return FormatDocumentType(value);
    case "status":
    case "solicitation_status":
      return FormatStatus(value);
    case "role":
      return (
        permissionsMock.filter((item: any) => item.uuid === value)[0]?.name ??
        "Não possui"
      );
    case "abono_pecuniario":
    case "thirteen_anticipation":
      return FormatBool(value);

    case "acquisition_period_start":
    case "acquisition_period_end":
    case "concessive_period_start":
    case "concessive_period_end":
    case "created_at":
    case "end_date":
    case "start_date":
    case "finished_at":
    case "approved_at":
    case "completed_at":
      return FormatDate(value);
    case "solicitation_type":
      return FormatSolicitation(value);
    case "executed_at":
      return FormatDateComplete(value);

    case "business_unit_job_security_date":
      break;
    case "business_unit_contract_type":
      return value
        ? value === "999"
          ? "PJ"
          : value === "997"
          ? "Aprendiz"
          : value === "998"
          ? "Estagiário"
          : "CLT"
        : " - ";
    case "has_new_position":
      return FormatBool(value);
    default:
      return chave.indexOf("date") !== -1
        ? FormatDate(value)
        : EmptyData(value);
  }
}

export function FilterEmpty(array: any) {
  for (let i = 0; i < array.length; i++) {
    let objeto = array[i];
    for (let chave in objeto) {
      objeto[chave] = DataFormat(chave, objeto[chave]);
    }
  }
  return array;
}
