/** @format */

import { Button, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { AddCircleOutlined, Edit } from "@mui/icons-material";
import { IconButton } from "@radix-ui/themes";

import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { columns } from "./helpers/constants";
import { optionsTable } from "../../../../config/optionsTable";
import useStabilityTypes from "../../../../services/protected/hooks/useStabiltyType";

export function StabilityTypes() {
  const router = useNavigate();

  const {
    data,
    page,
    perPage,
    searchCall,
    setOrder,
    setPage,
    setPerPage,
    total,
  } = useStabilityTypes();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Configurações", value: "/settings/stabilityTypes" },
          { label: "Tipos de estabilidade" },
        ]}
      />
      <Button
        variant={"contained"}
        endIcon={<AddCircleOutlined />}
        onClick={() => router("/settings/stabilityTypes/new")}>
        Novo tipo de estabilidade
      </Button>

      <MUIDataTable
        title={"Cargos"}
        data={data}
        columns={[
          {
            name: "action",
            label: "AÇÕES",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid item gap={2} display={"flex"}>
                    <Tooltip title={"Editar"}>
                      <IconButton
                        variant='ghost'
                        radius='full'
                        onClick={() =>
                          router(
                            `/settings/stabilityTypes/details/${data[dataIndex].uuid}`
                          )
                        }>
                        <Edit fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          download: false,
          searchPlaceholder: "Pesquise pelo nome",
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
