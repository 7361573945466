/** @format */

import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";

import {
  AddCircleOutlined,
  ArrowDownward,
  ArrowUpward,
  CheckCircle,
  DragIndicator,
  Edit,
  HighlightOff,
  Restore,
  Save,
  Undo,
} from "@mui/icons-material";
import { IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { EmptyData } from "../../../../components/emptyData";
import { FormatStatus } from "../../../../helpers/formatStatus";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";
import { DialogModal } from "./helpers/dialog";
import { InfoComponent } from "../../../../components/infoComponent";

export function Grades() {
  const [items, setItems] = useState<any[]>([]);
  const [valide, setValide] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState({ open: false, type: "" });
  const dispatch = useDispatch();
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const handleDragStart = (index: number): void => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const handleDrop = (index: number): void => {
    if (draggedItemIndex === null) return;

    const updatedItems = [...items];
    const [removed] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(index, 0, removed);

    setItems(updatedItems);
    setDraggedItemIndex(null);
  };

  const formik: any = useFormik({
    initialValues: {
      grade: "",
      description: "",
      status: "active",
      id: null,
      index: null,
    },
    validateOnChange: valide,
    validateOnBlur: valide,
    validationSchema: validationSchema,
    onSubmit: () => {
      if (openModal.type === "create") {
        const newGrade = {
          grade: formik.values.grade,
          description: formik.values.description,
          status: formik.values.status,
        };
        setItems((prevItems) => [...prevItems, newGrade]);
      } else {
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[formik.values.index] = {
            id: formik.values.id,
            grade: formik.values.grade,
            description: formik.values.description,
            status: formik.values.status,
          };
          return updatedItems;
        });
      }
      setOpenModal({ open: false, type: "" });
      formik.setValues({ grade: "", description: "", id: null, index: null });
    },
  });

  const handleClickEdit = (item: any, index: number) => {
    formik.setValues({
      description: item.description,
      grade: item.grade,
      id: item.id,
      status: item.status,
      index,
    });
    setOpenModal({ open: true, type: "edit" });
  };

  const handleSendData = () => {
    const toSend = items.map((item, index) => ({
      ...item,
      ordinance: index,
    }));
    dispatch(updateLoading(true));
    PostInstante(null, { grades: toSend }, "/grades")
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  const handleClickUp = (index: number) => {
    setItems((prevItems) => {
      if (index <= 0) return prevItems;
      const updatedItems = [...prevItems];

      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      return updatedItems;
    });
  };

  const handleClickDown = (index: number) => {
    setItems((prevItems) => {
      if (index >= prevItems.length - 1) return prevItems;
      const updatedItems = [...prevItems];

      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      return updatedItems;
    });
  };

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/grades")
      .then((response: any) => {
        if (Array.isArray(response?.data?.data)) setItems(response.data.data);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {openModal.open && (
        <DialogModal
          formik={formik}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setValide={setValide}
        />
      )}
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Tabela salarial", value: "/tableSalary/grades" },
          { label: "Níveis funcional" },
        ]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            endIcon={<AddCircleOutlined />}
            onClick={() => {
              setOpenModal({ open: true, type: "create" });
            }}
          >
            Novo nível
          </ButtonComponent>
        }
      />
      <InfoComponent instruction="Cadastre todas as grades que serão utilizadas na tabela salarial" />
      {items.length === 0 ? (
        <EmptyData />
      ) : (
        <>
          <Paper>
            <Grid container p={2} mt={2}>
              <Grid
                width={"100%"}
                display={"grid"}
                gridTemplateColumns={"1fr 1fr 2fr 1fr"}
              >
                <Grid item alignItems={"center"}>
                  <Typography fontWeight={800}>Ações</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={800}>Grade</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={800}>Nível funcional</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={800}>Status</Typography>
                </Grid>
              </Grid>
              <Grid item width={"100%"}>
                <Divider />
              </Grid>
              {items.map((item, index) => (
                <Grid
                  width={"100%"}
                  display={"grid"}
                  pt={2}
                  gridTemplateColumns={"1fr 1fr 2fr 1fr"}
                  key={index}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(index)}
                >
                  <Grid item gap={2} display={"flex"}>
                    <Tooltip title={"Arraste para a posição desejada"}>
                      <IconButton variant="ghost" radius="full">
                        <DragIndicator />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Subir"}>
                      <IconButton
                        variant="ghost"
                        radius="full"
                        disabled={index === 0}
                        onClick={() => handleClickUp(index)}
                      >
                        <ArrowUpward />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Descer"}>
                      <IconButton
                        variant="ghost"
                        radius="full"
                        disabled={index === items.length - 1}
                        onClick={() => handleClickDown(index)}
                      >
                        <ArrowDownward />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Editar"}>
                      <IconButton
                        variant="ghost"
                        radius="full"
                        onClick={() => handleClickEdit(item, index)}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={item.status === "active" ? "Desativar" : "Ativar"}
                    >
                      <IconButton
                        variant="ghost"
                        radius="full"
                        onClick={() =>
                          setItems(
                            items.map((x, i) =>
                              i === index
                                ? {
                                    ...x,
                                    status:
                                      x.status === "active"
                                        ? "inactive"
                                        : "active",
                                  }
                                : x
                            )
                          )
                        }
                      >
                        {item.status === "active" ? (
                          <HighlightOff />
                        ) : (
                          <CheckCircle />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>{item.grade}</Grid>
                  <Grid item>{item.description}</Grid>
                  <Grid item>
                    <Chip
                      size={"small"}
                      color={item?.status === "active" ? "success" : "error"}
                      label={FormatStatus(item.status)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <ButtonComponent
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              endIcon={<Undo />}
            >
              Voltar
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              color={"info"}
              onClick={() => {
                window.location.reload();
              }}
              endIcon={<Restore />}
            >
              Limpar modificações
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              endIcon={<Save />}
              onClick={handleSendData}
            >
              Salvar modificações
            </ButtonComponent>
          </Grid>
        </>
      )}
    </Box>
  );
}
