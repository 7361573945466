import * as yup from "yup";

export const validationSchema = yup.object({
  description: yup.string().required("Descrição é obrigatório"),
});

export const columns = [
  {name: "description", label: "Descrição"},
  {name: "duration", label: "Duração"},
  {name: "union_name", label: "Sindicato"},
];