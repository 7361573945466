/** @format */
import * as yup from "yup";
import { LabelFormat } from "../../../../../helpers/labelFormat";
import { FormatCurrency, ClearCurrencyInt } from "../../../../../helpers/formatCurrency";

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "collaborator_role", label: "Cargo" },
  { name: "new_manager_name", label: "Gestor futuro" },
  { name: "department_name", label: LabelFormat("department")},
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  with_position: yup.string().nullable().required("Campo é obrigatório"),
  transfer_date: yup
    .date()
    .min(new Date(), "Data da tranferência precisa ser futura")
    .nullable()
    .required("Campo é obrigatório"),
  new_manager_id: yup.string().nullable().required("Campo é obrigatório"),
  observations: yup.string().nullable().required("Campo é obrigatório"),
});

export const validationSchemaEdit = (companySettings: any, user: any, options: any) => {
  const {
    transfer_requisitions_rules = {},
    transfer_with_promotion_min_percent,
    transfer_with_promotion_max_percent,
    transfer_without_promotion_min_percent,
    transfer_without_promotion_max_percent
  } = companySettings || {};
  const { fields: requiredFields = [] } = transfer_requisitions_rules;

  return yup.object({
    with_position: yup.string().nullable().required("Campo é obrigatório"),
    position_id: yup.string().nullable().required("Campo é obrigatório"),
    transfer_date: yup
      .date()
      .min(new Date(), "Data da transferência precisa ser futura")
      .nullable()
      .required("Campo é obrigatório"),
    business_unit_id: yup.string().nullable().required("Campo é obrigatório"),
    sector_id: yup.string().nullable().required("Campo é obrigatório"),
    cost_center_id: yup.string().nullable().required("Campo é obrigatório"),
    job_role_id: yup.string().nullable().required("Campo é obrigatório"),
    department_id: yup.string().nullable().required("Campo é obrigatório"),
    observations: yup.string().nullable().required("Campo é obrigatório"),
    new_remuneration_percentage: yup
      .number()
      .nullable()
      .when("with_promotion", ([with_promotion], schema) => {
        if (with_promotion) {
          return schema
            .required("Campo é obrigatório")
            .min(
              transfer_with_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${transfer_with_promotion_min_percent}%`
            )
            .max(
              transfer_with_promotion_max_percent,
              `A porcentagem não pode ser maior que ${transfer_with_promotion_max_percent}%`
            );
        } else {
          return schema
            .required("Campo é obrigatório")
            .min(
              transfer_without_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${transfer_without_promotion_min_percent}%`
            )
            .max(
              transfer_without_promotion_max_percent,
              `A porcentagem não pode ser maior que ${transfer_without_promotion_max_percent}%`
            );
        }
      }),
    new_remuneration_value: yup
      .string()
      .nullable()
      .required("Campo é obrigatório")
      .test("invalid-salary", "Salário fora da faixa salarial!", (value, context) => {
        const target_job_role = options?.job_role.find((jobRole: any) =>
          context?.options?.context?.job_role_id && jobRole.id === context?.options?.context?.job_role_id
        );
        
        const activeItems = target_job_role?.grade_items?.filter((gradeItem: any) => gradeItem?.grade_column?.status === "active")
        if (activeItems?.length > 0) {
          const { minSalary, maxSalary } = activeItems.reduce(
            (acc: any, currentItem: any) => {
              const currentSalary = Number(currentItem.value);

              return ({
                minSalary: Math.min(acc.minSalary, currentSalary),
                maxSalary: Math.max(acc.maxSalary, currentSalary),
              })
            },
            {
              minSalary: Number(activeItems[0].value),
              maxSalary: Number(activeItems[0].value),
            }
          )

          if (Number(ClearCurrencyInt(value)) < Number(ClearCurrencyInt(FormatCurrency({ target: { value: `${minSalary}` } })))) {
            return context.createError({ message: `Salário fora da faixa salarial, o valor deve ser superior a ${FormatCurrency({ target: { value: `${minSalary}` } })}` })
          }

          if (Number(ClearCurrencyInt(value)) > Number(ClearCurrencyInt(FormatCurrency({ target: { value: `${maxSalary}` } })))) {
            return context.createError({ message: `Salário fora da faixa salarial, o valor deve ser inferior a ${FormatCurrency({ target: { value: `${maxSalary}` } })}` })
          }
        };

        return true;
      }),
    has_medical_examination: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes("has_medical_examination") || requiredIfRole(value, user.role, "rh")),
    has_benefits_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes("has_benefits_change") || requiredIfRole(value, user.role, "rh")),
    has_union_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes("has_union_change") || requiredIfRole(value, user.role, "rh")),
    has_work_schedule_change: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value) => !requiredFields.includes("has_work_schedule_change") || requiredIfRole(value, user.role, "rh")),
    union_id: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value, context) => !requiredFields.includes("has_union_change") || !context.options?.context?.has_union_change || requiredIfRole(value, user.role, "rh")),
    work_schedule_id: yup
      .string().nullable()
      .test('required-if-rh', 'Esse campo é obrigatório',
        (value, context) => !requiredFields.includes("has_work_schedule_change") || !context.options?.context?.has_work_schedule_change || requiredIfRole(value, user.role, "rh")),
  });
}

function requiredIfRole(value: any, role1: string, role2: string) {
  if (role1 === role2 && (!value || value === '')) {
    return false;
  }
  return true;
}
