import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { FormComponent } from "./helpers/form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
import { useParams } from "react-router-dom";

export function FilesAccountUpload() {
  const [data, setData]: any = useState({ options: { document_types: [] } });
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/my_documents_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Colaboradores", value: "/employee/active" },
          { label: "Upload de documentos" },
        ]}
      />
      <InfoComponent
        user={data.user_data}
        instruction="Preencha os dados abaixo para inclui o documento ao colaborador."
        instructionMap={[
          "Só é possível fazer upload de um arquivo por vez;",
          "O arquivo deve ser no formato PDF;",
          "O arquivo deve ter no máximo 25MB.",
        ]}
      />
      <FormComponent data={data} />
    </Grid>
  );
}
