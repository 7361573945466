/** @format */

import { Grid, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ButtonComponent } from "./buttonComponent";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";

function FormatValue(values: any, keys: any) {
  let returnString = "";
  // eslint-disable-next-line array-callback-return
  Object.entries(values).map((item: any) => {
    const keyObj: any = Object.entries(keys).filter(
      (obj: any) => obj[0] === item[0]
    )?.[0]?.[1];
    const key = keyObj?.label ?? "";
    const isArray = Array.isArray(item[1]);
    let value = isArray
      ? item[1].map((item: any) => item.label).toString()
      : item[1].toString();
    if (dayjs(value).isValid() && !isArray) {
      value = dayjs(value).format("DD/MM/YYYY") ?? "-";
    }
    if (keyObj?.options && !isArray) {
      value =
        keyObj.options.filter((item: any) => item.value.toString() === value)[0]
          ?.label ?? "";
    }
    if (value.length > 0) returnString += ` ${key}: ${value};`;
  });
  return returnString;
}

interface FilterProps {
  formik: any;
  children: any;
  keys: any;
  id: string;
  buttonLabel?: string;
}

export function Filter({
  formik,
  children,
  keys,
  id,
  buttonLabel,
}: FilterProps) {
  const [active, setActive] = useState(formik.values);
  const userState = useSelector((state: any) => state.user.data);
  const [all, setAll] = useState(false);

  function onSubmit() {
    if (
      (formik.values?.from &&
        new Date(formik.values?.from).toDateString() === "Invalid Date") ||
      (formik.values?.to &&
        new Date(formik.values?.to).toDateString() === "Invalid Date")
    ) {
      toast.info("Os campos precisam ser preenchidos corretamente.");
    } else {
      formik.handleSubmit();
    }
  }

  function onReset() {
    formik.handleReset();
    formik.handleSubmit();
    Cookies.set(
      "filters_" + id + "_" + userState?.id,
      JSON.stringify(formik.initialValues)
    );
    setActive(formik.initialValues);
  }

  useEffect(() => {
    if (Cookies.get("filters_" + id + "_" + userState?.id)) {
      const filters = JSON.parse(
        Cookies.get("filters_" + id + "_" + userState?.id) || "{}"
      );
      formik.setValues(filters);
      setActive(filters);
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik?.isValid && formik?.isSubmitting) {
      Cookies.set(
        "filters_" + id + "_" + userState?.id,
        JSON.stringify(formik.values)
      );
      setActive(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.isSubmitting]);

  return formik.values !== undefined ? (
    <Grid container gap={2}>
      <Grid
        gap={2}
        width={"100%"}
        flexDirection={isMobile ? "column" : "row"}
        display={"flex"}
      >
        <Grid
          width={"100%"}
          display={"grid"}
          gap={2}
          gridTemplateColumns={
            isMobile
              ? "1fr"
              : children.length === 1 || children.length === undefined
              ? "1fr"
              : children.length === 2
              ? "1fr 1fr"
              : children.length === 3
              ? "1fr 1fr 1fr"
              : "1fr 1fr 1fr 1fr"
          }
        >
          {all ? children : children.slice(0, 4)}
        </Grid>
        <Grid display={"flex"} height={40} gap={2}>
          {children.length > 4 && (
            <Tooltip title={all ? "Menos filtros" : "Mais filtros"}>
              <ButtonComponent variant="contained" onClick={() => setAll(!all)}>
                {all ? (
                  <FilterAltOffOutlined fontSize="small" />
                ) : (
                  <FilterAltOutlined fontSize="small" />
                )}
              </ButtonComponent>
            </Tooltip>
          )}
          <ButtonComponent variant="contained" onClick={onSubmit}>
            {buttonLabel ?? "Filtrar"}
          </ButtonComponent>
        </Grid>
      </Grid>
      {FormatValue(active, keys).length > 0 && (
        <Grid container alignItems={"center"}>
          <Typography fontSize={12} fontWeight={400}>
            Filtros ativos: <strong>{FormatValue(active, keys)}</strong>
          </Typography>
          <ButtonComponent
            style={{ marginLeft: 8 }}
            size="small"
            variant="text"
            onClick={onReset}
          >
            Limpar filtros
          </ButtonComponent>
        </Grid>
      )}
    </Grid>
  ) : (
    <></>
  );
}
