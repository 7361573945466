/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import { LabelFormat } from "../../../../helpers/labelFormat";
import Cookies from "js-cookie";

export function LabelsConfig() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      business_unit: "",
      department: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          labels: values,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          setTimeout(() => {
            window.location.href = "/";
          }, 6000); 
          Cookies.set("labels", JSON.stringify(values));
          toast.success(response?.data?.message);
          toast.info("Recarregando o sistema...");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          ...response.data.data.record.labels,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/labels" },
            { label: "Labels" },
          ]}
        />
        <InfoComponent instruction="Nesta tela é feito a personalização do nome de inputs, caso não seja informado um nome personalizado, será utilizado o padrão" />
        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações da Labels
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}
          >
            <InputTextField
              formik={formik}
              id={`business_unit`}
              label={LabelFormat("business_unit")}
              name={"business_unit"}
            />
            <InputTextField
              formik={formik}
              id={`department`}
              label={LabelFormat("department")}
              name={"department"}
            />
          </Grid>
        </Paper>
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}
          >
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
