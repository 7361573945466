/** @format */
import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";
import { createOrg } from "./helpers/createOrg";
import { Org } from "./helpers/org";
import { EmptyCustom } from "../../../components/emptyCustom";

export function Organizational() {
  const user = useSelector((state: any) => state.user.data);
  const companyData = useSelector((state: any) => state.company);
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [data, setData]: any = useState([]);
  const [orgTypes, setOrgTypes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        const dataHas =
          response.data.data.record.organograma_hidden_fields ?? [];
        setOrgTypes(dataHas);
        GetInstance(null, "/organograma")
          .then(async (res) => {
            const usersList = (await res?.data?.data) ?? [];
            const orgData = createOrg(usersList, user);
            setData(orgData);
            dispatch(updateLoading(false));
          })
          .catch((e) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Grid container>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Organograma", value: "/organograma" },
        ]}
      />
      <Grid
        p={2}
        container
        ref={ref}
        style={{
          overflowX: "auto",
          overflowY: "auto",
          height: "calc(100vh - 145px)",
          display: "flex",
          alignItems: "flex-start",
        }}
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}
      >
        {data.length > 0 && Object.keys(data?.[0])?.length === 0 ? (
          <EmptyCustom
            message={
              "Foram encontrado falhas ao gerar o organograma a partir do seu perfil, por favor verifique ou acione a equipe de RH para conferir seu cadastro."
            }
          />
        ) : (
          companyData !== undefined &&
          data.length > 0 && (
            <Org
              data={data}
              setData={setData}
              companyData={companyData}
              orgTypes={orgTypes}
            />
          )
        )}
      </Grid>
    </Grid>
  );
}
