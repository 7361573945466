/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { ToggleOn, Undo } from "@mui/icons-material";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { fields } from "../../../../../config/fields";
import { GenericError } from "../../../../../helpers/genericError";
import { IsPermissions } from "../../../../../helpers/isPermission";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PutInstante } from "../../../../../services/api";
import { ActionManager } from "../helpers/buttonRequest/actionmanager";
import { ActionUploadDocument } from "../helpers/buttonRequest/actionUploadDocument";
import { validationSchema } from "../helpers/constants";
import { CustomTabPanel } from "../helpers/customTabPanel";
import { EmptyForm } from "../helpers/emptyForm";
import { GetData } from "../helpers/getData";
import { SetActive } from "../helpers/setActive";
import { Benefits } from "./benefits";
import { Contract } from "./contract";
import { Dependents } from "./dependents";
import { Documents } from "./documents";
import { EmergencyContacts } from "./emergencyContacts";
import { Personal } from "./personal";
import { Remuneration } from "./remuneration";
import { Stabilities } from "./stabilities";
import { Photo } from "./photo";
import { ActionAdmissionInterval } from "../helpers/buttonRequest/actionAdmissionInterval";

export function UpdateNew() {
  const { id, solicitation } = useParams();
  const [index, setIndex] = useState(0);
  const [openManager, setOpenManager] = useState(false);
  const [openAdmissionInterval, setOpenAdmissionInterval] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [data, setData]: any = useState({
    user_data: {},
    fields_with_required_documents: [],
    user_form_hidden_fields: [],
  });
  const companySettings = useSelector(
    (state: any) => state.user.companySettings
  );
  const userState = useSelector((state: any) => state.user.data);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: { uuid: "", pensions: [] },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      if (values.type_of_disability) {
        values.type_of_disability = values.type_of_disability.map(
          (item: any) => item.value
        );
      }
      PutInstante(values.uuid, values, "/users")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetData({
      id,
      formik,
      setData,
      setIndex,
      dispatch,
      setAvatar,
      solicitation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, solicitation]);

  useEffect(() => {
    const hasError = Boolean(formik.errors.business_unit_contract_type);
    if (hasError && formik.values.position_type !== "pj") {
      toast.error(
        "Esse colaborador está em uma posição CLT, o tipo de contratação não poderá ser PJ."
      );
    } else if (hasError && formik.values.position_type === "pj") {
      toast.error(
        "Esse colaborador está em uma posição PJ, o tipo de contratação deverá ser PJ também."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors.business_unit_contract_type]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/updateInformation" },
            {
              label: "Alteração cadastral",
              value: "/request/updateInformation",
            },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent user={data.user_data} />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Visualizar informações
                <IsPermissions
                  keyPrivate={"requestUpdateInformationDetailsEdit"}
                >
                  {" "}
                  / Solicitar alteração cadastral
                </IsPermissions>
              </Typography>
            </Grid>
            {data.user_form_hidden_fields.length ===
            fields.filter((item: any) => item?.hidden === undefined).length ? (
              <EmptyForm />
            ) : (
              <>
                <Tabs
                  value={index}
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(event, newValue) => setIndex(newValue)}
                >
                  <Tab label="Dados pessoais" value={1} />
                  <Tab label="Estrutura e remuneração" value={2} />
                  <Tab label="Beneficios" value={3} />
                  <Tab label="Contrato" value={4} />
                  <Tab label="Documentação" value={5} />
                  <Tab id={"dependents"} label="Dependentes" value={6} />
                  <Tab
                    id={"dependents"}
                    label="Contatos de emergência"
                    value={7}
                  />
                  <Tab id={"stabilities"} label="Estabilidades" value={8} />
                  <Tab id={"photo"} label="Foto" value={9} />
                </Tabs>
                <CustomTabPanel value={index} index={1}>
                  <Personal formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={2}>
                  <Remuneration formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={3}>
                  <Benefits formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={4}>
                  <Contract formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={5}>
                  <Documents formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={6}>
                  <Dependents formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={7}>
                  <EmergencyContacts formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={8}>
                  <Stabilities formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={9}>
                  <Photo setAvatar={setAvatar} id={id} photo={avatar} />
                </CustomTabPanel>
              </>
            )}
          </Paper>
          <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
            <Grid container gap={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              {data.user_data.status === "pending" && (
                <IsPermissions keyPrivate={"employeePendingActive"}>
                  <ActionManager
                    open={openManager}
                    setOpen={setOpenManager}
                    action={() => SetActive(dispatch, formik, data, setData)}
                  />
                  <ActionAdmissionInterval
                    open={openAdmissionInterval}
                    setOpen={setOpenAdmissionInterval}
                    action={() => {
                      if (
                        formik.values?.manager_id?.length === 0 ||
                        formik.values?.manager_id === null ||
                        formik.values?.manager_id === undefined
                      ) {
                        setOpenManager(true);
                      } else {
                        SetActive(dispatch, formik, data, setData);
                      }
                    }}
                    interval={companySettings.admission_interval}
                  />
                  <ButtonComponent
                    variant={"contained"}
                    color="success"
                    onClick={() => {
                      if (
                        dayjs(formik.values?.business_unit_hire_date).diff(
                          dayjs(),
                          "days"
                        ) < companySettings.admission_interval &&
                        companySettings.admission_bypass_users?.length > 0 &&
                        !companySettings.admission_bypass_users?.includes(
                          userState?.id
                        )
                      ) {
                        setOpenAdmissionInterval(true);
                      } else if (
                        formik.values?.manager_id?.length === 0 ||
                        formik.values?.manager_id === null ||
                        formik.values?.manager_id === undefined
                      ) {
                        setOpenManager(true);
                      } else {
                        SetActive(dispatch, formik, data, setData);
                      }
                    }}
                    endIcon={<ToggleOn />}
                  >
                    Tornar ativo
                  </ButtonComponent>
                </IsPermissions>
              )}
              <IsPermissions keyPrivate={"requestUpdateInformationDetailsEdit"}>
                <ActionUploadDocument
                  id={id}
                  formik={formik}
                  solicitation={solicitation}
                  url="/user_update_requests"
                  data={{
                    ...formik.values,
                    status: data.user_data.status,
                    payload: data?.payload ?? null
                  }}
                  initialData={{
                    ...data.record_data,
                    ...data.address_data,
                    ...data.internship_details_data,
                    dependents: data.dependant_data,
                    emergency_contacts: data.emergency_contact_data,
                    user_stabilities: data.stability_data,
                  }}
                  urlBack={"/request/updateInformation"}
                />
              </IsPermissions>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
