/** @format */
import * as yup from "yup";
import { LabelFormat } from "../../../../helpers/labelFormat";

export const accountantProcessOptions = [
  { label: "Todos", value: "all" },
  { label: "Transferência", value: "transfer" },
  { label: "Desligamento", value: "dismissal" },
  { label: "Afastamento", value: "absence" },
  { label: "Revisão salarial", value: "salary_review" },
  { label: "Promoção", value: "promotion" },
  { label: "Férias", value: "vacation_item" },
  { label: "Atualização cadastral", value: "user_update_request" },
];

export const rhProcessOptions = [
  { label: "Todos", value: "all" },
  { label: "Transferência", value: "transfer" },
  { label: "Autorização de desligamento", value: "dismissal_request" },
  { label: "Desligamento", value: "dismissal" },
  { label: "Afastamento", value: "absence" },
  { label: "Revisão salarial", value: "salary_review" },
  { label: "Promoção", value: "promotion" },
  { label: "Férias", value: "vacation_item" },
  { label: "Atualização cadastral", value: "user_update_request" },
];

export const columns = [
  { name: "business_unit_name", label: LabelFormat("business_unit") },
  { name: "collaborator_identification", label: "Matrícula" },
  { name: "collaborator_name_formatted", label: "Nome do colaborador" },
  { name: "business_unit_contract_type", label: "Tipo de contrato" },
  { name: "solicitation_type_label", label: "Nome do processo" },
  { name: "operation_date", label: "Data de Execução" },
  { name: "created_at", label: "Data da criação" },
  { name: "approved_at", label: "Data da Aprovação" },
  { name: "status", label: "Status" },
];

export const accountantStatusOptions = [
  { label: "Aprovados", value: "approved" },
  { label: "Concluídos", value: "completed" },
  { label: "Reprovado folha", value: "accounting_disapproved" },
];

export const rhStatusOptions = [
  { label: "Retornada", value: "returned" },
  { label: "Aguardando gestor", value: "waiting_manager_user" },
  { label: "Aguardando RH", value: "waiting_rh_user" },
  { label: "Aprovado", value: "approved" },
  { label: "Concluído", value: "completed" },
  { label: "Reprovado", value: "disapproved" },
  { label: "Reprovado folha", value: "accounting_disapproved" },
];

export const validationSchema = yup.object({
  from: yup.string().nullable().required("Campo é obrigatório"),
  to: yup.string().nullable().required("Campo é obrigatório"),
  solicitation_types: yup.string().nullable().required("Campo é obrigatório"),
  status: yup.string().nullable().required("Campo é obrigatório"),
});
