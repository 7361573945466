/** @format */
import { Info, PersonRemove } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { Filters } from "./helpers/filters";
import useReportDismissals from "../../../../services/protected/hooks/useReportDismissals";
import { columns } from "./helpers/constants";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
import { exportDataToCSV } from "../../../../helpers/exportDataToCSV";
import { GenericError } from "../../../../helpers/genericError";

export function ReportDismissal() {
  const dispatch = useDispatch();
  const router = useNavigate();

  const formik: any = useFormik({
    initialValues: {
      manager_id: "all",
      business_unit_id: "all",
      dismissal_type_id: "all",
      filter_start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
      filter_end_date: dayjs().add(2, "month").format("YYYY-MM-DD"),
    },
    onSubmit: () => {
      setReset(true);
    },
  });

  const {
    data,
    total,
    setOrder,
    setPage,
    setPerPage,
    searchCall,
    page,
    perPage,
    options,
    setReset,
  } = useReportDismissals({ ...formik.values, status: "active" });

  const handleDownloadReport = () => {
    dispatch(updateLoading(true));
    GetInstance(
      {
        ...formik.values,
      },
      "/reports/dismissals/export_csv"
    )
      .then((response: any) => {
        exportDataToCSV(response.data, "Relatório de colaboradores");
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Relatórios", value: "/report/employee" },
          { label: "Colaboradores" },
        ]}
      />
      <Filters formik={formik} options={options} />

      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Tooltip title={"Ver informações"}>
                    <IconButton
                      onClick={() =>
                        router(
                          `/request/dismissals/details/${data[dataIndex].uuid}`
                        )
                      }
                    >
                      <Info />
                    </IconButton>
                  </Tooltip>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome ou CPF",
          download: false,
          customToolbar: () => (
            <>
              <Tooltip title="Baixar relatório de Desligamento">
                <IconButton onClick={handleDownloadReport}>
                  <PersonRemove />
                </IconButton>
              </Tooltip>
            </>
          ),
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
