/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { FilterEmpty } from "../../../../../helpers/emptyData";

export function JobRoleDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [grades, setGrades] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [cboFamilies, setCboFamilies] = useState<any[]>([]);
  const [cboOcupations, setCboOcupations] = useState<any[]>([]);
  const [cboFamily, setCboFamily] = useState<any>(null);

  const formik: any = useFormik({
    initialValues: {
      name: "",
      code: "",
      grade_id: null,
      job_role_group_id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, values, "/job_roles/" + id)
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/jobRoles");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/job_roles_form/" + id)
      .then((response: any) => {
        setCboFamilies(FilterEmpty(response.data.data.cbo_families));
        setGrades(response.data.data.grades);
        setLevels(response.data.data.job_role_levels);

        formik.setValues({
          name: response.data.data.job_role.name,
          code: response.data.data.job_role.code,
          grade_id: response.data.data.job_role.grade_id,
          job_role_group_id: response.data.data.job_role.job_role_group_id,
          salary_minimum: response.data.data.job_role.salary_minimum,
          salary_maximum: response.data.data.job_role.salary_maximum,
          cbo_family: parseInt(response.data.data.job_role.cbo_family),
          cbo_ocupation: parseInt(response.data.data.job_role.cbo_ocupation),
          job_role_level_id: response.data.data.job_role.job_role_level_id,
        });

        setCboFamily(parseInt(response.data.data.job_role.cbo_family));
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (cboFamily) {
      dispatch(updateLoading(true));
      GetInstance({ cbo_family_id: cboFamily }, "/cbo_occupations")
        .then((response: any) => {
          setCboOcupations(FilterEmpty(response.data.data));
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cboFamily]);

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Cargos",
              value: "/settings/jobRoles",
            },
            { label: "Detalhes" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar novo grupo
            </Typography>
          </Grid>

          <Form
            formik={formik}
            grades={grades}
            levels={levels}
            cboFamilies={cboFamilies}
            cboOcupations={cboOcupations}
            setCboFamily={setCboFamily}
            setCboOcupations={setCboOcupations}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
