/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import {
  aviso_e_social,
  banks_e_social,
  dependents_e_social,
  gender_e_social,
  type_of_account,
} from "../../../../../config/constants";
import { ClearCPF } from "../../../../../helpers/clearString";
import { GenericError } from "../../../../../helpers/genericError";
import { HiddenCheckChildren } from "../../../../../helpers/hiddenCheck";
import { GetInstance } from "../../../../../services/api";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCPFField } from "../../../../../components/inputs/inputCPFField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { base_calculations, discount_types } from "../helpers/constants";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { AutoCompleteFieldMultiple } from "../../../../../components/inputs/autoCompleteFieldMultiple";

export function Dependents({ formik, data }: any) {
  function Add() {
    const newArray = formik.values.dependents;
    newArray.push({
      name: "",
      mother_name: "",
      birth_date: null,
      degree_of_kinship: "",
      cpf: "",
      gender: "",
      ir: "",
      family_salary: "",
      is_disability: "",
      pension_number: "",
    });
    formik.setFieldValue("dependents", newArray);
  }

  function Remove(id: number) {
    const newArray = formik.values.dependents;
    newArray.splice(id, 1);
    formik.setFieldValue("dependents", newArray);
  }

  function AddPension() {
    const newArray = formik.values.pensions;
    newArray.push({
      process_number: "",
      discount_type: null,
      discount_value: "",
      base_calculation: null,
      init_date: null,
      final_date: null,
      dependents: [],
      files: [],
      bank_code: "",
      bank_account_type: "",
      bank_agency: "",
      bank_account: "",
    });
    formik.setFieldValue("pensions", newArray);
  }

  function RemovePension(id: number) {
    const newArray = formik.values.pensions;
    newArray.splice(id, 1);
    formik.setFieldValue("pensions", newArray);
  }

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}
      >
        <Grid
          id={"dependents"}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={16} fontWeight={500}>
            Dependentes
          </Typography>
        </Grid>
        {formik.values.dependents.map((item: any, index: number) => (
          <div id={"dependents"}>
            <Divider style={{ marginTop: 16 }} />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={14} fontWeight={400}>
                Dependente {index + 1}
              </Typography>
              <Tooltip title={"Remover dependente"}>
                <IconButton onClick={() => Remove(index)} color={"error"}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}
            >
              <InputTextField
                id={`dependents[${index}].name`}
                label="Nome"
                formik={formik}
              />
              <InputTextField
                id={`dependents[${index}].mother_name`}
                label="Nome da mãe"
                formik={formik}
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}
            >
              <InputDateField
                label="Data de nascimento"
                id={`dependents[${index}].birth_date`}
                formik={formik}
              />
              <AutoCompleteField
                label="Parentesco"
                id={`dependents[${index}].degree_of_kinship`}
                formik={formik}
                options={dependents_e_social?.map((jobRole: any) => ({
                  label: `${jobRole?.value} - ${jobRole.label}`,
                  value: jobRole.value,
                }))}
              />
              <InputCPFField
                onBlur={() => {
                  GetInstance(
                    null,
                    "/users_cpf/" +
                      ClearCPF(formik.values.dependents[index].cpf)
                  )
                    .then((r: any) => {
                      if (!r.data.data.valid) {
                        formik.setFieldError(
                          `dependents[${index}].cpf`,
                          "CPF inválido!"
                        );
                      }
                    })
                    .catch((e: any) => GenericError(e));
                }}
                label="CPF"
                id={`dependents[${index}].cpf`}
                formik={formik}
              />
              <SelectField
                label="Sexo"
                formik={formik}
                id={`dependents[${index}].gender`}
                options={gender_e_social}
              />
              <SelectField
                label="Renda familiar"
                formik={formik}
                id={`dependents[${index}].family_salary`}
                options={aviso_e_social}
              />
              <SelectField
                label="Imposto de renda"
                formik={formik}
                id={`dependents[${index}].ir`}
                options={aviso_e_social}
              />
              <SelectField
                label="Incapaz"
                formik={formik}
                id={`dependents[${index}].is_disability`}
                options={aviso_e_social}
              />
              <InputTextField
                label="Pensão"
                disabled
                formik={formik}
                id={`dependents[${index}].pension_number`}
              />
            </Grid>
            <Divider style={{ marginTop: 16 }} />
          </div>
        ))}
        <Grid mt={2}>
          <Button endIcon={<AddCircle />} onClick={Add} variant={"contained"}>
            Adicionar dependente
          </Button>
        </Grid>
      </HiddenCheckChildren>
      {/* <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}
      >
        <Grid
          id={"dependents"}
          container
          marginTop={5}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={16} fontWeight={500}>
            Pensões
          </Typography>
        </Grid>
        {formik.values.pensions.map((item: any, index: number) => (
          <div id={"dependents"}>
            <Divider style={{ marginTop: 16 }} />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={14} fontWeight={400}>
                Pensão {index + 1}
              </Typography>
              <Tooltip title={"Remover pensão"}>
                <IconButton
                  onClick={() => RemovePension(index)}
                  color={"error"}
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}
            >
              <InputTextField
                id={`pensions[${index}].process_number`}
                label="Número do processo"
                formik={formik}
              />
              <SelectField
                formik={formik}
                id={`pensions[${index}].discount_type`}
                label="Tipo de desconto"
                options={discount_types}
              />
              {formik.values.pensions[index].discount_type === "currency" ? (
                <InputCurrencyField
                  id={`pensions[${index}].discount_value`}
                  label="Valor do desconto"
                  formik={formik}
                />
              ) : (
                <InputTextField
                  type="number"
                  disabled={
                    formik.values.pensions[index].discount_type === null
                  }
                  id={`pensions[${index}].discount_value`}
                  label="Valor do desconto"
                  formik={formik}
                />
              )}
              <SelectField
                formik={formik}
                id={`pensions[${index}].base_calculation`}
                label="Base de cálculo"
                options={base_calculations}
              />
              <InputDateField
                label="Data de início"
                id={`pensions[${index}].init_date`}
                formik={formik}
              />
              <InputDateField
                label="Data de término"
                id={`pensions[${index}].final_date`}
                formik={formik}
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}
            >
              <AutoCompleteField
                formik={formik}
                id={`pensions[${index}].bank_code`}
                label="Código do banco"
                labelcode={true}
                options={banks_e_social}
              />
              <AutoCompleteField
                formik={formik}
                id={`pensions[${index}].bank_account_type`}
                label="Tipo da conta"
                labelcode={true}
                options={type_of_account}
              />
              <InputTextField
                formik={formik}
                id={`pensions[${index}].bank_agency`}
                label="Agência com DV"
                type={"number"}
              />
              <InputTextField
                formik={formik}
                id={`pensions[${index}].bank_account`}
                label="Conta com DV"
                type={"number"}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"}>
              <AutoCompleteFieldMultiple
                label="Dependentes"
                id={`pensions[${index}].dependents`}
                onChange={(e: any, newValue: any) => {
                  // eslint-disable-next-line array-callback-return
                  formik.values.dependents.map((item: any) => {
                    if (
                      item.pension_number ===
                      formik.values.pensions[index].process_number
                    ) {
                      item.pension_number = "";
                    }
                  });
                  // eslint-disable-next-line array-callback-return
                  newValue.map((item: any) => {
                    const dependent = formik.values.dependents.find(
                      (i: any) => i.cpf === item.value
                    );
                    if (dependent) {
                      dependent.pension_number =
                        formik.values.pensions[index].process_number;
                    }
                  });
                }}
                formik={formik}
                options={formik.values.dependents?.map((item: any) => ({
                  label: `${item.name} - ${item.mother_name}`,
                  value: item.cpf,
                }))}
              />
              <InputFile formik={formik} id={`pensions[${index}].files`} />
            </Grid>
            <Divider style={{ marginTop: 16 }} />
          </div>
        ))}
        <Grid mt={2}>
          <Button
            endIcon={<AddCircle />}
            onClick={AddPension}
            variant={"contained"}
          >
            Adicionar pensão
          </Button>
        </Grid>
      </HiddenCheckChildren> */}
    </Grid>
  );
}
