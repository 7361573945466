/** @format */

import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";

type Props = {
  links: { label: string; value?: string }[];
  buttons?: any;
};

export const BreadcrumbsComponent = ({ links, buttons }: Props) => {
  return (
    <Grid
      container
      pb={buttons !== undefined ? 2 : 0}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {links.map((link, index) =>
          index < links.length - 1 ? (
            <Link
              key={index + link.label}
              underline="hover"
              sx={{ cursor: "pointer" }}
              color="inherit"
              href={index === 0 ? "/" : link.value}
            >
              {link.label}
            </Link>
          ) : (
            <Typography key={index + link.label} fontWeight={800}>
              {link.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
      {buttons}
    </Grid>
  );
};
