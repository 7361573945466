import * as yup from "yup";

export const columns = [
  { name: "code", label: "Código" },
  { name: "name", label: "Nome" },
  { name: "grade_code", label: "Grade" },
  { name: "grade_description", label: "Nível funcional" },
  { name: "job_role_level_name", label: "Nível do cargo" },
  { name: "cbo_family", label: "Família CBO" },
  { name: "cbo_ocupation", label: "Ocupação CBO" },
  { name: "created_at", label: "Data de Criação" },
];

export const validationSchema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
});