/** @format */

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterEmpty } from "../../../helpers/emptyData";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";
import {
  CheckCircleOutline,
  Download,
  LoopOutlined,
} from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";

let timerSearch: any;

export default function useUpload(id?: string) {
  const [data, setData]: any = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getCall = useCallback(async () => {
    try {
      setLoading(false);
      dispatch(updateLoading(true));
      const { data } = await GetInstance(
        { page: page + 1, per_page: perPage, search, order_by: order },
        "/imported_files"
      );
      const dataResult = FilterEmpty(data.data.data);
      const loading = dataResult.find(
        (item: any) => item.status === "Pendente"
      );
      if (!!loading) {
        setTimeout(() => {
          setLoading(true);
        }, 5000);
      }
      const dataMap = await dataResult.map((item: any) => ({
        ...item,
        action: item.file?.url ? (
          <Grid container marginLeft={3}>
            <Tooltip title={"Baixar arquivo"}>
              <a
                title={item.file.name}
                href={item.file.url}
                target="_blank"
                style={{
                  padding: 8,
                  fontSize: 12,
                  borderRadius: 24,
                  fontWeight: 800,
                  textDecorationLine: "none",
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                }}
                download={item.file.name}
                rel="noreferrer"
              >
                <Download color="primary" fontSize={"small"} />
              </a>
            </Tooltip>
          </Grid>
        ) : (
          "Sem arquivo"
        ),
        process:
          item.status === "Pendente" ? (
            <Grid container marginLeft={7}>
              <LoopOutlined color="primary" />
            </Grid>
          ) : (
            <Grid container marginLeft={7}>
              <CheckCircleOutline color="success" />
            </Grid>
          ),
      }));
      setData(dataMap);
      setTotal(data.data.total_count);
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page, search, perPage, order]);

  //para atualizar a chamada
  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order, loading]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    order,
    searchCall,
  };
}
