/** @format */

import { Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import {
  admission_type_e_social,
  aviso_e_social,
  contract_type_e_social,
  internship_levels,
  internship_nature,
  nature_e_social,
  onus_types,
  overtime_type_e_social,
  reintegration_types,
  salary_e_social,
  security_type_e_social,
  social_security_regime_e_social,
  status_e_social,
} from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";
import { InputCPFField } from "../../../../../components/inputs/inputCPFField";
import { handleCEPChange } from "../../../../../helpers/handleCEPChange";
import { LabelFormat } from "../../../../../helpers/labelFormat";

export function Contract({ formik, data }: any) {
  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Contrato
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={status_e_social.map((item: any) => ({
                label: item,
                value: item,
              }))}
              label='Status'
              id='contract_status'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='contract_cbo'
              label='CBO número'
              type='number'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={nature_e_social}
              label='Natureza'
              id='contract_nature'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={salary_e_social}
              label='Tipo de salário'
              id='contract_salary'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={data.options.salary_payment_method}
              label='Método de Pagamento do Salário'
              id='salary_payment_method_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              id='contract_base_salary'
              label='Salário base'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            {LabelFormat("business_unit")}
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.business_unit}
              formik={formik}
              label='Código'
              labelcode
              id='business_unit_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.sector}
              formik={formik}
              label='Setor'
              labelcode
              id='sector_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_section}
              formik={formik}
              label='Seção'
              id='work_section_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.cost_center}
              formik={formik}
              label='Centro de custo'
              labelcode
              id='cost_center_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.managerial_cost_center}
              formik={formik}
              label='Centro de custo gerencial'
              labelcode
              id='managerial_cost_center_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_category}
              formik={formik}
              label='Categoria do colaborador'
              id='work_category_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={contract_type_e_social.filter((option) => {
                if (formik.values.position_type === "PJ" || formik.values.position_type === "aprendiz" || formik.values.position_type === "estagiario") {
                  return option !== formik.values.position_type;
                }

                return option.value !== "999" && option.value !== "998" && option.value !== "997";
              })}
              formik={formik}
              label='Tipo de contrato'
              id='business_unit_contract_type'
              disabled={formik.values.business_unit_contract_type === "999" ||
                formik.values.business_unit_contract_type === "998" ||
                formik.values.business_unit_contract_type === "997"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_schedule}
              formik={formik}
              label='Horário de trabalho'
              id='work_schedule_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={security_type_e_social.map((item: any) => ({
                label: item,
                value: item,
              }))}
              formik={formik}
              label='Tipo de estabilidade'
              id='business_unit_job_security_type'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_job_security_date'
              label='Data garantia estabilidade'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_employment_bond'
              label='Contrato determ. térm.'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='staffing_number_date'
              label='Lotação organ. período'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_hire_date'
              label='Data de admissão'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_exp_extension'
              label='Data exp. prorrogação'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_exp_finish_date'
              label='Data exp. término'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_leave_date'
              label='Data de afastamento'
              disabled
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_contract_finish'
              label='Data de desligamento'
              disabled
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>

      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Dados de trabalho
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={aviso_e_social}
              label='Aprendiz grávida'
              id='is_pregnant_apprentice'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={aviso_e_social}
              label='Teletrabalho'
              id='work_from_home'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={admission_type_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "admission_type"
              )}
              id='admission_type'
              label='Tipo de admissão'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={social_security_regime_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "social_security_regime"
              )}
              id='social_security_regime'
              label='Regime Previdenciário'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={overtime_type_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "overtime_type"
              )}
              id='overtime_type'
              label='Tipo de Horas Extras'
            />
            {formik.values.admission_type === "9" && (
              <AutoCompleteField
                options={reintegration_types}
                formik={formik}
                id='reintegration_type'
                label='Tipo de reintegração'
              />
            )}
            {["2", "3", "5", "7"].includes(formik.values.admission_type) && (
              <AutoCompleteField
                options={onus_types}
                formik={formik}
                id='onus_type'
                label='Tipo de onus'
              />
            )}
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      {(formik.values.business_unit_contract_type === "997" || formik.values.business_unit_contract_type === "998") &&
        <>
          <HiddenCheckChildren
            user_form_hidden_fields={data.user_form_hidden_fields}>
            <Grid mt={2}>
              <Typography fontSize={16} fontWeight={500}>
                Dados do estágio/aprendiz
              </Typography>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <AutoCompleteField
                  formik={formik}
                  id='educational_institution_id'
                  label='Nome da instituição de ensino'
                  options={[{ label: "", value: null }, ...data.options.educational_institutions]}
                  onChange={(event: any, newValue: any) => {
                    formik.setFieldValue("internship_detail.postal_code", newValue.postal_code ? handleCEPChange({ target: { value: newValue.postal_code } }) : "");
                    formik.setFieldValue("internship_detail.number", newValue.number);
                    formik.setFieldValue("internship_detail.complement", newValue.complement);
                    formik.setFieldValue("institution_cnpj", newValue.institution_cnpj);
                    
                    if (newValue.postal_code && newValue.postal_code.replace(/\D/g, "").length === 8) {
                      axios
                      .get(
                        `https://viacep.com.br/ws/${newValue.postal_code.replace(
                          /\D/g,
                          ""
                        )}/json/`
                      )
                      .then((response) => {
                        formik.setFieldValue("internship_detail.street", response.data.logradouro);
                        formik.setFieldValue("internship_detail.city", response.data.localidade);
                        formik.setFieldValue(
                          "internship_detail.neighborhood",
                          response.data.bairro
                        );
                        formik.setFieldValue("internship_detail.state", response.data.uf);
                        formik.setFieldTouched("internship_detail.street");
                        formik.setFieldTouched("internship_detail.city");
                        formik.setFieldTouched("internship_detail.neighborhood");
                        formik.setFieldTouched("internship_detail.state");
                      })
                      .catch(() => {
                        toast.error(
                          "CEP não encontrado. Verifique o CEP digitado."
                        );
                      });
                    } else {
                      formik.setFieldValue("internship_detail.street", "");
                      formik.setFieldValue("internship_detail.city", "");
                      formik.setFieldValue("internship_detail.neighborhood", "");
                      formik.setFieldValue("internship_detail.state", "");
                      formik.setFieldTouched("internship_detail.street");
                      formik.setFieldTouched("internship_detail.city");
                      formik.setFieldTouched("internship_detail.neighborhood");
                      formik.setFieldTouched("internship_detail.state");
                    }
                    
                  }}
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='institution_cnpj'
                  label='CNPJ da instituição'
                  disabled
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='policy_number'
                  label='Número da apólice do estágio'
                  type='number'
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputCPFField
                  formik={formik}
                  id={"supervisor_cpf"}
                  label={"CPF do supervisor do estágio"}
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <AutoCompleteField
                  options={internship_levels}
                  formik={formik}
                  id={"level"}
                  label={"Nível do estágio"}
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <AutoCompleteField
                  options={internship_nature}
                  formik={formik}
                  id={"nature"}
                  label={"Natureza do estágio"}
                />
              </HiddenCheck>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='objective'
                  label='Objetivo do contrato'
                  multiline={true}
                  rows={4}
                />
              </HiddenCheck>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='area'
                  label='definição da área do estágio'
                  multiline={true}
                  rows={4}
                />
              </HiddenCheck>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='activities'
                  label='plano de atividades exercidas'
                  multiline={true}
                  rows={4}
                />
              </HiddenCheck>
            </Grid>
          </HiddenCheckChildren>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <Grid mt={2} id={"address"}>
              <Typography fontSize={16} fontWeight={500}>
                Endereço da instituição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 2fr "} mt={2} display={"grid"} gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <TextField
                  fullWidth
                  id='internship_detail.postal_code'
                  placeholder={"00.000-000"}
                  label='CEP'
                  disabled
                  value={formik.values.internship_detail?.postal_code}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                  helperText={formik.touched.zip_code && formik.errors.zip_code}
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  disabled
                  id='internship_detail.street'
                  label='Logradouro'
                />
              </HiddenCheck>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 2fr 2fr 2fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='internship_detail.number'
                  type={"number"}
                  label='Número'
                  disabled
                />
              </HiddenCheck>
              <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='internship_detail.complement'
                  label='Complemento'
                  disabled
                />
              </HiddenCheck>
              <InputTextField
                formik={formik}
                disabled
                id='internship_detail.neighborhood'
                label='Bairro'
              />
              <InputTextField formik={formik} disabled id='internship_detail.city' label='Cidade'/>
              <InputTextField formik={formik} disabled id='internship_detail.state' label='UF'/>
            </Grid>
          </HiddenCheck>
        </>
      }
    </Grid>
  );
}
