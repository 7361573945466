/** @format */

import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { CardCustom } from "./helpers/card";
import { InfoComponent } from "../../../components/infoComponent";
import { useEffect, useState } from "react";
import { GetInstance } from "../../../services/api";
import { GenericError } from "../../../helpers/genericError";
import { filesAccount } from "../../../config/constants";
import { EmptyData } from "../../../components/emptyData";
import { useDispatch } from "react-redux";
import { updateLoading } from "../../../redux/reducers/common";

export function FilesAccount() {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "my_documents?statistics=true")
      .then((response) => {
        setOptions(
          response.data.data.map((item: any) => ({
            ...item,
            ...filesAccount.find((element) => element.value === item.value),
          }))
        );
        dispatch(updateLoading(false));
      })
      .catch((e) => {
        GenericError(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container gap={4}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio", value: "/" },
            { label: "Meus documentos" },
          ]}
        />
        <InfoComponent instruction="Selecione uma pasta abaixo para verificar seus documentos." />
        {options.length === 0 && <EmptyData />}
        <Grid
          display={"grid"}
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          gap={2}
          width={"100%"}
        >
          {options.map((item: any) => (
            <CardCustom key={item.name} {...item} />
          ))}
        </Grid>
        <Grid />
      </Grid>
    </Grid>
  );
}
