/** @format */

import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { TabsTableComponent } from "../../../../components/tabsTableComponent";
import { Table } from "./helpers/table";

export function Leave() {
  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Solicitações", value: "/request/leave" },
          { label: "Afastamentos" },
        ]}
      />
      <TabsTableComponent
        keyString="leave"
        tabs={[
          {
            type: "general_records",
            component: <Table type='general_records' />,
          },
          {
            type: "team_records",
            component: <Table type='team_records' />,
          },
          {
            type: "my_records",
            component: <Table type='my_records' />,
          },
        ]}
      />
    </Grid>
  );
}
