/** @format */

import { Save, Undo } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import {
  personnelRequisitionRequestTypes,
  validationSchema,
  rhFieldsOptions,
  managerFieldsOptions
} from "./helpers/constants";
import { SelectField } from "../../../../components/inputs/selectField";
import { positionSearchOptions } from "../../../../config/constants";
import { updateCompanySettings } from "../../../../redux/reducers/user";

export function PermissionsPersonnel() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);

  const [leftRhFields, setLeftRhFields]: any = useState([]);
  const [rightRhFields, setRightRhFields]: any = useState([]);

  const [leftManagerFields, setLeftManagerFields]: any = useState([]);
  const [rightManagerFields, setRightManagerFields]: any = useState([]);

  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      active_request_types: [],
      position_search_method: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          personnel_requisitions_rules: {
            approvers: rightApprovers,
            rh_fields: rightRhFields,
            manager_fields: rightManagerFields,
            active_request_types: values.active_request_types,
          },
          new_user_rules: {
            position_search_method: values.position_search_method,
          },
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateCompanySettings(response.data.data.record));
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        const data = response.data.data.record;
        formik.setValues({
          active_request_types:
            data.personnel_requisitions_rules.active_request_types,
          position_search_method: data.new_user_rules.position_search_method,
        });

        const {rh_fields = [], manager_fields = []} = data.personnel_requisitions_rules;

        setRightRhFields(rh_fields);
        setRightManagerFields(manager_fields);

        setLeftRhFields(rhFieldsOptions
          .filter(item => !rh_fields.includes(item.value))
          .map(item => item.value));
          
        setLeftManagerFields(managerFieldsOptions
          .filter(item => !manager_fields.includes(item.value))
          .map(item => item.value));

        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.user_update_request_rules)
                .length > 0
            ) {
              if (
                response.data.data.record.user_update_request_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.user_update_request_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.user_update_request_rules.approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRequestType = (id: string) => {
    if (formik.values.active_request_types.includes(id)) {
      const results = formik.values.active_request_types;
      const index = results.indexOf(id);
      results.splice(index, 1);
      formik.setFieldValue("active_request_types", results);
    } else {
      const results = formik.values.active_request_types;
      results.push(id);
      formik.setFieldValue("active_request_types", results);
    }
  };

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/personnel" },
            { label: "Requisição de pessoal" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta seção é possível configura a parametrização dos fluxos de requisição de pessoal",
          ]}
        />

        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid m={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações de requisição de pessoal
            </Typography>
            <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"}>
              {personnelRequisitionRequestTypes.map((option: any) => (
                <FormControlLabel
                  label={option.label}
                  key={option.value}
                  slotProps={{
                    typography: {
                      color: formik.errors.name ? "error" : "inherit",
                      fontSize: 13,
                      fontWeight: 400,
                    },
                  }}
                  onBlur={formik.handleBlur}
                  control={
                    <Checkbox
                      checked={
                        formik.values?.active_request_types?.indexOf(
                          option?.value
                        ) !== -1
                      }
                      onChange={() => handleChangeRequestType(option.value)}
                    />
                  }
                />
              ))}
            </Grid>
          </Grid>
          <Grid m={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações para novos colaboradores
            </Typography>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr"}
              display={"grid"}
              mt={2}
            >
              <SelectField
                formik={formik}
                id={"position_search_method"}
                label="Forma de escolher a posição do colaborador:"
                options={positionSearchOptions}
              />
            </Grid>
          </Grid>
        </Paper>

        <InfoComponent
          instructionMap={[
            "Na seção abaixo é permitido a configuração de usuários com permissão aos fluxos de requisição de pessoal.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />

        <ConfigSelection
          leftLabel="Usuários sem permissão:"
          rightLabel="Usuários com permissão:"
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <InfoComponent
          instructionMap={[
            "Na seção abaixo é permitido a configuração dos campos que serão exibidos no fluxo de requisição de pessoal de acordo com o perfil.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
          ]}
        />

        <ConfigSelection
          leftLabel="Perfil RH - campos ocultos:"
          rightLabel="Perfil RH - campos liberados:"
          left={leftRhFields}
          right={rightRhFields}
          options={rhFieldsOptions}
          setLeft={setLeftRhFields}
          setRight={setRightRhFields}
        />

        <ConfigSelection
          leftLabel="Perfil Gestor - campos ocultos:"
          rightLabel="Perfil Gestor - campos liberados:"
          left={leftManagerFields}
          right={rightManagerFields}
          options={managerFieldsOptions}
          setLeft={setLeftManagerFields}
          setRight={setRightManagerFields}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}
          >
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
