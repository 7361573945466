/** @format */

import { Add } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { TabsTableComponent } from "../../../../components/tabsTableComponent";
import { FormatDate } from "../../../../helpers/formatDate";
import { DialogDismissalNew } from "./helpers/dialogDismissalNew";
import { Table } from "./helpers/table";

export function Dismissals() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const [openModalDismissal, setOpenModalDismissal] = useState({
    open: false,
    type: "",
    date: "",
  });
  const delegation = useSelector((state: any) => state.user.delegation_data);

  return (
    <Grid container gap={2}>
      {openModalDismissal.open && (
        <DialogDismissalNew
          {...openModalDismissal}
          close={() =>
            setOpenModalDismissal((prev) => ({ ...prev, open: false }))
          }
        />
      )}
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Solicitações", value: "/request/dismissals" },
          {
            label: "Desligamentos",
          },
        ]}
        buttons={
          !delegation?.delegated_session && (
            <ButtonComponent
              variant={"contained"}
              onClick={() => {
                if (
                  user.business_unit_job_security_date &&
                  new Date(user.business_unit_job_security_date) >= new Date()
                ) {
                  setOpenModalDismissal({
                    open: true,
                    type: user.business_unit_job_security_type,
                    date: FormatDate(user.business_unit_job_security_date),
                  });
                } else {
                  router(`/request/dismissals/new/${user.uuid}`);
                }
              }}
              endIcon={<Add />}>
              Solicitar desligamento
            </ButtonComponent>
          )
        }
      />
      <TabsTableComponent
        keyString="dismissals"
        tabs={[
          {
            type: "general_records",
            component: <Table type='general_records' />,
          },
          {
            type: "team_records",
            component: <Table type='team_records' />,
          },
          {
            type: "my_records",
            component: <Table type='my_records' />,
          },
        ]}
      />
    </Grid>
  );
}
