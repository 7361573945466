/** @format */

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { getIn } from "formik";

interface FieldProps {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
  onBlur?: (event: any) => void;
  onChange?: (value: any) => void;
}

const valueToDate = (value: any) => {
  if (!value || value === "" || value.length === 0) {
    return null;
  }
  return dayjs(value);
}


export function InputDateField({
  formik,
  id,
  label,
  disabled,
  onBlur,
  onChange,
}: FieldProps) {
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);
  return (
    <DatePicker
      slotProps={{
        textField: {
          fullWidth: true,
          id: id,
          onBlur: (event) => {
            formik.handleBlur(event);
            onBlur && onBlur(event);
          },
          error: error,
          helperText: formik.errors[id],
        },
      }}
      openTo='month'
      views={["year", "month", "day"]}
      disabled={disabled}
      label={label}
      formatDensity='spacious'
      value={valueToDate(value)}
      onChange={(value: any) => {
        formik.setFieldTouched(id, true);
        formik.setFieldValue(id, value);
        onChange && onChange(value);
      }}
    />
  );
}
