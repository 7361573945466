import * as yup from "yup";
export const types = [
  { value: "1", label: "Por tipo de documento" },
  { value: "2", label: "Em lote" },
];

export const time_type = [
  { value: "year", label: "Por ano" },
  { value: "month", label: "Por mês" },
];

export const validationSchema = yup.object({
  document_type: yup.string().nullable().required("Campo é obrigatório"),
  time_type: yup.string().nullable().required("Campo é obrigatório"),
  time: yup.string().nullable().required("Campo é obrigatório"),
  files: yup
    .array()
    .min(1, `Precisa ter 1 anexo`)
    .nullable()
    .required("Arquivo é obrigatório"),
});


