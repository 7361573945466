/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircleOutline, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";
import { Personal } from "./helpers/personal";
import {
  getUniquePersonnelRequisitions,
  getPositionsFilteredByPersonnelRequisition,
} from "./helpers/personnelRequisition";

export function EmployeeNew() {
  const router = useNavigate();
  const [valide, setValide] = useState(false);
  const [data, setData] = useState<any>({
    positions: [],
    config: { position_search_method: "position" },
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      type_of_disability: [],
      position_id: "",
      personnel_requisition_solicitation: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values: any) => {
      setValide(true);
      dispatch(updateLoading(true));
      if (!values.is_disability) {
        delete values.type_of_disability;
      }
      delete values.personnel_requisition;
      PostInstante(null, values, "/users")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/employee/pending");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/external_recruitment_positions")
      .then((response) => {
        setData(response.data.data);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Colaboradores", value: "/employee/active" },
            { label: "Pendentes", value: "employee/pending" },
            { label: "Novo colaborador" },
          ]}
        />
        <InfoComponent instruction="Nesta tela é realizado o cadastro de novos colaboradores. " />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Cadastrar novo colaborador
              </Typography>
            </Grid>

            {data?.config?.position_search_method ===
            "personnel_requisition" ? (
              <>
                <Grid>
                  <Typography fontSize={16} fontWeight={500}>
                    Solicitação
                  </Typography>
                </Grid>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}
                >
                  <AutoCompleteField
                    id="personnel_requisition_solicitation"
                    label="Requisição de pessoal"
                    formik={formik}
                    options={getUniquePersonnelRequisitions(data.positions).map(
                      (requisition) => ({
                        label: `${requisition.code} - ${requisition.job_role}`,
                        value: requisition.id,
                      })
                    )}
                  />

                  <AutoCompleteField
                    options={getPositionsFilteredByPersonnelRequisition(
                      data.positions,
                      formik.values.personnel_requisition_solicitation
                    ).map((position) => ({
                      value: position?.id,
                      label: `${position?.code} - ${position?.job_role}`,
                    }))}
                    disabled={!formik.values.personnel_requisition_solicitation}
                    labelcode={false}
                    formik={formik}
                    id="position_id"
                    label="Posição"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Typography fontSize={16} fontWeight={500}>
                  Posição
                </Typography>
                <Grid
                  gridTemplateColumns={"1fr "}
                  mt={2}
                  display={"grid"}
                  gap={2}
                >
                  <AutoCompleteField
                    options={data.positions.map((item: any) => ({
                      value: item.id,
                      label: `${item.code} - ${item.job_role}`,
                    }))}
                    formik={formik}
                    id="position_id"
                    label="Posição"
                  />
                </Grid>
              </>
            )}

            <Personal formik={formik} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"}>
              <Grid container gap={2} justifyContent={"end"}>
                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}
                >
                  Voltar
                </ButtonComponent>
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircleOutline />}
                >
                  Criar usuário
                </ButtonComponent>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
