import { useSelector } from "react-redux";
import { Filter } from "../../../../../components/filter";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";

export function Filters({ formik, options }: any) {
  const user = useSelector((state: any) => state.user.data);
  return (
    <Filter
      formik={formik}
      id={"reports_dismissal"}
      keys={{
        manager_id: { label: "Gestor", options: options?.managers ?? [] },
        business_unit_id: {
          label: "Unidade de negócio",
          options: options?.business_units ?? [],
        },
        dismissal_type_id: {
          label: "Tipo de desligamento",
          options: options?.dismissal_types ?? [],
        },
        filter_start_date: { label: "Data de início", type: "date" },
        filter_end_date: { label: "Data de fim", type: "date" },
        department_id: {
          label: "Departamento",
          options: options?.departments ?? [],
        },
        job_role_id: { label: "Cargo", options: options?.job_roles ?? [] },
      }}
    >
      <InputDateField
        formik={formik}
        label="Data de início"
        id="filter_start_date"
      />
      <InputDateField
        formik={formik}
        label="Data de fim"
        id="filter_end_date"
      />
      {user.role !== "manager" && (
        <AutoCompleteField
          formik={formik}
          label="Gestor"
          id="manager_id"
          options={options?.managers}
        />
      )}
      <AutoCompleteField
        formik={formik}
        label="Tipo de desligamento"
        id="dismissal_type_id"
        options={options?.dismissal_types}
      />
      <AutoCompleteField
        formik={formik}
        label="Unidade de negócio"
        id="business_unit_id"
        options={options?.business_units}
      />
    </Filter>
  );
}
