/** @format */

import * as yup from "yup";

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "days_quantity", label: "Dias solicitados" },
  { name: "start_date", label: "Data inicial" },
  { name: "end_date", label: "Data final" },
  { name: "status", label: "Status" },
];

export const validationSchema = (data: any, userData: any) => {
  if (userData?.role === "rh" && !(userData?.data?.id === data?.target_user_id)) {
    return yup.object().shape({
      vacation_items: yup.array().of(
        yup.object().shape({
          days_quantity: yup
          .number()
          .min(5, "Mínimo de 5 dias")
          .required("Campo obrigatório")
          .test(
            "assiduity_allowance",
            "Quantidade de dias superior ao disponivel.",
            function (item: any, all: any) {
              return all.options.context.days >= item;
            }
          ),
        start_date: yup
          .date()
          .typeError("Data inválida")
          .nullable("Data inválida")
          .required("Campo obrigatório")
          .test(
            "min",
            "Data de inicio não pode ser anterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_start.getTime() <
                new Date(item).getTime()
              );
            }
          )
          .test(
            "min_policy",
            "Data não respeita as regras da empresa",
            function (item: any, all: any) {
              const policyDay = new Date();
              policyDay.setDate(
                policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
              );
              return new Date(item).getTime() > policyDay.getTime();
            }
          ),
        end_date: yup
          .date()
          .nullable("Data inválida")
          .test(
            "min",
            "Data de término não pode ser posterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_end.getTime() >
                new Date(item).getTime()
              );
            }
          )
          .typeError("Data de início ou quantidade inválido."),
        thirteen_anticipation: yup
          .string()
          .nullable()
          .required("Campo obrigatório"),
        abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
          assiduity_allowance: yup.string().nullable().test(
            "max",
            "Campo obrigatório",
            function (item: any) {
              const verification = (data.vacations_rules?.use_assiduity_allowance && !data.has_assiduity)
              return verification ? !!item === true : true;
            }
          ),
      }))
    });
  }

  return yup.object().shape({
    vacation_items: yup.array().of(
      yup.object().shape({
        days_quantity: yup
          .number()
          .min(5, "Mínimo de 5 dias")
          .required("Campo obrigatório")
          .test(
            "assiduity_allowance",
            "Quantidade de dias superior ao disponivel.",
            function (item: any, all: any) {
              return all.options.context.days >= item;
            }
          ),
        start_date: yup
          .date()
          .typeError("Data inválida")
          .nullable("Data inválida")
          .required("Campo obrigatório")
          .test(
            "min",
            "Data de inicio não pode ser anterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_start.getTime() <
                new Date(item).getTime()
              );
            }
          )
          .test(
            "min_policy",
            "Data não respeita as regras da empresa",
            function (item: any, all: any) {
              const policyDay = new Date();
              policyDay.setDate(
                policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
              );
              return new Date(item).getTime() > policyDay.getTime();
            }
          ),
        end_date: yup
          .date()
          .nullable("Data inválida")
          .test(
            "min",
            "Data de término não pode ser posterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_end.getTime() >
                new Date(item).getTime()
              );
            }
          )
          .typeError("Data de início ou quantidade inválido."),
        thirteen_anticipation: yup
          .string()
          .nullable()
          .required("Campo obrigatório"),
        abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
      })
    ),
  });
};

export const validationSchemaEdit = (data: any, userData: any) => {
  if (userData?.role === "rh" && !(userData?.data?.id === data?.target_user_id)) {
    return yup.object().shape({
      vacation_items: yup.array().of(
        yup.object().shape({
          days_quantity: yup
          .number()
          .min(5, "Mínimo de 5 dias")
          .required("Campo obrigatório")
          .test(
            "assiduity_allowance",
            "Quantidade de dias superior ao disponivel.",
            function (item: any, all: any) {
              return all.options.context.days >= item;
            }
          ),
        start_date: yup
          .date()
          .typeError("Data inválida")
          .nullable("Data inválida")
          .required("Campo obrigatório")
          .test(
            "min",
            "Data de inicio não pode ser anterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_start.getTime() <
                new Date(item).getTime()
              );
            }
          )
          .test(
            "min_policy",
            "Data não respeita as regras da empresa",
            function (item: any, all: any) {
              const policyDay = new Date();
              policyDay.setDate(
                policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
              );
              return new Date(item).getTime() > policyDay.getTime();
            }
          ),
        end_date: yup
          .date()
          .nullable("Data inválida")
          .test(
            "min",
            "Data de término não pode ser posterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_end.getTime() >
                new Date(item).getTime()
              );
            }
          )
          .typeError("Data de início ou quantidade inválido."),
        thirteen_anticipation: yup
          .string()
          .nullable()
          .required("Campo obrigatório"),
        abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
          assiduity_allowance: yup.string().nullable().test(
            "max",
            "Campo obrigatório",
            function (item: any) {
              const verification = (data.vacations_rules?.use_assiduity_allowance && !data.has_assiduity)
              return verification ? !!item === true : true;
            }
          ),
      }))
    });
  }

  return yup.object().shape({
    vacation_items: yup.array().of(
      yup.object().shape({
        days_quantity: yup
          .number()
          .min(5, "Mínimo de 5 dias")
          .required("Campo obrigatório")
          .test(
            "assiduity_allowance",
            "Quantidade de dias superior ao disponivel.",
            function (item: any, all: any) {
              return all.options.context.days >= item;
            }
          ),
        start_date: yup
          .date()
          .typeError("Data inválida")
          .nullable("Data inválida")
          .required("Campo obrigatório")
          .test(
            "min",
            "Data de inicio não pode ser anterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_start.getTime() <
                new Date(item).getTime()
              );
            }
          )
          .test(
            "min_policy",
            "Data não respeita as regras da empresa",
            function (item: any, all: any) {
              const policyDay = new Date();
              policyDay.setDate(
                policyDay.getUTCDate() +
                all.options.context.vacations_request_minimum_days
              );
              return new Date(item).getTime() > policyDay.getTime();
            }
          ),
        end_date: yup
          .date()
          .nullable("Data inválida")
          .test(
            "min",
            "Data de término não pode ser posterior ao período concessivo",
            function (item: any, all: any) {
              return (
                all.options.context.concessive_period_end.getTime() >
                new Date(item).getTime()
              );
            }
          )
          .typeError("Data de início ou quantidade inválido."),
        thirteen_anticipation: yup
          .string()
          .nullable()
          .required("Campo obrigatório"),
        abono_pecuniario: yup.string().nullable().required("Campo obrigatório"),
      })
    ),
  });
};
