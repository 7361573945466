/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function PermissionsDismissals() {
  const [reasons, setReasons] = useState<any>([]);
  const [leftReasons, setLeftReasons] = useState<any>([]);
  const [rightReasons, setRightReasons] = useState<any>([]);

  const [companies, setCompanies] = useState<any>([]);
  const [leftCompanies, setLeftCompanies] = useState<any>([]);
  const [rightCompanies, setRightCompanies] = useState<any>([]);

  const [reasonsTypes, setReasonsTypes] = useState<any>([]);
  const [leftReasonsTypes, setLeftReasonsTypes] = useState<any>([]);
  const [rightReasonsTypes, setRightReasonsTypes] = useState<any>([]);

  const [reasonsCollaborators, setReasonsCollaborator] = useState<any>([]);
  const [leftReasonsCollaborators, setLeftReasonsCollaborator] = useState<any>(
    []
  );
  const [rightReasonsCollaborators, setRightReasonsCollaborators] =
    useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/dismissal_types")
      .then((response) => {
        const reasonsLabel = response.data.group_0.map((r: any) => ({
          label: r.name,
          value: r.id,
          ...r,
        }));
        const colaboratorsLabel = response.data.group_1.map((r: any) => ({
          label: r.name,
          value: r.id,
          ...r,
        }));

        const reasonsLeft = response.data.group_0
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const reasonsRight = response.data.group_0
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        const typesCollaboratorLeft = response.data.group_1
          .filter((r: any) => !r.associated)
          .map((r: any) => r.id);
        const typesCollaboratorRight = response.data.group_1
          .filter((r: any) => r.associated)
          .map((r: any) => r.id);

        setCompanies(colaboratorsLabel);
        setLeftCompanies(typesCollaboratorLeft);
        setRightCompanies(typesCollaboratorRight);

        setRightReasons(reasonsRight);
        setLeftReasons(reasonsLeft);
        setReasons(reasonsLabel);
        GetInstance({}, "/dismissal_reasons")
          .then((response) => {
            const reasonsLabel = response.data.group_0.map((r: any) => ({
              label: r.name,
              value: r.id,
              ...r,
            }));
            const reasonsLeft = response.data.group_0
              .filter((r: any) => !r.associated)
              .map((r: any) => r.id);
            const reasonsRight = response.data.group_0
              .filter((r: any) => r.associated)
              .map((r: any) => r.id);

            const typesCollaboratorLeft = response.data.group_0
              .filter((r: any) => !r.associated)
              .map((r: any) => r.id);
            const typesCollaboratorRight = response.data.group_0
              .filter((r: any) => r.associated)
              .map((r: any) => r.id);

            setLeftCompanies(typesCollaboratorLeft);
            setRightCompanies(typesCollaboratorRight);

            setRightReasonsTypes(reasonsRight);
            setLeftReasonsTypes(reasonsLeft);
            setReasonsTypes(reasonsLabel);

            const reasonsLabelCollaborator = response.data.group_1.map(
              (r: any) => ({
                label: r.name,
                value: r.id,
                ...r,
              })
            );

            const reasonsLeftCollaborator = response.data.group_1
              .filter((r: any) => !r.associated)
              .map((r: any) => r.id);
            const reasonsRightCollaborator = response.data.group_1
              .filter((r: any) => r.associated)
              .map((r: any) => r.id);

            setRightReasonsCollaborators(reasonsRightCollaborator);
            setLeftReasonsCollaborator(reasonsLeftCollaborator);
            setReasonsCollaborator(reasonsLabelCollaborator);
            dispatch(updateLoading(false));
          })
          .catch((err) => {
            GenericError(err, dispatch);
          });
      })
      .catch((err) => {
        GenericError(err, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));
    PutInstante(
      null,
      {
        dismissal_types_data: [
          { group: 0, types: rightReasons },
          { group: 1, types: rightCompanies },
        ],
        dismissal_reasons_data: [
          { group: 0, types: rightReasonsTypes },
          { group: 1, types: rightReasonsCollaborators },
        ],
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/dismissals" },
            { label: "Desligamento" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta tela é permitido a configuração dos tipos de desligamento.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
          ]}
        />
        <ConfigSelection
          leftLabel="Perfil RH e Gestor - Tipos sem permissão:"
          rightLabel="Perfil RH e Gestor -  Tipos com permissão:"
          left={leftReasons}
          right={rightReasons}
          options={reasons}
          setLeft={setLeftReasons}
          setRight={setRightReasons}
        />
        <ConfigSelection
          leftLabel="Perfil Colaborador - Tipos sem permissão:"
          rightLabel="Perfil Colaborador - Tipos com permissão:"
          left={leftCompanies}
          right={rightCompanies}
          options={companies}
          setLeft={setLeftCompanies}
          setRight={setRightCompanies}
        />
        <ConfigSelection
          leftLabel="Perfil RH e Gestor - Motivos sem permissão:"
          rightLabel="Perfil RH e Gestor - Motivos com permissão:"
          left={leftReasonsTypes}
          right={rightReasonsTypes}
          options={reasonsTypes}
          setLeft={setLeftReasonsTypes}
          setRight={setRightReasonsTypes}
        />
        <ConfigSelection
          leftLabel="Perfil Colaborador - Motivos sem permissão:"
          rightLabel="Perfil Colaborador - Motivos com permissão:"
          left={leftReasonsCollaborators}
          right={rightReasonsCollaborators}
          options={reasonsCollaborators}
          setLeft={setLeftReasonsCollaborator}
          setRight={setRightReasonsCollaborators}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={onSubmit}
            endIcon={<Save />}
          >
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
