import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { PostInstanteFormData } from "../../../../../services/api";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GenericError } from "../../../../../helpers/genericError";

export function Photo({ id, photo, setAvatar }: any) {
  const user = useSelector((state: any) => state.user.data);

  const theme = useTheme();
  const dispatch = useDispatch();
  const handleImageAvatar = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    if (e.target.files[0]) {
      dispatch(updateLoading(true));
      PostInstanteFormData(null, formData, `/users/${id}/upload_avatar`)
        .then((res) => {
          toast.success(res.data.message);
          setAvatar(res.data.data);
          setTimeout(() => {
            dispatch(updateLoading(false));
          }, 2500);
        })
        .catch((error) => GenericError(error, dispatch));
    }
  };

  return (
    <Grid>
      <Grid container flexDirection="column">
        <Typography fontSize={16} fontWeight={500}>
          Foto de perfil
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent={"center"}
          mb={2}
          flexDirection="row"
        >
          <Grid
            item
            style={{
              width: isMobile ? "140px" : "400px",
              height: isMobile ? "140px" : "420px",
              background: `linear-gradient(to bottom left, transparent 0%, ${theme?.palette.primary.main} 150%)`,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              borderRadius: "50%",
            }}
          >
            <Grid
              item
              style={{
                width: isMobile ? "100px" : "380px",
                height: isMobile ? "100px" : "400px",
                background: `linear-gradient(to top right, transparent 0%, ${theme?.palette.primary.main} 200%)`,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "50%",
              }}
            >
              <Avatar
                alt={
                  user?.social_name?.length > 1 ? user?.social_name : user?.name
                }
                src={photo ?? "/image.png"}
                sx={{
                  width: isMobile ? 128 : 380,
                  height: isMobile ? 128 : 400,
                  bgcolor: theme.palette.primary.main,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <input
            style={{ display: "none" }}
            type="file"
            id="input-avatar"
            onChange={handleImageAvatar}
          />
          <label htmlFor="input-avatar">
            <ButtonComponent
              component="span"
              endIcon={<Edit />}
              variant="contained"
            >
              Alterar foto
            </ButtonComponent>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}
