import { ArrowForwardIos, Folder } from "@mui/icons-material";
import { Box, Card, Flex, Text } from "@radix-ui/themes";

interface ItemProps {
  label: string;
  quantity: number;
  value: number;
  calendar: string;
}

export function CardCustom(item: ItemProps) {
  return (
    <Card asChild>
      <a href={"/filesAccount/" + item.value + '/' + item.calendar}>
        <Flex gap="3" justify={"between"} align={"center"} height={"80px"}>
          <Folder color={"primary"} />
          <Box style={{width:'100%'}}>
            <Text as="div" size="2" weight="bold">
              {item.label}
            </Text>
            <Text as="div" size="1" color="gray">
              {item.quantity} arquivos
            </Text>
          </Box>
          <ArrowForwardIos color="primary" />
        </Flex>
      </a>
    </Card>
  );
}
