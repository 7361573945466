/** @format */

import { Mail, Phone } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Avatar, Card, IconButton, Tooltip } from "@radix-ui/themes";
import { toast } from "react-toastify";
import { ActionsUser } from "../../../../components/actionsUser";

interface Props {
  company: any;
  user: any;
  orgTypes: string[];
  level: number;
}

export function CardUser({ company, user, orgTypes, level }: Props) {
  function valide(value: string) {
    return orgTypes.filter((item) => item === value).length > 0;
  }
  return (
    <Card style={{ display: "inline-block", height: 165, width: 230 + level * 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", gap: 8 }}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexDirection={"column"}
          >
            {valide("photo") && (
              <Avatar
                size="3"
                src={user?.avatar}
                radius="full"
                fallback={user?.name?.split("")[0] ?? ""}
              />
            )}
          </Grid>
          <div style={{ width: 180 }}>
            {valide("name") && (
              <Typography fontSize={12} fontWeight={800}>
                {user?.name?.toUpperCase()}
              </Typography>
            )}
            {valide("job_role_name") && user?.job_role_name && (
              <Typography fontSize={10}>{user?.job_role_name}</Typography>
            )}
            {valide("email") && user?.email && !user?.hide_info && (
              <Typography fontSize={10}>{user?.email}</Typography>
            )}
            {valide("phone") && user?.phone && !user?.hide_info && (
              <Typography fontSize={10}>{user?.phone}</Typography>
            )}
            {valide("unit_business") && user?.business_unit_name && (
              <Typography fontSize={10}>{user?.business_unit_name}</Typography>
            )}
          </div>
        </div>
        {valide("icons") && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {!user?.hide_info && (
              <>
                <Tooltip
                  content={
                    !user?.phone
                      ? "Sem número de telefone"
                      : "Copiar número de telefone"
                  }
                >
                  <IconButton
                    disabled={!user?.phone}
                    color={company.primary}
                    variant="ghost"
                    radius="full"
                    style={{ cursor: "pointer", margin: 2 }}
                    onClick={() => {
                      toast.success("Número de telefone copiado com sucesso.");
                      navigator.clipboard.writeText(user.phone);
                    }}
                  >
                    <Phone fontSize={"small"} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  content={
                    !user?.email
                      ? "Sem endereço de e-mail"
                      : "Copiar endereço de e-mail"
                  }
                >
                  <IconButton
                    disabled={!user?.email}
                    color={company.primary}
                    variant="ghost"
                    radius="full"
                    style={{ cursor: "pointer", margin: 2 }}
                    onClick={() => {
                      toast.success("Endereço de e-mail copiado com sucesso.");
                      navigator.clipboard.writeText(user.email);
                    }}
                  >
                    <Mail fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {(user.userActive || user.userManager || user.userOptions) && (
              <ActionsUser data={[user]} dataIndex={0} />
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
