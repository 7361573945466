/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";

import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";

interface FormProps {
  formik: any;
  unions: any;
}

export function Form({
  formik,
  unions
}: FormProps) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          id="description"
          formik={formik}
          label={"Descrição"}
        />
        <InputTextField
          id="duration"
          formik={formik}
          label={"duração"}
          type="number"
        />
        <SelectField
          formik={formik}
          id={"union_id"} 
          label='Sindicato'
          options={unions}
        />
      </Grid>

      <Grid container gap={2} mt={2} justifyContent={"end"}>
        <ButtonComponent
          variant={"outlined"}
          onClick={() => {
            window.history.back();
          }}
          endIcon={<Undo />}>
          Voltar
        </ButtonComponent>
        <ButtonComponent
          variant={"contained"}
          type={"submit"}
          endIcon={<AddCircle />}>
          Salvar
        </ButtonComponent>
      </Grid>
    </form>
  );
}
