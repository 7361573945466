import { ArrowCircleDown } from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";

export function customList(
  items: any,
  title: string,
  color: any,
  checked: any,
  setCheck: any,
  options: any
) {
  return (
    <Grid container flexDirection={"column"}>
      <Callout.Root
        color={color ?? "amber"}
        size='2'
        style={{ alignItems: "center" }}
        mb={"2"}>
        <Callout.Icon>
          <ArrowCircleDown />
        </Callout.Icon>
        <Grid container flexDirection={"column"}>
          <Typography fontSize={16} fontWeight={400}>
            {title}
          </Typography>
        </Grid>
      </Callout.Root>
      <Paper sx={{ width: "100%", height: "30dvh", overflow: "auto" }}>
        <List dense component='div' role='list'>
          {items
            .toSorted((a: any, b: any) => a - b)
            .map((value: any) => {
              const labelId = `transfer-list-item-${value}-label`;
              return (
                <ListItemButton
                  key={value + title}
                  role='listitem'
                  onClick={() => {
                    const currentIndex = checked.indexOf(value);
                    const newChecked = [...checked];
                    if (currentIndex === -1) {
                      newChecked.push(value);
                    } else {
                      newChecked.splice(currentIndex, 1);
                    }
                    setCheck(newChecked);
                  }}>
                  
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      options.filter((opt: any) => opt.value === value)[0]
                        ?.label
                    }
                  />
                </ListItemButton>
              );
            })}
        </List>
      </Paper>
      <Typography fontSize={14} fontWeight={400} ml={2} mt={1}>
        Total de registros: {items.length}
      </Typography>
    </Grid>
  );
}