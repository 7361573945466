import { Cancel, CheckCircle } from "@mui/icons-material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { ButtonComponent } from "../../../../../components/buttonComponent";

export function DialogNew({ open, setOpen, formik }: any) {
  return (
    <AlertDialog.Root open={open} onOpenChange={setOpen}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>
          Deseja realmente desligar este colaborador?
        </AlertDialog.Title>
        <AlertDialog.Description size="2">
          Após a confirmação, não será possível reverter o processo. Tenha
          absoluta certeza antes de realizar essa ação.
        </AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <ButtonComponent
              variant={"outlined"}
              color="error"
              endIcon={<Cancel />}
            >
              Desistir
            </ButtonComponent>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonComponent
              color="success"
              variant={"contained"}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
              endIcon={<CheckCircle />}
            >
              Confirmar
            </ButtonComponent>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
