/** @format */

import { AddCircleOutline, CancelOutlined, Edit } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Dialog, Flex } from "@radix-ui/themes";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import RadioGroupComponent from "../../../../../components/inputs/radioGroup";
import { referenceOptions } from "./constants";

export function DialogModal({
  formik,
  openModal,
  setOpenModal,
  setValide,
}: any) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog.Root
        open={openModal.open}
        onOpenChange={() => {
          setValide(false);
          setOpenModal({ open: false, type: "" });
          formik.handleReset();
        }}
      >
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>
            {openModal.type === "create"
              ? "Nova faixa salarial"
              : "Editar faixa salarial"}
          </Dialog.Title>
          <Dialog.Description size="2" mb="4">
            {openModal.type === "create"
              ? "Preencha os campos abaixo para criar uma nova faixa salarial:"
              : "Edite os campos abaixo para alterar a faixa salarial:"}
          </Dialog.Description>

          <Grid mt={1} display="flex" flexDirection="column" gap={2}>
            <RadioGroupComponent
              formik={formik}
              label="Referência"
              id="name"
              options={referenceOptions}
            />
            <InputTextField
              formik={formik}
              label="Nome da faixa"
              id="description"
            />
          </Grid>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <ButtonComponent
                color="error"
                variant="outlined"
                endIcon={<CancelOutlined />}
              >
                Cancelar
              </ButtonComponent>
            </Dialog.Close>
            <ButtonComponent
              color="success"
              variant="contained"
              onClick={() => {
                setValide(true);
                formik.handleSubmit();
              }}
              endIcon={
                openModal.type === "create" ? <AddCircleOutline /> : <Edit />
              }
            >
              {openModal.type === "create" ? "Criar" : "Editar"}
            </ButtonComponent>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </form>
  );
}
