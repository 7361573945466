/** @format */

export function FormatStatusToHistoric(value: string) {
    const statusDict = {
      pending: "Pendente",
      approved: "Aprovou",
      approved_on_hold: "Aprovou",
      disapproved: "Reprovou",
      accounting_disapproved: "Reprovou",
      archived: "Arquivou",
      active: "Ativo",
      on_leave: "Afastado",
      accepted: "Férias aceita",
      inactive: "Inativo",
      open: "Aberta",
      awaiting_acceptance: "Aguardando aceite",
      scheduled: "Agendada",
      waiting_schedule: "Aguardando agendamento",
      vacations_scheduled: "Férias agendadas",
      vacations_waiting_schedule: "Férias aguardando agendamento",
      returned: "Retornou",
      occupied: "Ocupada",
      hiring: "Em contratação",
      processed: "Processado",
      failed: "Não processado",
      waiting_manager_user: "Enviou para o gestor",
      waiting_rh_user: "Enviou para o RH",
      waiting_transfer_approval: "Aguardando RH",
      cancelled: "Cancelou",
      completed: "Concluiu",
      accounting_return: "Devolução folha",
    };
    const exist = Object.entries(statusDict).filter(
      (opt: any) => opt[0] === value
    );
    return value ? (exist.length > 0 ? exist[0][1] : "Não possui") : "Não possui";
  }
  