/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { ConfigSelection } from "../../../../components/configSelection";

export function PermissionsAdmission() {
  const [usersOptions, setUsersOptions]: any = useState([]);
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);


  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      admission_interval: "",
      admission_bypass_users: [],
    },
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          admission_interval: values.admission_interval,
          admission_bypass_users: rightApprovers,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/users/options?settings=true")
      .then((usersResponse: any) => {
        setUsersOptions(usersResponse.data.data);

        GetInstance({}, "/company_settings/")
          .then((companySettingsResponse: any) => {
            formik.setValues({
              admission_interval: companySettingsResponse.data.data.record.admission_interval,
            });
            const arrayIds = usersResponse.data.data.map((opt: any) => opt.value);
            if (companySettingsResponse.data.data.record.admission_bypass_users?.length > 0) {
              if (companySettingsResponse.data.data.record.admission_bypass_users) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>

                    !companySettingsResponse.data.data.record.admission_bypass_users?.includes(
                      item
                    )
                );
                setRightApprovers(
                  companySettingsResponse.data.data.record.admission_bypass_users
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/admission" },
            { label: "Admissão" },
          ]}
        />

        <InfoComponent instruction='Aqui você pode configurar as parametrizações da admissão' />
        {/* pensar em alguma mensagem mais descritiva */}

        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações da auditória
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <TextField
              fullWidth
              id={`admission_interval`}
              type='number'
              label='Intervalo entre Solicitação e Admissão'
              variant='outlined'
              value={formik.values.admission_interval}
              onChange={(event: any) => {
                formik.setFieldValue(`admission_interval`, event.target.value);
              }}
              error={Boolean(formik.errors.admission_interval)}
              helperText={formik.errors.admission_interval}
            />
          </Grid>
        </Paper>

        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={usersOptions}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
