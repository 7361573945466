/** @format */

import { East } from "@mui/icons-material";
import {
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Link,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ModeTheme } from "../../../helpers/mode";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../services/api";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GenericError } from "../../../helpers/genericError";
import { InputTextField } from "../../../components/inputs/inputTextField";
import { ButtonComponent } from "../../../components/buttonComponent";

const steps = [
  "Preencha o usuário",
  "Responda as perguntas",
  "Escolha da nova senha",
];

const validationUserSecretAnswers = yup.object({
  username: yup.string().required("Login é obrigatório"),
  questions: yup.array().of(
    yup.object({
      answer: yup.string().required("A resposta é obrigatória"),
    })
  ),
  newPassword: yup.object({
    value: yup
      .string()
      .required("Campo é obrigatório")
      .matches(/(?=.{8,})/, "A senha deve ter pelo menos 8 caracteres")
      .matches(
        /(?=.*?[A-Z])/,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(
        /(?=.*?[a-z])/,
        "A senha deve conter pelo menos uma letra minúscula"
      )
      .matches(/(?=.*?[0-9])/, "A senha deve conter pelo menos um número")
      .matches(
        /(?=.*?[#?!@$%^&*-])/,
        "A senha deve conter pelo menos um caractere especial"
      ),
  }),
  confirmPassword: yup.object({
    value: yup
      .string()
      .test(
        "passwords-match",
        "Nova senha e Confirmar senha não conferem",
        function (value, context) {
          return value === context?.options?.context?.newPassword?.value;
        }
      )
      .required("Campo é obrigatório"),
  }),
});

export function ForgetPasswordSecretQuestions() {
  const company = useSelector((state: any) => state.company);
  const router = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [token, setToken] = useState("");

  const formik: any = useFormik({
    initialValues: {
      username: Cookies.get("username") ?? "",
      questions: [],
      newPassword: { value: "", visibility: false },
      confirmPassword: { value: "", visibility: false },
    },
    validationSchema: validationUserSecretAnswers,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const params = {
        otp_token: token,
        password: values.newPassword.value,
        password_confirmation: values.confirmPassword.value,
      };
      PostInstante(null, params, "/password/reset_password")
        .then((response: any) => {})
        .then(() => {
          dispatch(updateLoading(false));
          toast.success("Senha alterada com sucesso");
          router("/");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  const find_secret_questions = () => {
    dispatch(updateLoading(true));
    GetInstance(
      { username: formik.values.username },
      "/secret_questions/validation_questions"
    )
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          username: formik.values.username,
          questions: response.data.data.record.map((question: any) => ({
            id: question.id,
            label: question.question,
            visibility: false,
            answer: "",
          })),
          newPassword: formik.values.newPassword,
          confirmPassword: formik.values.confirmPassword,
        });
        setActiveStep(1);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  const submit_secret_answers = () => {
    dispatch(updateLoading(true));
    const params = {
      username: formik.values.username,
      questions: formik.values.questions.map((question: any) => ({
        id: question.id,
        answer: question.answer,
      })),
    };
    PostInstante(null, params, "/secret_questions/verify_user_secret_answers")
      .then((response: any) => {
        setToken(response.data.data.otp_token);
        setActiveStep(2);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  const toggleQuestionsVisibility = (index: number) => {
    const updatedQuestions = formik.values.questions.map(
      (question: any, i: number) =>
        i === index
          ? { ...question, visibility: !question.visibility }
          : question
    );
    formik.setFieldValue("questions", updatedQuestions);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        padding={10}
      >
        <Grid style={{ position: "absolute", top: 10, right: 10 }}>
          <ModeTheme />
        </Grid>
        <Grid item xs={12} lg={6} md={4}>
          <Grid container justifyContent={"center"}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              maxWidth={500}
              gap={2}
            >
              <img {...company.logoDefault} alt="Logo da empresa" />
              <div>
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  fontSize={"2.4rem"}
                >
                  Redefinição de senha
                </Typography>
                <Typography textAlign={"center"} color={"grey"}>
                  Para redefinir sua senha, responda as pergunta e preencha a
                  nova senha desejada.
                </Typography>
              </div>

              <Grid sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              {activeStep === 0 && (
                <>
                  <InputTextField
                    id={"username"}
                    label={"Login"}
                    formik={formik}
                  />

                  <ButtonComponent
                    onClick={find_secret_questions}
                    disabled={!!formik.errors.username}
                    fullWidth
                    variant="contained"
                    endIcon={<East />}
                  >
                    Continuar
                  </ButtonComponent>
                </>
              )}

              {activeStep === 1 && (
                <>
                  {formik.values.questions.map(
                    (question: any, index: number) => (
                      <TextField
                        key={index}
                        fullWidth
                        id={`questions[${index}].answer`}
                        label={question.label}
                        variant="outlined"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            `questions[${index}].answer`,
                            event.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        type={question.visibility ? "text" : "password"}
                        error={
                          formik.touched.questions?.[index]?.answer &&
                          Boolean(formik.errors.questions?.[index]?.answer)
                        }
                        helperText={
                          formik.touched.questions?.[index]?.answer &&
                          formik.errors.questions?.[index]?.answer
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle visibility"
                                edge="end"
                                onClick={() => toggleQuestionsVisibility(index)}
                              >
                                {question.visibility ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility color="primary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  )}
                  <ButtonComponent
                    onClick={submit_secret_answers}
                    disabled={!!formik.errors.questions}
                    fullWidth
                    variant="contained"
                    endIcon={<East />}
                  >
                    Continuar
                  </ButtonComponent>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <TextField
                    fullWidth
                    key="newPassword.value"
                    id="newPassword.value"
                    label="Nova senha"
                    variant="outlined"
                    type={
                      formik.values.newPassword.visibility ? "text" : "password"
                    }
                    value={formik.values.newPassword.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.newPassword?.value &&
                      Boolean(formik.errors?.newPassword?.value)
                    }
                    helperText={
                      formik.touched?.newPassword?.value &&
                      formik.errors?.newPassword?.value
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              formik.setValues({
                                ...formik.values,
                                newPassword: {
                                  ...formik.values.newPassword,
                                  visibility:
                                    !formik.values.newPassword.visibility,
                                },
                              })
                            }
                            edge="end"
                          >
                            {formik.values.newPassword.visibility ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    key="confirmPassword.value"
                    id="confirmPassword.value"
                    label="Confirmar senha"
                    variant="outlined"
                    type={
                      formik.values.confirmPassword.visibility
                        ? "text"
                        : "password"
                    }
                    value={formik.values.confirmPassword.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.confirmPassword?.value &&
                      Boolean(formik.errors?.confirmPassword?.value)
                    }
                    helperText={
                      formik.touched?.confirmPassword?.value &&
                      formik.errors?.confirmPassword?.value
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              formik.setValues({
                                ...formik.values,
                                confirmPassword: {
                                  ...formik.values.confirmPassword,
                                  visibility:
                                    !formik.values.confirmPassword.visibility,
                                },
                              })
                            }
                            edge="end"
                          >
                            {formik.values.confirmPassword.visibility ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <ButtonComponent
                    type={"submit"}
                    disabled={
                      !!formik.errors.confirmPassword &&
                      !!formik.errors.newPassword
                    }
                    fullWidth
                    variant="contained"
                    endIcon={<East />}
                  >
                    Enviar
                  </ButtonComponent>
                </>
              )}

              <Link
                style={{ cursor: "pointer" }}
                underline={"hover"}
                href={"/login"}
              >
                Voltar
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item lg={6} md={8}>
            <Grid container justifyContent={"center"}>
              <img
                src={company.image}
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  height: "auto",
                  borderRadius: 20,
                  width: "80%",
                  maxHeight: 700,
                }}
                width={0}
                height={0}
                sizes="100vw"
                alt="Imagem da empresa"
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
}
