/** @format */

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";
import { FormatStatusToHistoric } from "../../../../../helpers/formatStatusToHistoric";
import { LabelFormat } from "../../../../../helpers/labelFormat";

export const HistoryComponent = ({ data, options }: any) => {
  const theme = useTheme();
  const [expandedHistoricId, setExpandedHistoricId] = useState<any>(null);
  const solicitationHistories =
    data?.length &&
    data?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);

      return dateB - dateA;
    });
  return data?.length > 0 ? (
    <>
      <Typography fontSize={18} fontWeight={500}>
        Histórico
      </Typography>
      {solicitationHistories.map((history: any, index: number) => {
        const data = history.new_data;
        const manager = options?.manager.find((item: any) => item?.id=== history.new_data?.manager_id);
        const department = options?.department.find((item: any) => item?.id === history.new_data?.department_id);
        const costCenter = options?.cost_center.find((item: any) => item?.id === history.new_data?.cost_center_id);
        const businessUnit = options?.business_unit.find((item: any) => item?.id === history.new_data?.business_unit_id);
        const jobRole = options?.job_role.find((item: any) => item?.value === history.new_data?.job_role_id);
        const reasonSolicitation = options?.reason_solicitation.find((item: any) => item?.id === history.new_data?.reason_solicitation_id);

        return (
          <Accordion
            sx={{
              marginTop: 3.5,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              "&:before": {
                display: "none",
              },
            }}
            expanded={expandedHistoricId === history?.id}
            onChange={() => setExpandedHistoricId(history?.id)}>
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'
              expandIcon={
                <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
              }
              sx={{
                margin: 0,
                flexDirection: "row-reverse",
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
              }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='100%'>
                <Typography marginLeft={2} fontSize={18} fontWeight={500}>
                  {`${history.user_name} - ${FormatStatusToHistoric(
                    history.status
                  )}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid display='flex' flexDirection='column' gap={2}>
                <Grid
                  gridTemplateColumns={"1fr 1fr"}
                  display={"grid"}
                  gap={2}>
                  <TextField
                    fullWidth
                    label='Gestor'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={manager?.name}
                  />
                  <TextField
                    fullWidth
                    label={LabelFormat("department")}
                    variant='outlined'
                    rows={4}
                    disabled
                    value={`${department?.code} - ${department?.name}`}
                  />
                  <TextField
                    fullWidth
                    label='Centro de custo'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={`${costCenter?.code} - ${costCenter?.description}`}
                  />
                  <TextField
                    fullWidth
                    label='Função'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={data?.name}
                  />
                  <TextField
                    fullWidth
                    label='Quantidade'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={data?.quantity}
                  />
                  <TextField
                    fullWidth
                    label={LabelFormat("business_unit")}
                    variant='outlined'
                    rows={4}
                    disabled
                    value={`${businessUnit?.code} - ${businessUnit?.name}`}
                  />
                  <TextField
                    fullWidth
                    label='Cargo'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={jobRole?.code ? `${jobRole?.code} - ${jobRole?.label}` : jobRole?.label} 
                  />
                  <TextField
                    fullWidth
                    label='Remuneração'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={
                      data?.salary &&
                      FormatCurrency({ target: { value: `${data.salary}` } })
                    }
                  />
                  <TextField
                    fullWidth
                    label='Motivo da solicitação'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={reasonSolicitation?.name}
                  />
                  <TextField
                    fullWidth
                    label='Tipo de posição'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={history.new_data?.position_type}
                  />
                </Grid>
                <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Comentários'
                    variant='outlined'
                    rows={4}
                    multiline={true}
                    disabled
                    value={data?.observations}
                  />
                </Grid>
                {history.observations && (
                  <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                    <TextField
                      fullWidth
                      label='Justificativa'
                      variant='outlined'
                      rows={4}
                      multiline={true}
                      disabled
                      value={history.observations}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <></>
  );
};
