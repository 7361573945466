/** @format */

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { GenericError } from "../../../helpers/genericError";
import { ModeTheme } from "../../../helpers/mode";
import { updateLoading } from "../../../redux/reducers/common";
import { initial, updatePhoto, updateUser } from "../../../redux/reducers/user";
import { GetInstance, PostInstante } from "../../../services/api";

const validationSchema = yup.object({
  username: yup.string().required("Login é obrigatório"),
  password: yup.string().required("Senha é obrigatório"),
});

export function LoginPage() {
  const company = useSelector((state: any) => state.company);
  const [showPassword, setShowPassword] = useState(false);
  const router = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: Cookies.get("username") ?? "",
      password: "",
      remember: Cookies.get("remember") === "true" ? true : false ?? false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/login")
        .then((response: any) => {
          dispatch(updateUser(response.data.data.user));
          dispatch(updateLoading(false));
          Cookies.set("auth", response.data.data.token);
          router("/");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateUser(initial));
    dispatch(updatePhoto(initial.photo));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        padding={10}>
        <Grid style={{ position: "absolute", top: 10, right: 10 }}>
          <ModeTheme />
        </Grid>
        <Grid item xs={12} lg={6} md={4}>
          <Grid container justifyContent={"center"}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              maxWidth={500}
              gap={2}>
              <img {...company.logoDefault} alt='Logo da empresa' />
              <div>
                <Typography fontWeight={600} fontSize={"2.4rem"}>
                  Acesse sua conta
                </Typography>
                <Typography color={"grey"}>
                  Bem vindo ao nosso sistema de gestão de RH.
                </Typography>
              </div>
              <TextField
                fullWidth
                id='username'
                label='Login'
                variant='outlined'
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                fullWidth
                id='password'
                label='Senha'
                autoComplete={"form-password"}
                variant='outlined'
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'>
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility color='primary' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container alignItems={"center"}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"remember"}
                        checked={formik.values.remember}
                        onChange={(event: any) => {
                          if (event.target.checked) {
                            formik.setFieldValue(
                              "remember",
                              event.target.checked
                            );
                            Cookies.set("username", formik.values.username);
                            Cookies.set("remember", "true");
                          } else {
                            Cookies.remove("username");
                            formik.setFieldValue(
                              "remember",
                              event.target.checked
                            );
                            Cookies.remove("remember");
                          }
                        }}
                      />
                    }
                    label='Lembrar-me'
                  />
                </Grid>
                <Grid item xs={6} textAlign={"end"}>
                  <Button
                    onClick={() => {
                      dispatch(updateLoading(true));
                      GetInstance(
                        { name: company.company },
                        "/company_settings/password_recovery_settings"
                      )
                        .then(async (response: any) => {
                          if (
                            !Object.values(
                              response.data.data.password_recovery_settings
                            ).includes(true)
                          ) {
                            toast.error(
                              "Não possui método de recuperação de senha, por favor entre em contato com o RH."
                            );
                          } else if (
                            Object.values(
                              response.data.data.password_recovery_settings
                            ).filter((item: any) => item === true).length === 1
                          ) {
                            const index = Object.values(
                              response.data.data.password_recovery_settings
                            ).indexOf(true);
                            const key = Object.keys(
                              response.data.data.password_recovery_settings
                            )[index];
                            switch (key) {
                              case "default":
                                router("/forgetPasswordStandard");
                                break;
                              case "email":
                                router("/forgetPasswordSendEmail");
                                break;
                              case "secret_questions":
                                router("/forgetPasswordSecretQuestion");
                                break;

                              default:
                                break;
                            }
                          } else {
                            router("/methodForgetPassword");
                          }
                          dispatch(updateLoading(false));
                        })
                        .catch((e: any) => {
                          if (e.code !== "ERR_CANCELED") {
                            dispatch(updateLoading(false));
                            toast.error(
                              e?.response?.data?.message ??
                                "Erro inesperado, tente novamente."
                            );
                          }
                        });
                    }}>
                    <Typography
                      style={{ cursor: "pointer" }}
                      textTransform={"none"}>
                      Esqueceu sua senha?
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Button
                type={"submit"}
                fullWidth
                variant='contained'
                endIcon={<Login />}>
                Entrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item lg={6} md={8}>
            <Grid container justifyContent={"center"}>
              <img
                src={company.image}
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  height: "auto",
                  borderRadius: 20,
                  width: "80%",
                  maxHeight: 700,
                }}
                width={0}
                height={0}
                sizes='80vw'
                alt='Imagem da empresa'
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
}
