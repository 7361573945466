/** @format */

import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { companysOptions } from "../config/companies";
import { updateCompany } from "../redux/reducers/company";

export const CompanyRouter = ({ children }: any) => {
  let url = window.location.href;
  const dispatch = useDispatch();
  if (
    window.location.pathname === "/" &&
    Cookies.get("company") === undefined
  ) {
    if (url.indexOf("development") !== -1 || url.indexOf("localhost") !== -1) {
      return <Navigate to="/companyOptions" replace />;
    }
  }
  const company: any =
    url.indexOf("development") !== -1 || url.indexOf("localhost") !== -1
      ? Cookies.get("company") ?? "3ClicksRH"
      : companysOptions.filter(
          (data: any) => url.split(".")[0].indexOf(data.url) !== -1
        )[0].company ??
        "3ClicksRH" ??
        "3ClicksRH";
  Cookies.set("company", company);
  const companySelect = companysOptions.filter(
    (item: any) => item.company === company
  )[0];
  dispatch(updateCompany(companySelect));
  return children;
};
