/** @format */

import { Grid, IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GenericError } from "../helpers/genericError";
import { updateLoading, updateLoadingSessao } from "../redux/reducers/common";
import { GetInstance, PutInstante } from "../services/api";
import { Cancel, Save, Visibility, VisibilityOff } from "@mui/icons-material";
import { AlertDialog } from "@radix-ui/themes";
import { ButtonComponent } from "./buttonComponent";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { InputTextField } from "./inputs/inputTextField";

export const SecretQuestionsRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const [hasUnansweredQuestions, setHasUnansweredQuestions]: any =
    useState(false);

  useEffect(() => {
    dispatch(updateLoadingSessao(true));
    GetInstance({}, `/secret_questions`)
      .then(async (response: any) => {
        const {
          secret_questions: secretQuestions,
          unanswered_questions: unansweredQuestions,
        } = response.data.data.records;
        // eslint-disable-next-line array-callback-return
        secretQuestions.map((question: any) => {
          if (unansweredQuestions.includes(question.id)) {
            setHasUnansweredQuestions(true);
          }
        });
        formik.setValues({
          questions: secretQuestions.map((question: any) => {
            const isUnanswered = unansweredQuestions.includes(question.id);
            return {
              id: question.id,
              label: question.question,
              visibility: false,
              answer: isUnanswered ? "" : "*************************",
              enable: isUnanswered,
            };
          }),
        });
        dispatch(updateLoadingSessao(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object({
    questions: yup.array().of(
      yup.object({
        answer: yup.string().required("A resposta é obrigatória"),
      })
    ),
  });

  const formik: any = useFormik({
    initialValues: {
      questions: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const params = {
        questions: values.questions
          .filter((question: any) => question.enable)
          .map((question: any) => ({
            id: question.id,
            answer: question.answer,
          })),
      };
      PutInstante(null, params, "/secret_questions/update_user_secret_answers")
        .then((r: any) => {
          dispatch(updateLoading(false));
          window.location.reload();
          toast.success(r.data.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  const toggleQuestionsVisibility = (index: number) => {
    const updatedQuestions = formik.values.questions.map(
      (question: any, i: number) =>
        i === index
          ? { ...question, visibility: !question.visibility }
          : question
    );
    formik.setFieldValue("questions", updatedQuestions);
  };

  const handleEnableQuestion = (index: number) => {
    const updatedQuestions = formik.values.questions.map(
      (question: any, i: number) =>
        i === index ? { ...question, answer: "", enable: true } : question
    );
    formik.setFieldValue("questions", updatedQuestions);
  };

  return (
    <>
      {children}
      <AlertDialog.Root open={hasUnansweredQuestions}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Perguntas secretas</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Foi identificado que existem perguntas secretas ainda não
            respondidas pelo o usuário.
          </AlertDialog.Description>
          <form onSubmit={formik.handleSubmit}>
            <Grid gap={2} mt={2} container flexDirection="column">
              {formik.values.questions.map((question: any, index: number) => (
                <InputTextField
                  formik={formik}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {question.enable ? (
                          <IconButton
                            aria-label="toggle visibility"
                            edge="end"
                            onClick={() => toggleQuestionsVisibility(index)}
                          >
                            {question.visibility ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="handle enable"
                            edge="end"
                            onClick={() => handleEnableQuestion(index)}
                          >
                            <Cancel />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  type={question.visibility ? "text" : "password"}
                  key={index}
                  id={`questions[${index}].answer`}
                  label={question.label}
                  disabled={!question.enable}
                />
              ))}
              <Grid container gap={2} mt={2} justifyContent={"end"}>
                <ButtonComponent
                  endIcon={<Save />}
                  type="submit"
                  variant="contained"
                >
                  Salvar modificações
                </ButtonComponent>
              </Grid>
            </Grid>
          </form>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};
