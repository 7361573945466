/** @format */

import * as yup from "yup";
import { typeLetter } from "../../../../../config/constants";
import { LabelFormat } from "../../../../../helpers/labelFormat";
export const columns = [
  { name: "id", label: "Código" },
  { name: "name", label: "Nome da carta" },
  { name: "solicitation_type", label: "Tipo da carta" },
  { name: "active", label: "Ativo" },
];

export const statusOptions = [
  { label: "Todos", value: "all" },
  { label: "Ativo", value: true },
  { label: "Inativo", value: false },
];

export const typeLetterOptions = [
  { label: "Todos", value: "all" },
  ...typeLetter,
];

export const variables = [
  { label: "Nome do colaborador", value: "collaborator_name" },
  { label: "Matricula do colaborador", value: "collaborator_identification" },
  { label: "Gestor", value: "collaborator_manager" },
  { label: "RG", value: "collaborator_rg" },
  { label: "Cargo do colaborador", value: "collaborator_job" },
  { label: "Novo cargo do colaborador", value: "collaborator_job_new" },
  { label: "Salário do colaborador", value: "collaborator_salary" },
  { label: "Novo salário do colaborador", value: "collaborator_salary_new" },
  { label: "Data da solicitação", value: "solicitation_date" },
  { label: "Data de vigência da solicitação", value: "solicitation_date_init" },
  { label: "Data de admissão", value: "collaborator_date_init" },
  { label: "Data de desligamento", value: "collaborator_date_end" },
  { label: "Porcentagem da solicitação", value: "solicitation_percent" },
  { label: LabelFormat("business_unit"), value: "collaborator_unit_work" },
].sort((a, b) =>
  a.label.toString().toLowerCase() > b.label.toString().toLowerCase()
    ? 1
    : -1
);

export const validationSchema = yup.object({
  solicitation_type: yup.string().nullable().required("Campo é obrigatório"),
  name: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_types: yup
    .array()
    .when("solicitation_type", (solicitation_type: any, schema: any) => {
      return solicitation_type[0] === "dismissal"
        ? schema
            .min(1, "Selecione ao menos 1 tipo.")
            .nullable()
            .required("Campo é obrigatório")
        : schema;
    }),
  content: yup
    .string()
    .nullable()
    .min(100, "Minimo de 100 caracteres")
    .required("Documento é obrigatório"),
});

export const config = (editor: any) => ({
  readonly: false,
  placeholder: "EX: Nome do colaborador: {{collaborator_name}}",
  language: "pt_br",
  minHeight: 500,
  uploader: {
    insertImageAsBase64URI: true,
  },
  events: {
    paste: (e: any) => {
      const newContent = e.target.innerHTML.replace(/(^|;)\s*font-[^;]+/g, "");
      editor.current.value = newContent;
      return e;
    },
  },
});
