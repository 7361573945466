/** @format */

import { Save, Undo } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { updateCompanySettings} from "../../../../redux/reducers/user";
import { GetInstance, PutInstante } from "../../../../services/api";
import { validationSchema, fieldsOptions } from "./helpers/constants";

export function PermissionsPromote() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      audit_retain_days: "",
      fields: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          promotion_requisitions_rules: {
            fields: values.fields
          },
          promotions_min_percent: values.promotions_min_percent,
          promotions_max_percent: values.promotions_max_percent,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateCompanySettings(response.data.data.record));
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          promotions_max_percent:
            response.data.data.record.promotions_max_percent,
          promotions_min_percent:
            response.data.data.record.promotions_min_percent,
          fields: response.data.data.record.promotion_requisitions_rules.fields
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (value: any) => {
    const fields = formik.values.fields || [];
    const isChecked = fields.includes(value);

    formik.setFieldValue(
      "fields",
      isChecked
        ? fields.filter((field: any) => field !== value)
        : [...fields, value]
    );
  };

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/promote" },
            { label: "Promoção" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta tela é permitido a configuração de minimo e máximo do percentual de reajuste salarial.",
          ]}
        />

        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações de promoção
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <InputTextField
              label='Minimo de reajuste(%)'
              id='promotions_min_percent'
              formik={formik}
              type='number'
            />
            <InputTextField
              label='Máximo de reajuste (%)'
              id={`promotions_max_percent`}
              formik={formik}
              type='number'
            />
          </Grid>
          <Grid m={2}>
            <Typography fontSize={14} fontWeight={500}>
              Campos adicionais no formulário:
            </Typography>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"}>
              {fieldsOptions.map(({ label, value }: any) =>
                <FormControlLabel
                  label={label}
                  key={value}
                  slotProps={{
                    typography: {
                      color: formik.errors.name ? "error" : "inherit",
                      fontSize: 13,
                      fontWeight: 400,
                    },
                  }}
                  control={
                    <Checkbox
                      id={value}
                      checked={formik.values?.fields?.includes(value)}
                      onChange={() => handleFieldChange(value)}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
