/** @format */

import { CheckCircle, Edit } from "@mui/icons-material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { ButtonComponent } from "../../../../../../components/buttonComponent";

interface Props {
  open?: any;
  setOpen?: any;
  action?: any;
  interval?: number;
}

export function ActionAdmissionInterval({ action, open, setOpen, interval }: Props) {
  return (
    <AlertDialog.Root open={open} onOpenChange={setOpen}>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Description size='2'>
          A data de admissão não cumpre o prazo mínimo de {interval} dias. Deseja prosseguir mesmo assim?"
        </AlertDialog.Description>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel>
            <ButtonComponent
              variant={"outlined"}
              color='error'
              endIcon={<Edit />}>
              Ajustar
            </ButtonComponent>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonComponent
              color='success'
              variant={"contained"}
              onClick={action}
              endIcon={<CheckCircle />}>
              Confirmar
            </ButtonComponent>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
