/** @format */

import dayjs from "dayjs";
import { ClearString } from "../../../../../helpers/clearString";
import { FormatCPF } from "../../../../../helpers/formatCPF";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import { FormatPhone } from "../../../../../helpers/formatPhone";
import { type_of_disability } from "../../../../../config/constants";

interface Props {
  id: any;
  formik: any;
  setData: any;
  setIndex: any;
  dispatch: any;
  setAvatar: any;
  solicitation: any;
}

export async function GetData({
  id,
  formik,
  setData,
  setIndex,
  dispatch,
  setAvatar,
  solicitation,
}: Props) {
  dispatch(updateLoading(true));
  GetInstance({ user_uuid: id }, "/users_form")
    .then(async (response: any) => {
      try {
        let payload = {} as any
        let internship_detail = {} as any;
        if (response.data.data.internship_details_data?.educational_institution_id) {
          const institution = response.data.data.options.educational_institutions.find((item: any) => item.value === response.data.data.internship_details_data.educational_institution_id);
          internship_detail = {
            postal_code: institution.postal_code,
            state: institution.state,
            city: institution.city,
            street: institution.street,
            neighborhood: institution.neighborhood,
            number: institution.number,
            complement: institution.complement,
            institution_cnpj: institution.institution_cnpj,
          }
        }
        
        let dataNotFormat = {
          ...response.data.data,
          ...response.data.data.record_data,
          ...response.data.data.address_data,
          ...response.data.data.internship_details_data,
        };
        if (solicitation) {
          const dataSolicitation = await GetInstance(
            {},
            "/user_update_requests/" + solicitation
          );
          payload = dataSolicitation?.data?.data?.record?.payload;
          dataNotFormat = {
            ...dataNotFormat,
            ...dataSolicitation?.data?.data?.record?.payload,
          };
        }
        if (dataNotFormat.address_data && dataNotFormat.address_data.country) {
          delete dataNotFormat?.address_data?.country;
        }
        const dataFormat = {
          ...dataNotFormat,
          city_of_birth: ClearString(
            dataNotFormat.record_data?.city_of_birth ?? ""
          ).toLocaleUpperCase(),
          internship_detail,
          institution_cnpj: internship_detail.institution_cnpj,
          dependents: payload?.dependents ??dataNotFormat.dependant_data,
          user_stabilities: payload?.user_stabilities ??dataNotFormat.stability_data,
          emergency_contacts: payload?.emergency_contacts ?? dataNotFormat.emergency_contact_data,
          fields_with_required_documents: payload?.fields_with_required_documents ??
            dataNotFormat.fields_with_required_documents,
          position_code: dataNotFormat.user_data.position_code,
          position_type: dataNotFormat.user_data.position_type,
          phone: FormatPhone(payload?.phone ??dataNotFormat.record_data.phone),
          admission_type: payload?.admission_type ??
            dataNotFormat.record_data?.admission_type?.toString() ?? null,
          reintegration_type: payload?.reintegration_type ??
            dataNotFormat.record_data?.reintegration_type?.toString() ?? null,
          onus_type: payload?.onus_type ??dataNotFormat.record_data?.onus_type?.toString() ?? null,
        };
        if (dataNotFormat.record_data?.type_of_disability) {
          dataFormat.type_of_disability = type_of_disability.filter(
            (item: any) =>
              dataNotFormat.record_data.type_of_disability.includes(item.value)
          );
        }
        if (solicitation && dataNotFormat?.type_of_disability) {
          dataFormat.type_of_disability = type_of_disability.filter(
            (item: any) =>
              dataNotFormat?.type_of_disability.includes(
                item.value
              )
          );
        }
        if (dataNotFormat.user_data.position_type === "pj") {
          dataFormat.business_unit_contract_type = "999";
        } else if (dataNotFormat.user_data.position_type === "aprendiz") {
          dataFormat.business_unit_contract_type = "997";
        } else if (dataNotFormat.user_data.position_type === "estagiario") {
          dataFormat.business_unit_contract_type = "998";
        }
        // eslint-disable-next-line array-callback-return
        Object.entries(dataFormat).map((opt: any) => {
          if (opt[0].indexOf("date") !== -1 && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_employment_bond" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_exp_extension" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "contribution_starting_from" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_contract_finish" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "cpf") {
            dataFormat[opt[0]] = FormatCPF(opt[1]);
          }
        });
        // eslint-disable-next-line array-callback-return
        dataFormat.dependents.map((item: any) => {
          item.birth_date = dayjs(item.birth_date);
          item.cpf = FormatCPF(item.cpf);
        });
        formik.setValues({ ...dataFormat, falta: null, pensions:[] });
        setData({
          ...response.data.data,
          payload,
          options: {
            ...response.data.data.options,
          },
        });
        setIndex(1);
        setAvatar(response.data.data.avatar);
        dispatch(updateLoading(false));
      } catch (e) {
        GenericError(e, dispatch);
      }
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
}
