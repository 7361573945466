/** @format */

import { AddCircleOutline, Edit } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ButtonComponent } from "../../../components/buttonComponent";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";
import {  MaskCurrency } from "../../../helpers/formatCurrency";

let timerSearch: any;

export default function useTableSalary(callback: any) {
  const [data, setData]: any = useState([]);
  const [columns, setColumns]: any = useState([]);
  const [items, setItems]: any = useState([]);
  const [options, setOptions]: any = useState({
    grades: [],
    grade_columns: [],
    grade_items: [],
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const dispatch = useDispatch();

  const formatTable = () => {
    const table: any = [];
    options.grades.forEach((grade: any) => {
      let row: any = {};
      options.grade_columns.forEach((column: any, index: number) => {
        
        row["grade"] = grade.description;
        const item = items.find(
          (item: any) =>
            item.grade_id === grade.id && item.grade_column_id === column.id
        );
        if (item) {
          row["grade" + index] = (
            <ButtonComponent
              variant="text"
              onClick={() => callback({ ...item, item_value: MaskCurrency(Number(item.item_value) /100) })}
              endIcon={<Edit />}
            >
              {MaskCurrency(Number(item.item_value) /100)}
            </ButtonComponent>
          );
        } else {
          row["grade" + index] = (
            <ButtonComponent
              variant="text"
              onClick={() =>
                callback({
                  item_value: null,
                  grade_id: grade.id,
                  grade_column_id: column.id,
                })
              }
              endIcon={<AddCircleOutline />}
            >
              Não possui
            </ButtonComponent>
          );
        }
      });
      table.push(row);
    });
    setData(table);
  };

  const getCall = useCallback(async () => {
    try {
      dispatch(updateLoading(true));
      const { data } = await GetInstance(
        { page: page + 1, per_page: perPage, search, order_by: order },
        "/grade_table"
      );
      setItems(data?.data?.grade_items);
      setOptions({
        grades: data?.data?.grades.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.description,
        })),
        grade_columns: data?.data?.grade_columns.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.description,
        })),
        grade_items: data?.data?.grade_items.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.description,
        })),
      });
      formatTable();
      setColumns([
        { name: "grade", label: "Nível Funcional" },
        ...data.data.grade_columns.map((item: any, index: number) => ({
          ...item,
          label: item.description,
          name: "grade" + index.toString(),
        })),
      ]);
      setTotal(data.data.grades.length);
      setPerPage(data.data.grades.length);
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order]);

  //para atualizar a chamada
  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order]);

  useEffect(() => {
    formatTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    columns,
    options,
    items,
    setItems,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    order,
    searchCall,
  };
}
