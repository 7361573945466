/** @format */

import { Download, Edit, Undo } from "@mui/icons-material";
import {
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { fields } from "../../../../../config/fields";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import { formatField } from "../helpers/formatField";
import { HistoryComponent } from "../helpers/historyComponent";
import { formatDataDetails, formats } from "../helpers/constants";

export function UpdateInformationDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const router = useNavigate();
  const theme = useTheme();
  const [options, setOptions] = useState({
    departments: [],
    job_role: [],
    union: [],
    health_insurance: [],
    food_voucher: [],
    business_unit: [],
    sector: [],
    work_section: [],
    cost_center: [],
    managerial_cost_center: [],
    work_category: [],
    work_schedule: [],
    salary_payment_method: [],
    managers: [],
  });
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: { files: [] },
    record: { payload: {} },
  });

  const user = useSelector((state: any) => state.user.data);
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/user_update_requests/" + id)
      .then(async (response: any) => {
        let formData: any = response.data.data;
        formData.record.payload = formatDataDetails(formData?.record?.payload);
        if (formData?.solicitation_data?.solicitation_histories) {
          formData.solicitation_data.solicitation_histories =
            formData.solicitation_data.solicitation_histories.map(
              (item: any) => {
                item.new_data.payload = formatDataDetails(
                  item.new_data.payload
                );
                return item;
              }
            );
        }
        setData(formData);
        GetInstance({}, "/users_form")
          .then(async (response: any) => {
            setOptions(response.data.data.options);
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/updateInformation" },
            {
              label: "Alteração cadastral",
              value: "/request/updateInformation",
            },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          solicitation={data.solicitation_data}
          user={data.collaborator_data}
        />
        <Grid container gap={2}>
          <Paper style={{ padding: 16, width: "100%" }}>
            <Typography fontSize={16} fontWeight={800}>
              Dados gerais solicitados para alteração
            </Typography>
            <Grid container gap={"12px 96px"} mt={2}>
              {Object.entries(data.record.payload).map(([key, value]: any) => {
                const formatKeys = formats.map((item: any) => item.key);
                return (
                  formatKeys.indexOf(key) === -1 && (
                    <Grid>
                      <Typography fontSize={14} fontWeight={500}>
                        {fields.filter((item: any) => key === item.value)[0]
                          ?.label ?? "Campo não identificado"}
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        {formatField(key, value, options)}
                      </Typography>
                    </Grid>
                  )
                );
              })}
              {Object.entries(data.record.payload).map(([key, value]: any) => {
                const indexFormat = formats
                  .map((item: any) => item.key)
                  .indexOf(key);
                return (
                  indexFormat !== -1 && (
                    <Grid container flexDirection={"column"}>
                      <Divider />
                      <Typography fontSize={16} fontWeight={800} mt={2}>
                        {formats[indexFormat].title}
                      </Typography>
                      {value.map((dep: any, index: any) => (
                        <Grid container gap={1} flexDirection={"column"}>
                          <Typography fontSize={14} fontWeight={500} mt={2}>
                            {formats[indexFormat].label} {index + 1}:
                          </Typography>
                          <Grid container gap={4}>
                            {Object.entries(dep).map(([key2, value2]: any) => (
                              <Grid>
                                <Typography fontSize={14} fontWeight={500}>
                                  {fields.filter(
                                    (item: any) => key2 === item.value
                                  )[0]?.label ?? "Campo não identificado"}
                                </Typography>
                                <Typography fontSize={12} fontWeight={400}>
                                  {formatField(key2, value2, options)}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                          {index + 1 < value.length && <Divider />}
                        </Grid>
                      ))}
                    </Grid>
                  )
                );
              })}
            </Grid>
            <Grid mt={2} />
            <Divider />
            <Grid container mt={2}>
              <Typography fontSize={16} fontWeight={800}>
                Documentos anexados para comprovações
              </Typography>
            </Grid>
            <Grid container mt={2} gap={2}>
              {data.solicitation_data.files.map((file: any, index: number) => (
                <Tooltip title={"Baixar arquivo"}>
                  <a
                    title={file.name}
                    href={file.url}
                    target="_blank"
                    style={{
                      border: theme.palette.primary.main + " 1px solid",
                      color: theme.palette.primary.main,
                      padding: 8,
                      fontSize: 12,
                      borderRadius: 24,
                      fontWeight: 800,
                      textDecorationLine: "none",
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                    }}
                    download={file.name}
                    rel="noreferrer"
                  >
                    <Download fontSize={"small"} />
                    {file.name}
                  </a>
                </Tooltip>
              ))}
              {data.solicitation_data.files.length === 0 && (
                <Typography>Não existem arquivos anexados</Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"user_update_requests"}
                  hasObservation
                  onSubmit={(resendActionCallback: any) => {
                    resendActionCallback();
                  }}
                />
              )}
              {/* {isResendable && (
                <ButtonComponent
                  variant={"contained"}
                  onClick={() =>
                    router(
                      "/request/updateInformation/request/" +
                        data.collaborator_data.uuid +
                        "?solicitation=" +
                        id
                    )
                  }
                  endIcon={<Edit />}
                >
                  Editar
                </ButtonComponent>
              )} */}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}
              >
                {data?.rules?.approve && (
                  <>
                    <ActionReturned id={id} url={"user_update_requests"} />
                    <ActionDisapproved id={id} url={"user_update_requests"} />
                  </>
                )}
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}
              >
                {data?.rules?.approve && (
                  <ActionAproved id={id} url={"user_update_requests"} />
                )}
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_data.status}
              >
                {data?.rules?.approve && (
                  <ActionResend
                    id={id}
                    url={"user_update_requests"}
                    hasObservation
                    onSubmit={(resendActionCallback: any) => {
                      resendActionCallback();
                    }}
                  />
                )}
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}
              >
                <ActionCompleted id={id} url={"user_update_requests"} />
                {data?.rules?.approve && (
                  <ActionAccountingDisapproved
                    id={id}
                    url={"user_update_requests"}
                  />
                )}
              </StatusPermittedByRoleContainer>
            </Grid>
            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={options}
            />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
