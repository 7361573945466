/** @format */

import { EmptyData } from "../helpers/emptyData";
import { FormatStatus } from "../helpers/formatStatus";
import { GradeItemPositionPercentage } from "../helpers/gradeitemPositionPercentage";
import { ClearCurrencyInt } from "../helpers/formatCurrency";

export interface UserProps {
  position_name: string;
  remuneration_value: any;
  name: string;
  job_role_code: string;
  job_role_name: string;
  status: string;
  grade_items?: any;
}
export const userInfo = (user: UserProps, infos: string[]) => {
  return allInfos(user).filter((info :any) => infos.includes(info.key))
};

const allInfos = (user: UserProps) => [
  {
    key: 'name',
    title: "Nome",
    value: user.name,
  },
  {
    key: 'job_role_code',
    title: "Cód. cargo",
    value: EmptyData(user.job_role_code),
  },
  {
    key: 'job_role_name',
    title: "Cargo",
    value: EmptyData(user.job_role_name),
  },
  {
    key: 'position_name',
    title: "Posição",
    value: EmptyData(user.position_name),
  },
  {
    key: 'remuneration_value',
    title: "Salário atual",
    value: EmptyData(user.remuneration_value),
  },
  {
    key: 'grade_item_position_percentage',
    title: "Posicionamento da faixa",
    value: GradeItemPositionPercentage(user?.grade_items, Number(ClearCurrencyInt(`${user.remuneration_value}`))),
  },
  {
    key: 'status',
    title: "Status",
    value: FormatStatus(user.status),
  },
];
