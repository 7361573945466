/** @format */

import { Grid, Typography, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GetInstance } from "../../../../../services/api";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PersonalForExistingPositionDetails } from '../helpers/existingPosition/details';
import { PersonalForNewPositionDetails } from '../helpers/newPosition/details';

export function PersonalDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({});

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const responseData = response.data.data;
        setData(responseData);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Requisições", value: "/request/personal" },
          { label: "Pessoal", value: "/request/personal" },
          { label: "Nova solicitação" },
        ]}
      />

      <Grid width="inherit">
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Detalhes da requisição de pessoal
            </Typography>
          </Grid>

          {data?.record && (
            data.record.has_new_position ? (
              <PersonalForNewPositionDetails data={data} />
            ) : (
              <PersonalForExistingPositionDetails data={data} />
            )
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
