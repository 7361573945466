import * as yup from "yup";
export const columns = [
  { name: "action", label: "Ações" },
  { name: "process", label: "Carregamento" },
  { name: "status", label: "Status" },
  { name: "id", label: "Importação ID" },
  { name: "document_type", label: "Tipo do documento" },
  { name: "period", label: "Periodo"  },  
];


export const columns2 = [
  { name: "document_type", label: "Tipo do documento" },
  { name: "period", label: "Periodo" },
];

export const columnsError = [
  { name: "action", label: "Ações" },
  { name: "name", label: "Nome do documento" },
  { name: "imported_file_id", label: "Importação ID" },
];

export const types = [
  { value: "1", label: "Por tipo de documento" },
  { value: "2", label: "Em lote" },
];

export const time_type = [
  { value: "year", label: "Por ano" },
  { value: "month", label: "Por mês" },
];

export const validationSchema = yup.object({
  document_type: yup.string().nullable().required("Campo é obrigatório"),
  time_type: yup.string().nullable().required("Campo é obrigatório"),
  files: yup.array().min(1,'Adicione 1 documento.').nullable().required("Campo é obrigatório"),
});

export const validationSchemaView = yup.object({
  document_type: yup.string().nullable().required("Campo é obrigatório"),
  times: yup.array().min(1,'Adicione 1 periodo.').nullable().required("Campo é obrigatório"),
});
