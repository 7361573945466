/** @format */

import * as yup from "yup";

export const validationSchema = yup.object().shape({
  promotions_min_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 0%")
    .max(99, "Máximo de 99%"),
  promotions_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 1%")
    .max(99, "Máximo de 100%"),
  fields: yup.array()
});

export const fieldsOptions = [
  {
      label: "Exame médico ?",
      value: "has_medical_examination"
  },
  {
      label: "Mudança de benefícios ?",
      value: "has_benefits_change"
  },
  {
      label: "Mudança de sindicato ?",
      value: "has_union_change"
  },
  {
      label: "Mudança na jornada de trabalho ?",
      value: "has_work_schedule_change"
  }
]