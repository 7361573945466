/** @format */

import { Divider, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { aviso_e_social } from "../../../../../config/constants";
import { SelectField } from "../../../../../components/inputs/selectField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";

export function Form({ formik, user, edit }: any) {
  const userData: any = useSelector((state: any) => state?.user?.data);
  return formik.values.vacation_items.map((item: any, index: number) => (
    <>
      <Divider style={{ marginTop: 16 }} />

      <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} mt={2} gap={2}>
        <InputTextField
          formik={formik}
          disabled={edit}
          type='number'
          id={`vacation_items[${index}].days_quantity`}
          label='Quantidade'
          onChange={(event: any) => {
            formik.setFieldValue(
              `vacation_items[${index}].days_quantity`,
              event.target.value
            );
            formik.setFieldValue(
              `vacation_items[${index}].abono_pecuniario`,
              false
            );
          }}
          onBlur={() => {
            const newValue =
              formik.values.vacation_items[index]?.days_quantity.length > 0
                ? Number(formik.values.vacation_items[index]?.days_quantity)
                : 0;

            const allDaysArray = formik.values.vacation_items.map((item: any) =>
              Number(item?.days_quantity)
            );
            const allDay = allDaysArray.reduce(
              (acumulador: number, valorAtual: number) => {
                return Number(acumulador) + Number(valorAtual);
              },
              0
            );
            formik.setFieldValue(
              "days_available",
              Number(user.days_available) - Number(allDay)
            );
            formik.setFieldValue(
              "days_used",
              Number(user.days_used) + Number(allDay)
            );
            if (newValue === 0) {
              formik.setFieldValue(`vacation_items[${index}].end_date`, null);
              return;
            }
            if (formik.values.vacation_items[index]?.start_date) {
              const dateInit = new Date(
                formik.values.vacation_items[index]?.start_date
              );
              dateInit.setUTCDate(dateInit.getUTCDate() + (newValue - 1));
              formik.setFieldValue(
                `vacation_items[${index}].end_date`,
                dayjs(dateInit)
              );
              const dataPayment = new Date(
                formik.values.vacation_items[index]?.start_date
              );
              dataPayment.setUTCDate(dataPayment.getUTCDate() - 2);
              formik.setFieldValue(
                `vacation_items[${index}].payment_date`,
                dayjs(dataPayment)
              );
            }
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `vacation_items[${index}].start_date`,
              onBlur: formik.handleBlur,
              error:
                formik.errors.vacation_items &&
                Boolean(formik.errors.vacation_items[index]?.start_date),
              helperText:
                formik.errors.vacation_items &&
                formik.errors.vacation_items[index]?.start_date,
            },
          }}
          disabled={edit}
          label='Data de início'
          value={formik.values.vacation_items[index]?.start_date}
          onChange={(value: any) => {
            formik.setFieldValue(`vacation_items[${index}].start_date`, value);
            const dataPayment = new Date(value);
            dataPayment.setUTCDate(dataPayment.getUTCDate() - 2);
            formik.setFieldValue(
              `vacation_items[${index}].payment_date`,
              dayjs(dataPayment)
            );
            if (formik.values.vacation_items[index]?.days_quantity) {
              const dateInit = new Date(value);
              dateInit.setUTCDate(
                dateInit.getUTCDate() +
                Number(formik.values.vacation_items[index]?.days_quantity - 1)
              );
              formik.setFieldValue(
                `vacation_items[${index}].end_date`,
                dayjs(dateInit)
              );
            }
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `vacation_items[${index}].end_date`,
              error:
                formik.errors.vacation_items &&
                Boolean(formik.errors.vacation_items[index]?.end_date),
              helperText:
                formik.errors.vacation_items &&
                formik.errors.vacation_items[index]?.end_date,
            },
          }}
          disabled
          label='Data de término'
          value={formik.values.vacation_items[index].end_date}
        />
        <SelectField
          formik={formik}
          disabled={edit}
          id={`vacation_items[${index}].thirteen_anticipation`}
          label='Antecipação do 13°'
          options={aviso_e_social}
        />
        <SelectField
          formik={formik}
          disabled={edit}
          id={`vacation_items[${index}].abono_pecuniario`}
          label='Abono pecuniário'
          options={aviso_e_social}
          onChange={(event: any) => {
            formik.setFieldValue(
              `vacation_items[${index}].abono_pecuniario`,
              event.target.value
            );
            if (event.target.value) {
              const abono =
                user.vacations_custom_days_abono ??
                Math.floor(user.days_available / 3);
              formik.setFieldValue("abono_quantity", abono);
              formik.setFieldValue(
                "days_available",
                Number(formik.values.days_available) - Number(abono)
              );
              formik.setFieldValue(
                "days_used",
                Number(formik.values.days_used) + Number(abono)
              );
            } else {
              const abono =
                user.vacations_custom_days_abono ??
                Math.floor(user.days_available / 3);
              formik.setFieldValue("abono_quantity", abono);
              formik.setFieldValue(
                "days_available",
                Number(formik.values.days_available) + Number(abono)
              );
              formik.setFieldValue(
                "days_used",
                Number(formik.values.days_used) - Number(abono)
              );
            }
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: `vacation_items[${index}].payment_date`,
              onBlur: formik.handleBlur,
              error:
                formik.errors.vacation_items &&
                Boolean(formik.errors.vacation_items[index].payment_date),
              helperText:
                formik.errors.vacation_items &&
                formik.errors.vacation_items[index].payment_date,
            },
          }}
          disabled={
            !(userData?.role === "rh") || edit
          }
          label='Data de pagamento'
          value={formik.values.vacation_items[index].payment_date}
          onChange={(value: any) => {
            formik.setFieldValue(
              `vacation_items[${index}].payment_date`,
              value
            );
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} mt={2} gap={2}>

        {userData.role === "rh" && user.vacations_rules?.use_assiduity_allowance && !user.has_assiduity && (
          <SelectField
            formik={formik}
            id={`vacation_items[${index}].assiduity_allowance`}
            label='Abono de assiduidade'
            options={aviso_e_social}
          />
        )}

      </Grid >
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <div>
          <InputTextField
            formik={formik}
            multiline={true}
            disabled={edit}
            rows={4}
            id={`vacation_items[${index}].description`}
            label='Informações sobre as férias'
          />
        </div>
      </Grid>
      {/* <Divider style={{ marginTop: 16 }} /> */}
    </>
  ));
}
