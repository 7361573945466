export const getSalaryRangeByJobRole = (jobRoleId: any, options: Array<any>) => {
    const jobRole = options?.find((item: any) => item.value === jobRoleId);
    const activeItems = jobRole?.grade_items?.filter((gradeItem: any) => gradeItem?.grade_column?.status === "active")
    if (activeItems?.length > 0) {
      const { minSalary, maxSalary } = activeItems.reduce(
        (acc: any, currentItem: any) => {
          const currentSalary = Number(currentItem.value);

          return ({
            minSalary: Math.min(acc.minSalary, currentSalary),
            maxSalary: Math.max(acc.maxSalary, currentSalary),
          })
        },
        {
          minSalary: Number(activeItems[0].value),
          maxSalary: Number(activeItems[0].value),
        }
      )
      return { minimum: minSalary, maximum: maxSalary }
    };
    return {};
  }
