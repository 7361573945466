/** @format */

import { Filter } from "../../../../components/filter";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";
import { AutoCompleteFieldMultiple } from "../../../../components/inputs/autoCompleteFieldMultiple";
import { filesAccount } from "../../../../config/constants";
import { GetTimes, GetYears } from "../../../../helpers/getYears";

export const Filters = ({ formik, data, calendar,setTypeCalendar }: any) => {
  const options: any = calendar === "year" ? GetYears() : GetTimes()
  const documentOptions = filesAccount.filter(
    (option: any) => data.options.document_types.indexOf(option.value) !== -1
  );
  return (
    <Filter
      formik={formik}
      buttonLabel={"Buscar"}
      id={"view_document"}
      keys={{
        times: {
          label: "Períodos",
          options: options,
        },
        document_type: {
          label: "Tipo de documento",
          options: documentOptions,
        },
       
      }}
    >
      <AutoCompleteField
        formik={formik}
        label="Tipo de documento"
        id="document_type"
        onChange={(event: any,newValue:any) => {
          setTypeCalendar(newValue?.calendar)
        }}
        options={documentOptions}
      />
      <AutoCompleteFieldMultiple
        formik={formik}
        disabled={calendar === null}
        label="Períodos"
        limitTags={6}
        id="times"
        options={options}
      />
    </Filter>
  );
};
