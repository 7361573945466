/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { HiddenCheckChildren } from "../../../../../helpers/hiddenCheck";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export function Stabilities({ formik, data }: any) {
  function Add() {
    const newArray = formik.values.user_stabilities;
    newArray.push({
      stability_type_id: null,
      start_date: null,
    });
    formik.setFieldValue("user_stabilities", newArray);
  }

  function Remove(id: number) {
    const newArray = formik.values.user_stabilities;
    newArray.splice(id, 1);
    formik.setFieldValue("user_stabilities", newArray);
  }

  

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid id={"user_stabilities"}>
          <Typography fontSize={16} fontWeight={500}>
            Estabilidades
          </Typography>
        </Grid>
        {formik.values.user_stabilities.map((item: any, index: number) => (
          <div id={"user_stabilities"}>
            <Divider style={{ marginTop: 16 }} />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}>
              <Typography fontSize={14} fontWeight={400}>
                Estabilidade {index + 1}
              </Typography>
              <Tooltip title={"Remover dependente"}>
                <IconButton onClick={() => Remove(index)} color={"error"}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <SelectField
                options={[{ label: "", value: null }, ...data.options.stability_type]}
                formik={formik}
                id={`user_stabilities[${index}].stability_type_id`}
                label='Tipo de estabilidade'
              />

              <InputDateField
                id={`user_stabilities[${index}].start_date`}
                label="Data de início"
                formik={formik}
              />
              <DatePicker
                label="Fim da estabilidade"
                disabled={true}
                value={
                  formik.values?.user_stabilities[index].start_date ? dayjs(formik.values?.user_stabilities[index].start_date).add(
                    data.options.stability_type?.find(
                      (item: any) =>
                        item.value === formik.values.user_stabilities[index]?.stability_type_id,
                    )?.duration - 1 || 0,
                    'days',
                  ) : null
                }
              />
            </Grid>
            <Divider style={{ marginTop: 16 }} />
          </div>
        ))}
        <Grid mt={2}>
          <Button endIcon={<AddCircle />} onClick={Add} variant={"contained"}>
            Adicionar estabilidade
          </Button>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
