/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAproved } from "../../../../../../../components/actionApproved";
import { ActionDisapproved } from "../../../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../../../components/actionResend";
import { ActionReturned } from "../../../../../../../components/actionReturned";
import { ButtonComponent } from "../../../../../../../components/buttonComponent";
import { StatusPermittedByRoleContainer } from "../../../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../../../services/api";
import { HistoryComponent } from "../helpers/historyComponent";
import {
  position_type,
  recruitment_types,
} from "../../../../../../../config/constants";
import { validationSchema } from "../helpers/constants";
import { AutoCompleteField } from "../../../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../../../components/inputs/inputTextField";
import { LabelFormat } from "../../../../../../../helpers/labelFormat";

export function PersonalForNewPositionDetails({ data }: any) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [options, setOptions] = useState<any>({
    job_role: [],
    reason_solicitation: [],
    manager: [],
    department: [],
    business_unit: [],
    cost_center: [],
  });

  const user = useSelector((state: any) => state.user.data);
  const companySettings = useSelector((state: any) => state.user.companySettings);
  const requiredFields = user.role === "manager"
    ? companySettings?.personnel_requisitions_rules?.manager_fields ?? []
    : companySettings?.personnel_requisitions_rules?.rh_fields ?? [];

  const permissions = useSelector((state: any) => state.user.permissions);

  const soliciationComplete = data?.solicitation_data.status === "completed";
  const soliciationApproved = data?.solicitation_data.status === "approved";
  const isRh = user.role === "rh";
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data?.record.created_by_id;

  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPersonalDetailsEdit") !== -1 &&
      data?.solicitation_data.status === "pending") ||
    (isRh && !soliciationComplete && !soliciationApproved) ||
    isResendable;

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "",
      salary: "",
      initial_date: null,
      observations: "",
      department_id: "",
      business_unit_id: "",
      cost_center_id: "",
      manager_id: "",
      reason_solicitation_id: "",
      recruitment_type: "",
      position_type: "",
      name: "",
      quantity: "",
    },
    validationSchema: validationSchema({ userRole: user.role, companySettings: companySettings }),
    onSubmit: (values) => {
      onSubmit(values, null, true, true);
    },
  });

  useEffect(() => {
    const initial_date = dayjs(data?.record.initial_date);
    formik.setValues({
      job_role_id: data?.record.job_role_id,
      salary: FormatCurrency({
        target: {
          value: `${data?.record.salary}` || "0",
        },
      }),
      initial_date,
      observations: data?.record.observations,
      recruitment_type: data?.record.recruitment_type,
      department_id: data?.new_position?.department_id,
      business_unit_id: data?.new_position?.business_unit_id,
      cost_center_id: data?.new_position?.cost_center_id,
      manager_id: data?.new_position?.manager_id,
      reason_solicitation_id: data?.new_position?.reason_solicitation_id,
      position_type: `${data?.new_position?.position_type}`,
      name: data?.new_position?.name,
      quantity: data?.new_position?.quantity,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ has_new_position: true }, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          ...response.data.data.options,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/reason_solicitations")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          reason_solicitation: response.data,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any, callback: any, hasToast: boolean = false, historyBack: boolean = false) => {
    dispatch(updateLoading(true));
    PutInstante(
      id,
      {
        ...values,
        salary: ClearCurrency(values.salary),
        position_type: Number(values.position_type),
        has_new_position: true,
      },
      "/personnel_requisitions"
    )
      .then((response: any) => {
        if (callback) callback();
        if (historyBack) window.history.back();
        if (hasToast) toast.success(response?.data?.message);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid width={"inherit"}>
        <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} gap={2}>
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="recruitment_type"
            label="Tipo de recrutamento"
            options={recruitment_types}
          />
          <AutoCompleteField
            formik={formik}
            id="manager_id"
            label="Gestor"
            disabled={!edit || user.role === "manager"}
            options={options?.manager?.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="department_id"
            label={LabelFormat("department")}
            options={options?.department?.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.name}`,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="cost_center_id"
            label="Centro de custo"
            options={options?.cost_center?.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.description}`,
            }))}
          />
          <InputTextField
            formik={formik}
            id="name"
            label="Função"
            disabled={!edit}
          />
          <InputTextField
            type="number"
            formik={formik}
            label={"Quantidade"}
            id={"quantity"}
            disabled={!edit}
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="business_unit_id"
            label={LabelFormat("business_unit")}
            options={options?.business_unit?.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.name}`,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="job_role_id"
            label="Cargo"
            options={options?.job_role?.map((jobRole: any) => ({
              label: `${jobRole.value} - ${jobRole.label}`,
              value: jobRole.value,
            }))}
          />

          <InputCurrencyField
            disabled={!edit && (user.role !== 'manager' || requiredFields.includes("salary"))}
            formik={formik}
            id="salary"
            label="Remuneração"
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="reason_solicitation_id"
            label="Motivo da solicitação"
            options={options?.reason_solicitation?.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="position_type"
            label="Tipo de posição"
            options={position_type}
          />
          {requiredFields.includes("initial_date") && (
            <InputDateField
              disabled={!edit}
              formik={formik}
              id="initial_date"
              label="Data prevista de início"
            />

          )}
        </Grid>
        <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
          <InputTextField
            disabled={!edit}
            formik={formik}
            id="observations"
            label="Responsabilidades"
            multiline={true}
            rows={4}
          />
        </Grid>
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>

          {isResendable && (
            <ActionResend
              id={id}
              url={"personnel_requisitions"}
              disabled={!formik.isValid}
              onSubmit={(callback: any) => onSubmit(formik.values, callback)}
            />
          )}

          {edit && (
            <ButtonComponent
              variant={"contained"}
              type={"submit"}
              endIcon={<Edit />}
            >
              Editar
            </ButtonComponent>
          )}

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={[
              "pending",
              "waiting_manager_user",
              "waiting_rh_user",
              "accounting_return",
            ]}
            managerAllowedStatuses={["pending", "waiting_manager_user"]}
            status={data?.solicitation_data.status}
          >
            <ActionReturned id={id} url={"personnel_requisitions"} />
            <ActionDisapproved id={id} url={"personnel_requisitions"} />
          </StatusPermittedByRoleContainer>

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={[
              "pending",
              "waiting_manager_user",
              "waiting_rh_user",
            ]}
            managerAllowedStatuses={["pending", "waiting_manager_user"]}
            status={data?.solicitation_data.status}
          >
            <ActionAproved id={id} url={"personnel_requisitions"} beforeAction={(callback) => onSubmit(formik.values, callback)}/>
          </StatusPermittedByRoleContainer>

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={["accounting_return"]}
            status={data?.solicitation_data.status}
          >
            <ActionResend
              id={id}
              url={"personnel_requisitions"}
              hasObservation
              disabled={!formik.isValid}
              onSubmit={(callback: any) => onSubmit(formik.values, callback)}
            />
          </StatusPermittedByRoleContainer>
        </Grid>

        <HistoryComponent
          data={data?.solicitation_data?.solicitation_histories}
          options={options}
        />
      </Grid>
    </form>
  );
}
