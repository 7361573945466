import * as yup from "yup";

export const validationSchema = yup.object().shape({
    transfer_with_promotion_min_percent: yup
        .number()
        .required("Campo obrigatório"),
    transfer_with_promotion_max_percent: yup
        .number()
        .required("Campo obrigatório")
        .when(
            "transfer_with_promotion_min_percent",
            ([transfer_with_promotion_min_percent], schema) =>
                schema.min(
                    transfer_with_promotion_min_percent,
                    "O percentual máximo deve ser maior ou igual ao percentual mínimo"
                )
        ),
    transfer_without_promotion_min_percent: yup
        .number()
        .required("Campo obrigatório"),
    transfer_without_promotion_max_percent: yup
        .number()
        .required("Campo obrigatório")
        .when(
            "transfer_without_promotion_min_percent",
            ([transfer_without_promotion_min_percent], schema) =>
                schema.min(
                    transfer_without_promotion_min_percent,
                    "O percentual máximo deve ser maior ou igual ao percentual mínimo"
                )
        ),
    fields: yup.array()
});


export const fieldsOptions = [
    {
        label: "Exame médico ?",
        value: "has_medical_examination"
    },
    {
        label: "Mudança de benefícios ?",
        value: "has_benefits_change"
    },
    {
        label: "Mudança de sindicato ?",
        value: "has_union_change"
    },
    {
        label: "Mudança na jornada de trabalho ?",
        value: "has_work_schedule_change"
    }
]