/** @format */

import { LabelFormat } from "../../../../../helpers/labelFormat";

export const columns: any = [
  { name: "code", label: "N. da Posição" },
  { name: "manager_name_formatted", label: "Gestor" },
  { name: "job_role_name", label: "Cargo" },
  { name: "collaborator_name_formatted", label: "Colaborador" },
  { name: "cost_center_name", label: "Centro de custo" },
  { name: "department_name", label: LabelFormat("department") },
  { name: "business_unit_name", label: "Un. de negócio" },
  { name: "status", label: "Status" },
];
