/** @format */

import { Filter } from "../../../../../components/filter";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { LabelFormat } from "../../../../../helpers/labelFormat";

export const Filters = ({ formik, options }: any) => {
  return (
    <Filter
      formik={formik}
      id={"users_active"}
      keys={{
        job_role_id: { label: "Cargo", options: options?.job_roles ?? [] },
        manager_id: { label: "Gestor", options: options?.managers ?? [] },
        department_id: {
          label: LabelFormat("department"),
          options: options?.departments ?? [],
        },
      }}>
      <AutoCompleteField
        formik={formik}
        label='Cargo'
        id='job_role_id'
        options={options?.job_roles?.map((jobRole: any) => ({
          label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}`: jobRole.label,
          value: jobRole.value,
        }))}
      />
      <AutoCompleteField
        formik={formik}
        label='Gestor'
        id='manager_id'
        options={options?.managers}
      />
      <AutoCompleteField
        formik={formik}
        label={LabelFormat("department")}
        id='department_id'
        options={options?.departments}
      />
    </Filter>
  );
};
