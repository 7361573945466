export const FormatCurrency = (event: any) => {
	const onlyDigits = event.target.value
		.split('')
		.filter((s: any) => /\d/.test(s))
		.join('')
		.padStart(3, '0');
	const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
	event.target.value = MaskCurrency(digitsFloat);
	return event.target.value;
};

export const MaskCurrency = (valor: any, locale = 'pt-BR', currency = 'BRL') => {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency
	}).format(valor);
};

export const ClearCurrency = (value: string) => {
	return value.replace('R$', '').replace('.', '').replace(',', '.').trim();
};

export const ClearCurrencyInt = (value: string) => {
	return value.replace('R$', '').replaceAll('.', '').replaceAll(',', '').trim();
};