/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../../components/buttonComponent";
import { GenericError } from "../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../redux/reducers/common";
import { PostInstante, PutInstante } from "../../../../../../services/api";
import { ActionManager } from "./actionmanager";
import { valideFieldsChange } from "./constants";
import { ModalUploadDocument } from "./modalUploadDocument";

interface Props {
  id: any;
  url: string;
  data: any;
  urlBack: string;
  formik: any;
  initialData: any;
  solicitation: any;
}

export function ActionUploadDocument({
  id,
  url,
  data,
  urlBack,
  formik,
  initialData,
  solicitation,
}: Props) {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [dataUpload, setDataUpload] = useState<any>({});
  const [filesForm, setFileForm]: any = useState([]);
  const [hasRequiredFile, setHasRequiredFile] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalRequired, setTotalRequired] = useState(0);
  const [openManager, setOpenManager] = useState(false);

  async function Valide() {
    const { fieldsChange, dataSend } = await valideFieldsChange(
      formik,
      initialData
    );
    if (fieldsChange.length === 0) {
      toast.info(
        data?.payload !== null
          ? "Você não fez nenhuma alteração e por conta disso, não é possivel reenviar a solicitação."
          : "Você não fez nenhuma alteração e com isso, seu cadastro já está atualizado."
      );
      return;
    }
    if (Object.keys(formik.errors).length > 0) {
      toast.info(
        "Por favor revise o formulário, existe falha nos dados abaixo:"
      );
      if (Object.keys(formik.errors).indexOf("dependents") !== -1) {
        toast.error("Dependentes com dados inválidos");
      }
      if (Object.keys(formik.errors).indexOf("pensions") !== -1) {
        toast.error("Penção com dados inválidos");
      }
      if (Object.keys(formik.errors).indexOf("emergency_contacts") !== -1) {
        toast.error("Contatos de emergência com dados inválidos");
      }
      Object.values(formik?.errors ?? {}).map((item: any) => toast.error(item));
      return;
    }
    setDataUpload(dataSend);
     if (hasRequiredFile) setOpen(true);
     else onSubmit(dataSend);
  }
  async function onSubmit(dataSend: any) {
    const actionType = solicitation ? PutInstante : PostInstante;
    const actionUrl = solicitation ? `${url}/${solicitation}` : url;
    const actionPayload = solicitation ? {...data.payload, ...dataSend } : dataSend;
    dispatch(updateLoading(true));
    if (actionPayload.type_of_disability) {
      actionPayload.type_of_disability = actionPayload.type_of_disability.map(
        (item: any) => item.value
      );
    }
    actionType(null, { payload: actionPayload, target_user_uuid: id }, actionUrl)
      .then((response: any) => {
        toast.success(response?.data?.message);
        if (data.status !== "pending") {
          setTimeout(() => {
            router(urlBack);
          }, 3000);
        } else {
          window.location.reload();
        }
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  async function Files() {
    const { fieldsChange } = await valideFieldsChange(formik, initialData);
    const filesInit: any = [];
    let totalRequired = 0;
    fieldsChange
      .filter((element: any) =>
        data.fields_with_required_documents.includes(element)
      )
      // eslint-disable-next-line array-callback-return
      .map((item: any) => {
        if (item === "dependents") {
          const file: any = [];
          const names: any = [];
          //  eslint-disable-next-line array-callback-return
          data[item].map((opt: any, index: any) => {
            if (opt?.edit) {
              file.push(null);
              names.push(opt.name);
              totalRequired++;
            }
          });
          filesInit.push({ keyValue: item, file, names });
        } else if (item === "emergency_contacts") {
          const file: any = [];
          const names: any = [];
          //  eslint-disable-next-line array-callback-return
          data[item].map((opt: any, index: any) => {
            if (opt?.edit) {
              file.push(null);
              names.push(opt.name);
              totalRequired++;
            }
          });
          filesInit.push({ keyValue: item, file, names });
        } else {
          totalRequired++;
          filesInit.push({ keyValue: item, file: null });
        }
      });
    //eslint-disable-next-line array-callback-return
    fieldsChange.map((item: any) => {
      switch (item) {
        case "postal_code":
        case "street":
        case "number":
        case "complement":
        case "neighborhood":
        case "city":
        case "state":
          if (
            filesInit.filter((opt: any) => opt.keyValue === "address")
              .length === 0
          )
            filesInit.push({ keyValue: "address", file: null });
          break;
        case "bank_code":
        case "bank_account_type":
        case "bank_agency":
        case "bank_account":
          if (
            filesInit.filter((opt: any) => opt.keyValue === "bank").length === 0
          )
            filesInit.push({ keyValue: "bank", file: null });
          break;
        default:
          break;
      }
    });
    setTotalRequired(totalRequired);
    setFileForm(filesInit);
    setHasRequiredFile(data.status !== "pending" && filesInit.length > 0);
  }

  useEffect(() => {
    Files();
    if (totalRequired === 5) {
      toast.dismiss(1);
      setTimeout(() => {
        toast.warning(
          "Você atingiu o limite de campos com obrigação de anexo. Por favor envie sua solicitação e inicie outra.",
          { toastId: 1 }
        );
      }, 1000);
    }
    if (totalRequired > 5) {
      toast.dismiss(1);
      setTimeout(() => {
        toast.error(
          "Você ultrapassou o limite de campos com obrigação de anexo. Reinicie sua solicitação.",
          { toastId: 1 }
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <ActionManager
        open={openManager}
        setOpen={setOpenManager}
        action={Valide}
      />
      <ButtonComponent
        variant={"contained"}
        onClick={() => {
          if (totalRequired > 5) {
            toast.error(
              "Você ultrapassou o limite de campos com obrigação de anexo. Reinicie sua solicitação."
            );
          } else if (
            formik.values.manager_id?.length === 0 ||
            formik.values?.manager_id === null ||
            formik.values?.manager_id === undefined
          ) {
            setOpenManager(true);
          } else {
            Valide();
          }
        }}
        endIcon={<Edit />}
      >
        {solicitation
          ? "Reenviar solicitação"
          : data.status === "pending"
          ? "Atualizar cadastro"
          : "Solicitar alteração"}
      </ButtonComponent>
      <ModalUploadDocument
        filesForm={filesForm}
        setFileForm={setFileForm}
        data={dataUpload}
        open={open}
        setOpen={setOpen}
        id={id}
        url={url}
        urlBack={urlBack}
        itensRequired={totalRequired}
      />
    </>
  );
}
