/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { AlertDialog, Flex } from "@radix-ui/themes";

export function ConfirmSalaryDialog({ open, onClose, onConfirm, minSalary, maxSalary }: any) {
  return (
    <AlertDialog.Root open={open} onOpenChange={onClose}>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title>
          O salário está fora da faixas salariais
        </AlertDialog.Title>
        <AlertDialog.Description size='2'>
          {`O salário está fora da faixas salariais, deseja continuar mesmo assim ? 
                      O menor valor é ${minSalary} e o maior é ${maxSalary}`}
        </AlertDialog.Description>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel>
            <ButtonComponent
              color='error'
              variant={"outlined"}
              endIcon={<Cancel />}>
              Cancelar
            </ButtonComponent>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonComponent
              variant={"contained"}
              color='success'
              endIcon={<CheckCircle />}
              onClick={() => {
                onConfirm()
              }}>
              Confirmar
            </ButtonComponent>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
