/** @format */
import * as yup from "yup";

export const columns = [
  { name: "document_type", label: "Tipo do documento" },
  { name: "period", label: "Periodo" },
];
export const validationSchema = yup.object({
  times: yup
    .array()
    .min(1, "Adicione 1 periodo.")
    .nullable()
    .required("Campo é obrigatório"),
});
