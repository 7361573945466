/** @format */

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { getIn } from "formik";

type AutoCompleteFieldProps = {
  options: { value: string | number; label: string }[];

  label: string;
  id: string;
  onChange?: (
    event: any,
    newValue: { value: string | number; label: string }[] | null
  ) => void;
  labelcode?: boolean;
  formik: any;
  disabled?: boolean;
  limitTags?: number;
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export function AutoCompleteFieldMultiple(props: AutoCompleteFieldProps) {
  const { options, label, labelcode, formik, id, disabled, limitTags } = props;

  const optionsToUse = options === undefined || options === null ? [] : options;
  // eslint-disable-next-line array-callback-return
  optionsToUse.map((option) => {
    if (option.value === "all") {
      option.label = "Todos";
    }
  });
  const value = getIn(formik.values, id);
  const error = getIn(formik.errors, id);
  return (
    <Autocomplete
      {...props}
      size="small"
      multiple
      limitTags={limitTags ?? 2}
      options={optionsToUse}
      value={value}
      disabled={disabled}
      disableCloseOnSelect
      onChange={async (event, newValue, reason, details) => {
        const index = await value.findIndex(
          (v: any) => v.value === details?.option?.value
        );
        if (index !== -1) {
          value.splice(index, 1);
          formik.setFieldValue(id, value || []);
          if (props.onChange) props.onChange(event, value || []);
        } else if (reason === "clear") {
          formik.setFieldValue(id, []);
          if (props.onChange) props.onChange(event, []);
        } else {
          formik.setFieldValue(id, newValue || []);
          if (props.onChange) props.onChange(event, newValue || []);
        }
      }}
      noOptionsText="Nenhum resultado encontrado"
      filterOptions={(optionsToFiltter, params) => {
        const filtered = optionsToFiltter.filter((option: any) => {
          if (!params.inputValue) {
            return true;
          }
          return (
            option?.label
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase()) ||
            option?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase())
          );
        });

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (!option) return "";
        const selected = optionsToUse.find(
          ({ value }) => value === option.value
        );
        return selected
          ? labelcode
            ? `${selected.value} - ${selected.label}`
            : selected?.label ?? "Não encontrado"
          : "";
      }}
      renderOption={(props, option, opt, { value }) => {
        const { key, ...optionProps } = props;
        const selected = value?.find(({ value }) => value === option.value);
        return (
          <li key={key + option?.value?.toString()} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected !== undefined}
            />
            {labelcode ? `${option.value} - ${option.label}` : option.label}
          </li>
        );
      }}
      renderInput={(renderInputParams) => (
        <TextField
          {...renderInputParams}
          fullWidth
          placeholder="Digite aqui para buscar"
          onBlur={formik.handleBlur}
          error={Boolean(error)}
          helperText={error}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
}
