/** @format */

import * as yup from "yup";
import { ClearCPF, ClearDate } from "../../../../../../helpers/clearString";

export const validationSchema = (itensRequired: number) =>
  yup.object({
    files: yup
      .array()
      .min(itensRequired, `Precisa ter ${itensRequired} anexo`)
      .nullable()
      .required("Arquivo é obrigatório"),
  });

export async function valideFieldsChange(formik: any, initialData: any) {
  const fieldsTouched: any = Object.keys(formik.touched);
  const fieldsChange: any = await fieldsTouched.filter((item: any) => {
    if (
      item.indexOf("_from") !== -1 ||
      item.indexOf("_to") !== -1 ||
      item.indexOf("_start") !== -1 ||
      item.indexOf("_end") !== -1 ||
      item.indexOf("_at") !== -1 ||
      item.indexOf("_date") !== -1 ||
      item.indexOf("date") !== -1
    ) {
      return (
        ClearDate(formik.values[item]?.toString()) !==
        ClearDate(initialData[item]?.toString())
      );
    }
    return (
      ClearCPF(formik.values[item]?.toString()) !==
      ClearCPF(initialData[item]?.toString())
    );
  });
  if (fieldsTouched.indexOf("user_stabilities") !== -1)
    fieldsChange.push("user_stabilities");
  if (fieldsTouched.indexOf("dependents") !== -1)
    fieldsChange.push("dependents");
  if (fieldsTouched.indexOf("emergency_contacts") !== -1)
    fieldsChange.push("emergency_contacts");
  if (fieldsChange.indexOf("dependents") !== -1) {
    if (formik.values["dependents"].length === 0) {
      fieldsChange.splice(fieldsChange.indexOf("dependents"), 1);
    } else {
      formik.values["dependents"].map((item: any, index: any) => {
        const data = initialData["dependents"][index];
        if (data !== null && data !== undefined) {
          const fieldsChangeData = Object.keys(item).filter(
            (change: any) =>
              ClearCPF(data[change]?.toString()) !==
              ClearCPF(item[change]?.toString())
          );
          if (fieldsChangeData.length === 0) {
            formik.values["dependents"][index].edit = false;
          } else {
            formik.values["dependents"][index].edit = true;
          }
        }

        return null;
      });
    }
  }
  if (fieldsChange.indexOf("emergency_contacts") !== -1) {
    if (formik.values["emergency_contacts"].length === 0) {
      fieldsChange.splice(fieldsChange.indexOf("emergency_contacts"), 1);
    } else {
      formik.values["emergency_contacts"].map((item: any, index: any) => {
        const data = initialData["emergency_contacts"][index];
        if (data !== null && data !== undefined) {
          const fieldsChangeData = Object.keys(item).filter(
            (change: any) =>
              ClearCPF(data[change]?.toString()) !==
              ClearCPF(item[change]?.toString())
          );
          if (fieldsChangeData.length === 0) {
            formik.values["emergency_contacts"][index].edit = false;
          } else {
            formik.values["emergency_contacts"][index].edit = true;
          }
        }

        return null;
      });
    }
  }
  if (fieldsChange.indexOf("user_stabilities") !== -1) {
    if (formik.values["user_stabilities"].length === 0) {
      fieldsChange.splice(fieldsChange.indexOf("user_stabilities"), 1);
    } else {
      formik.values["user_stabilities"].map((item: any, index: any) => {
        const data = initialData["user_stabilities"][index];
        if (data !== null && data !== undefined) {
          const fieldsChangeData = Object.keys(item).filter(
            (change: any) =>
              ClearCPF(data[change]?.toString()) !==
              ClearCPF(item[change]?.toString())
          );
          if (fieldsChangeData.length === 0) {
            formik.values["user_stabilities"][index].edit = false;
          } else {
            formik.values["user_stabilities"][index].edit = true;
          }
        }

        return null;
      });
    }
  }
  
  const dataSend: any = {};
  // eslint-disable-next-line array-callback-return
  Object.entries(formik.values).map(([key, value]: any) => {
    if (fieldsChange.indexOf(key) !== -1 && key !== "internship_detail" ) {
      dataSend[key] = value;
    }
  });
  return { fieldsChange, fieldsTouched, dataSend };
}
