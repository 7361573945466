/** @format */

export const permissionsMock: {
  id: number;
  uuid: "manager" | "rh" | "collaborator" | "accountant" | "admin";
  name: string;
  permissions: string[];
}[] = [
  {
    id: 1,
    uuid: "manager",
    name: "Gestor",
    permissions: [
      "home",
      "organizational",
      "dataPulse",
      "notification",
      "vacationTimeDashboard",
      "settingsAccount",
      // "filesAccount",
      // "filesAccountView",
      // "filesAccountDocument",
      "employeeActive",
      "employeeLeave",
      "employeePosition",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationRequestReturn",
      "requestUpdateInformationDetails",
      "requestUpdateInformationDetailsEdit",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestPersonalDetailsEdit",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsEdit",
      "requestDismissalRequests",
      "requestDismissalRequestsNew",
      "requestDismissalRequestsDetails",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsEdit",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "reportEmployee",
      "reportDismissal",
    ],
  },
  {
    id: 2,
    uuid: "rh",
    name: "Recursos humanos",
    permissions: [
      "home",
      "dataPulse",
      "notification",
      "audit",
      "usersRole",
      "permissionsAudit",
      "vacationTimeDashboard",
      "vacationCollaborator",
      "vacationCollaboratorNew",
      "vacationCollaboratorDetails",
      "settingsAccount",
      // "filesAccount",
      // "filesAccountView",
      // "filesAccountDocument",
      // "filesAccountUpload",
      "settingsRequiredUpdateInformation",
      "organizational",
      "processes",
      "educationalInstitution",
      "educationalInstitutionNew",
      "educationalInstitutionDetails",
      "employeeLeave",
      "employeeActive",
      "employeeInactive",
      "employeePending",
      "employeePendingDetails",
      "employeePendingActive",
      "employeePosition",
      "sharedPositions",
      "employeeNew",
      "reportEmployee",
      "reportDismissal",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestLeaveDetailsEdit",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsEdit",
      "requestDismissalRequests",
      "requestDismissalRequestsNew",
      "requestDismissalRequestsDetails",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPositionDetailsEdit",
      "requestPositionDetailsRemove",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsEdit",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewDetailsEdit",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationRequestReturn",
      "requestUpdateInformationDetails",
      "requestUpdateInformationDetailsEdit",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestPersonalDetailsEdit",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "permissionsRequiredForgetPassword",
      "permissionGroup",
      "permissionGroupNew",
      "permissionGroupDetails",
      "permissionsRequiredPersonnel",
      "permissionsRequiredDismissals",
      "permissionsRequiredLeave",
      "permissionsRequiredPosition",
      "permissionsRequiredPromote",
      "permissionsRequiredSalaryReview",
      "permissionsRequiredTransfer",
      "permissionsRequiredVacation",
      "permissionLetter",
      "permissionLetterNew",
      "permissionLetterDetails",
      "permissionsRequiredUpdateInformation",
      "permissionsGrade",
      "permissionsWorkflow",
      "permissionFilesAccount",
      "permissionOrganizational",
      "grades",
      "reference",
      "tableSalary",
      "tableSalaryDashboard",
      // "workflow",
      // "workflowNew",
      // "workflowDetails",
      "calendar",
      "jobRoles",
      "jobRoleNew",
      "jobRoleDetails",
      "jobRoleLevels",
      "jobRoleMenu",
      "permissionsRequiredUpdateInformationEdit",
      "workSchedule",
      "workScheduleNew",
      "workScheduleDetails",
      "stabilityTypes",
      "stabilityTypeNew",
      "stabilityTypeDetails",
      // "upload",
      // "uploadNew",
      // "uploadView",
      "labelsConfig",
      "permissionsAdmission",
    ],
  },
  {
    id: 3,
    uuid: "collaborator",
    name: "Colaborador",
    permissions: [
      "home",
      "dataPulse",
      "notification",
      "organizational",
      "settingsAccount",
      // "filesAccount",
      // "filesAccountView",
      // "filesAccountDocument",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationRequestReturn",
      "requestUpdateInformationDetails",
      "requestUpdateInformationDetailsEdit",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
    ],
  },
  {
    id: 4,
    uuid: "accountant",
    name: "Contador",
    permissions: [
      "home",
      "notification",
      "organizational",
      "processes",
      "settingsAccount",
      // "filesAccount",
      // "filesAccountView",
      // "filesAccountDocument",
      "requestLeaveDetails",
      "requestDismissalsDetails",
      "requestUpdateInformationDetails",
      "requestUpdateInformationDetailsEdit",
      "requestVacationDetails",
      "requestSalaryReviewDetails",
      "requestPromoteDetails",
      "requestTransferDetails",
      "requestPositionDetails",
      "requestDetailsComplete",
      "requestDetailsAccountingReturned",
    ],
  },
  {
    id: 5,
    uuid: "admin",
    name: "Administrador",
    permissions: [
      "home",
      "dataPulse",
      "notification",
      "audit",
      "usersRole",
      "permissionsAudit",
      "vacationTimeDashboard",
      "vacationCollaborator",
      "vacationCollaboratorNew",
      "vacationCollaboratorDetails",
      "settingsAccount",
      // "filesAccount",
      // "filesAccountView",
      // "filesAccountDocument",
      // "filesAccountUpload",
      "settingsRequiredUpdateInformation",
      "calendar",
      "organizational",
      "processes",
      "educationalInstitution",
      "educationalInstitutionNew",
      "educationalInstitutionDetails",
      "employeeLeave",
      "employeeActive",
      "employeeInactive",
      "employeePending",
      "employeePendingDetails",
      "employeePendingActive",
      "employeePosition",
      "employeeNew",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestLeaveDetailsEdit",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsEdit",
      "requestDismissalRequests",
      "requestDismissalRequestsNew",
      "requestDismissalRequestsDetails",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPositionDetailsEdit",
      "requestPositionDetailsRemove",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsEdit",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewDetailsEdit",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationRequestReturn",
      "requestUpdateInformationDetails",
      "requestUpdateInformationDetailsEdit",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestPersonalDetailsEdit",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "permissionsRequiredForgetPassword",
      "permissionGroup",
      "permissionGroupNew",
      "permissionGroupDetails",
      "permissionsRequiredPersonnel",
      "permissionsRequiredDismissals",
      "permissionsRequiredLeave",
      "permissionsRequiredPosition",
      "permissionsRequiredPromote",
      "permissionsRequiredSalaryReview",
      "permissionsRequiredTransfer",
      "permissionsRequiredVacation",
      "permissionLetter",
      "permissionLetterNew",
      "permissionLetterDetails",
      "permissionsRequiredUpdateInformation",
      "permissionsGrade",
      "permissionsWorkflow",
      "permissionFilesAccount",
      "permissionOrganizational",
      "permissionsAdmission",
      "grades",
      "reference",
      "tableSalary",
      "tableSalaryDashboard",
      // "workflow",
      // "workflowNew",
      // "workflowDetails",
      "jobRoleMenu",
      "jobRoles",
      "jobRoleNew",
      "jobRoleDetails",
      "jobRoleLevels",
      "permissionsRequiredUpdateInformationEdit",
      "stabilityTypes",
      "stabilityTypeNew",
      "stabilityTypeDetails",
      // "upload",
      // "uploadNew",
      // "uploadView",
      "labelsConfig",
    ],
  },
];
