/**
 * eslint-disable @typescript-eslint/no-unused-expressions
 *
 * @format
 */

/* eslint-disable no-sequences */
import { createSlice } from "@reduxjs/toolkit";

export const initial: any = {
  data: { delegation_data: { delegation_options: [] } },
  permissions: null,
  photo: null,
  companySettings: {
    actions:{
      returned: true,
      approved: true,
      disapproved: true,
    }
  },
};
export const loadingSlice = createSlice({
  name: "user",
  initialState: initial,
  reducers: {
    updateUser: (state, action) => {
      state.data = action?.payload;
    },
    updatePhoto: (state, action) => {
      state.photo = action?.payload ?? initial.photo;
    },
    updateCompanySettings: (state, action) => {
      state.companySettings = action?.payload ?? initial.companySettings;
    },
    updateUserPermissions: (state, action) => {
      state.permissions = action?.payload ?? null;
    },
    updateUserDelegation: (state, action) => {
      state.delegation_data = action?.payload ?? { delegation_options: [] };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUser,
  updateCompanySettings,
  updatePhoto,
  updateUserPermissions,
  updateUserDelegation,
} = loadingSlice.actions;

export default loadingSlice.reducer;
