/** @format */

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import NavCollapse from "./navCollapse";
import NavItem from "./navItem";

const NavGroup = ({ item, level }: any) => {
  const items = item.children?.map((menu: any) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={level} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={level} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

export default NavGroup;
