import Cookies from "js-cookie";

function LabelData(chave: string, value: any) {
  switch (chave) {
    case "department":
      return value ? value : "Departamento";
    case "business_unit":
      return value ? value : "Unidade de negócio";
    default:
      return "Sem valor";
  }
}

export function LabelFormat(chave: string) {
  const valueData = JSON.parse(Cookies.get("labels") ?? 'null') ?? null;
  let labelReturn = LabelData(
    chave,
    valueData !== null ? valueData[chave] : null
  );
  return labelReturn;
}
