/** @format */

import { Filter } from "../../../../components/filter";
import { AutoCompleteFieldMultiple } from "../../../../components/inputs/autoCompleteFieldMultiple";
import { GetTimes, GetYears } from "../../../../helpers/getYears";

export const Filters = ({ formik, calendar }: any) => {
  const options:any = calendar === "year" ? GetYears() : GetTimes()
  return (
    <Filter
      formik={formik}
      buttonLabel={"Buscar"}
      id={"type_statement_" + calendar}
      keys={{
        times: {
          label: "Períodos",
          options: options,
        },
      }}
    >
      <AutoCompleteFieldMultiple
        formik={formik}
        label="Períodos"
        limitTags={6}
        id="times"
        options={options}
      />
    </Filter>
  );
};
