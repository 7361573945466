/** @format */

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Save } from "@mui/icons-material";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";

export function OrganizationalSettings() {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      hide_info: false,
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/users/organizational_settings")
        .then((response: any) => {
          toast.success(response?.data?.message);
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/users/organizational_settings")
      .then((response: any) => {
        formik.setValues({ hide_info: response?.data?.data?.hide_info });
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <InfoComponent instruction='' />
      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography fontSize={18} fontWeight={600} mb={2}>
          Organograma
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControlLabel
            key="hide_info"
            label="Esconder informações pessoais do organograma"
            onBlur={formik.handleBlur}
            control={
              <Checkbox
                checked={formik.values.hide_info}
                onChange={() => formik.setFieldValue("hide_info", !formik.values.hide_info)}
              />
            }
          />

          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <ButtonComponent
              endIcon={<Save />}
              variant={"contained"}
              type={"submit"}>
              salvar preferências
            </ButtonComponent>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
