
export function GradeItemPositionPercentage(gradeItems: any, salary: any) {
    if (!gradeItems || !salary || gradeItems.length === 0) {
        return "Não possui";
    }

    const gradeValue = gradeItems.find((item :any) => item.grade_column.name === 'yes').value

    let percentual = (salary / gradeValue) * 100;

    return `${percentual.toFixed(2)}%`;
}
