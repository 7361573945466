/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PostInstante } from "../../../../../services/api";
import { Form } from "../helpers/form";
import { useNavigate } from "react-router-dom";

export function EducationalInstitutionNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      code: "",
      description: "",
      postal_code: "",
      number: "",
      complement: "",
      cnpj: "",
    },
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/educational_institutions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/educationalInstitution");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            {
              label: "Parametrizações",
              value: "/settings/educationalInstitution",
            },
            { label: "Instituição de ensino" },
          ]}
        />
        <InfoComponent instruction="Nesta tela é permitido a configuração de dias para salvar da auditória." />

        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Cadastrar nova Instituição de ensino
              </Typography>
            </Grid>
            <Form formik={formik} />
          </Paper>
          <Grid container gap={2} justifyContent={"end"} mt={2}>
            <ButtonComponent
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              endIcon={<Undo />}
            >
              Voltar
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              onClick={formik.handleSubmit}
              endIcon={<Save />}
            >
              Salvar modificações
            </ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
