/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAproved } from "../../../../../../../components/actionApproved";
import { ActionDisapproved } from "../../../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../../../components/actionResend";
import { ActionReturned } from "../../../../../../../components/actionReturned";
import { ButtonComponent } from "../../../../../../../components/buttonComponent";
import { StatusPermittedByRoleContainer } from "../../../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../../../services/api";
import { HistoryComponent } from "../helpers/historyComponent";
import { validationSchema } from "../helpers/constants";
import { recruitment_types } from "../../../../../../../config/constants";
import { AutoCompleteField } from "../../../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../../../components/inputs/inputTextField";

export function PersonalForExistingPositionDetails({ data }: any) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState<any>({
    job_role: [],
    position: [],
  });

  const user = useSelector((state: any) => state.user.data);
  const companySettings = useSelector((state: any) => state.user.companySettings);
  const requiredFields = user.role === "manager"
    ? companySettings?.personnel_requisitions_rules?.manager_fields ?? []
    : companySettings?.personnel_requisitions_rules?.rh_fields ?? [];

  const permissions = useSelector((state: any) => state.user.permissions);

  const soliciationComplete = data?.solicitation_data?.status === "completed";
  const soliciationApproved = data?.solicitation_data?.status === "approved";
  const isRh = user.role === "rh";
  const isResendable =
    data?.solicitation_data?.status === "returned" &&
    user.id === data?.record?.created_by_id;

  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPersonalDetailsEdit") !== -1 &&
      data?.solicitation_data?.status === "pending") ||
    (isRh && !soliciationComplete && !soliciationApproved) ||
    isResendable;

  const isPositionOccupied = data?.position?.status === 'occupied';
  const hasVacancyDate = isPositionOccupied || (soliciationApproved && data?.record?.vacancy_date !== null)

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "",
      salary: "",
      initial_date: null,
      position_id: "",
      observations: "",
      vacancy_date: null,
      recruitment_type: '',
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema({ userRole: user.role, options: options, companySettings: companySettings }),
    onSubmit: (values) => {
      onSubmit(values, null, true, true);
    },
  });

  useEffect(() => {
    const initial_date = dayjs(data?.record?.initial_date);
    const vacancy_date = data?.record?.vacancy_date && dayjs(data?.record?.vacancy_date);

    formik.setValues({
      job_role_id: data?.record?.job_role_id,
      salary: FormatCurrency({
        target: {
          value: `${data?.record?.salary}` || "0",
        },
      }),
      initial_date,
      position_id: data?.position?.id,
      observations: data?.record?.observations,
      vacancy_date: vacancy_date,
      recruitment_type: data?.record?.recruitment_type,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data.data.options);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any, callback: any, hasToast: boolean = false, historyBack: boolean = false) => {
    setValide(false)
    dispatch(updateLoading(true));
    PutInstante(
      id,
      {
        ...values,
        salary: ClearCurrency(values.salary),
      },
      "/personnel_requisitions"
    )
      .then((response: any) => {
        if (callback) callback();
        dispatch(updateLoading(false));
        if (historyBack) window.history.back();
        if (hasToast) toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid width={"inherit"}>
        <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
          <TextField
            fullWidth
            disabled
            label="Posição"
            value={`${data?.position?.code} ${data?.position?.job_role_name}`}
          />

          <AutoCompleteField
            formik={formik}
            disabled={!edit}
            id="recruitment_type"
            label="Tipo de recrutamento"
            options={recruitment_types}
          />

          {hasVacancyDate &&
            <InputDateField
              disabled={!edit}
              formik={formik}
              id="vacancy_date"
              label="Data prevista para desocupação da posição"
            />
          }

          {user.role === "rh" && <>
            <AutoCompleteField
              formik={formik}
              disabled={!edit}
              id="job_role_id"
              label="Cargo"
              options={options?.job_role?.map((jobRole: any) => ({
                label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}` : jobRole.label,
                value: jobRole.value,
              }))}
            />
          </>}

          <InputCurrencyField
            disabled={!edit  && (user.role !== 'manager' || requiredFields.includes("salary"))}
            formik={formik}
            id="salary"
            label="Remuneração"
          />

          {requiredFields.includes("initial_date") && (
            <InputDateField
              disabled
              formik={formik}
              id="initial_date"
              label="Data prevista de início"
            />
          )}
        </Grid>

        <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
          <InputTextField
            disabled={!edit}
            formik={formik}
            id="observations"
            label="Responsabilidades"
            multiline={true}
            rows={4}
          />
        </Grid>
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>

          {isResendable && (
            <ActionResend
              id={id}
              url={"personnel_requisitions"}
              disabled={!formik.isValid}
              onSubmit={(callback: any) => onSubmit(formik.values, callback)}
            />
          )}

          {edit && (
            <ButtonComponent
              variant={"contained"}
              type={"submit"}
              onClick={() => setValide(true)}
              endIcon={<Edit />}
            >
              Editar
            </ButtonComponent>
          )}

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={[
              "pending",
              "waiting_manager_user",
              "waiting_rh_user",
              "accounting_return",
            ]}
            managerAllowedStatuses={["pending", "waiting_manager_user"]}
            status={data?.solicitation_data?.status}>
            <ActionReturned id={id} url={"personnel_requisitions"} />
            <ActionDisapproved id={id} url={"personnel_requisitions"} />
          </StatusPermittedByRoleContainer>

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={[
              "pending",
              "waiting_manager_user",
              "waiting_rh_user",
            ]}
            managerAllowedStatuses={["pending", "waiting_manager_user"]}
            status={data?.solicitation_data?.status}
          >
            <ActionAproved id={id} url={"personnel_requisitions"} beforeAction={(callback: any) => onSubmit(formik.values, callback)} />
          </StatusPermittedByRoleContainer>

          <StatusPermittedByRoleContainer
            rhAllowedStatuses={["accounting_return"]}
            status={data?.solicitation_data?.status}
          >
            <ActionResend
              id={id}
              url={"personnel_requisitions"}
              hasObservation
              disabled={!formik.isValid}
              onSubmit={(callback: any) => onSubmit(formik.values, callback)}
            />
          </StatusPermittedByRoleContainer>
        </Grid>

        <HistoryComponent
          data={data?.solicitation_data?.solicitation_histories}
          options={options}
        />

      </Grid>
    </form>
  );
}
