/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../../../components/buttonComponent";
import { GenericError } from "../../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { position_type, recruitment_types } from "../../../../../../../config/constants";
import { AutoCompleteField } from "../../../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../../../components/inputs/inputTextField";
import { LabelFormat } from "../../../../../../../helpers/labelFormat";

export function PersonalForNewPositionNew() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.data);
  const companySettings = useSelector((state: any) => state.user.companySettings);
  const requiredFields = user.role === "manager"
    ? companySettings?.personnel_requisitions_rules?.manager_fields ?? []
    : companySettings?.personnel_requisitions_rules?.rh_fields ?? [];


  const [optionsRequisition, setOptionsRequisition] = useState<any>({
    job_role: [],
    reason_solicitation: [],
    manager: [],
    department: [],
    business_unit: [],
    cost_center: []
  });

  const formik = useFormik({
    initialValues: {
      job_role_id: "",
      salary: "",
      initial_date: null,
      recruitment_type: '',
      observations: "",
      department_id: "",
      business_unit_id: "",
      cost_center_id: "",
      manager_id: user.role === "manager" ? user.id : null,
      reason_solicitation_id: "",
      position_type: "",
      name: "",
      quantity: "",
    },
    validationSchema: validationSchema({ userRole: user.role, companySettings: companySettings }),
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
          has_new_position: true,
          position_type: Number(values.position_type)
        },
        "/personnel_requisitions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ has_new_position: true }, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptionsRequisition((prevState: any) => ({
          ...prevState,
          ...response.data.data.options,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/reason_solicitations")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptionsRequisition((prevState: any) => ({
          ...prevState,
          reason_solicitation: response.data,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid width={"inherit"}>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <AutoCompleteField
            formik={formik}
            id="recruitment_type"
            label="Tipo de recrutamento"
            options={recruitment_types}
          />
          <AutoCompleteField
            formik={formik}
            id="manager_id"
            label="Gestor"
            disabled={user.role === "manager"}
            options={optionsRequisition?.manager.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            id="department_id"
            label={LabelFormat("department")}
            options={optionsRequisition?.department.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.name}`,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            id="cost_center_id"
            label="Centro de custo"
            options={optionsRequisition?.cost_center.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.description}`,
            }))}
          />
          <InputTextField
            formik={formik}
            id="name"
            label="Função"
          />
          <InputTextField
            type='number'
            formik={formik}
            label={"Quantidade"}
            id={"quantity"}
          />

          <AutoCompleteField
            formik={formik}
            id="business_unit_id"
            label={LabelFormat("business_unit")}
            options={optionsRequisition?.business_unit.map((item: any) => ({
              value: item.id,
              label: `${item?.code} ${item?.name}`,
            }))}
          />

          {user.role === "rh" &&
            <AutoCompleteField
              formik={formik}
              id="job_role_id"
              label="Cargo"
              options={optionsRequisition?.job_role?.map((jobRole: any) => ({
                label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}` : jobRole.label,
                value: jobRole.value,
              }))}
            />
          }

          {(user.role !== 'manager' || requiredFields.includes("salary")) && (
            <InputCurrencyField
              formik={formik}
              id="salary"
              label="Remuneração"
            />
          )}

          <AutoCompleteField
            formik={formik}
            id="reason_solicitation_id"
            label="Motivo da solicitação"
            options={optionsRequisition?.reason_solicitation.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <AutoCompleteField
            formik={formik}
            id="position_type"
            label="Tipo de posição"
            options={position_type}
          />
          {requiredFields.includes("initial_date") && (
            <InputDateField
              formik={formik}
              id="initial_date"
              label="Data prevista de início"
            />
          )}
        </Grid>
        <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
          <InputTextField
            formik={formik}
            id="observations"
            label="Responsabilidades"
            multiline={true}
            rows={4}
          />
        </Grid>
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            type={"submit"}
            endIcon={<AddCircle />}>
            Cadastrar
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
