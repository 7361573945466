import { Grid, IconButton, Tooltip } from "@mui/material";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { DeleteForeverOutlined, Download, Undo } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import { optionsTable } from "../../../../config/optionsTable";
import { columns2, validationSchemaView } from "../helpers/constants";
import { useFormik } from "formik";
import { InfoComponent } from "../../../../components/infoComponent";
import { useParams } from "react-router-dom";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { Filters } from "../helpers/filters";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, RemoveInstance } from "../../../../services/api";
import { EmptyFilter } from "../../../../components/emptyFilter";
import useUploadView from "../../../../services/protected/hooks/useUploadView";
import { toast } from "react-toastify";

export function UploadView() {
  let { id } = useParams();
  const [options, setData]: any = useState({ options: { document_types: [] } });
  const [typeCalendar, setTypeCalendar] = useState(null);
  const dispatch = useDispatch();
  const {
    data,
    total,
    page,
    perPage,
    setPage,
    search,
    setPerPage,
    setOrder,
    searchCall,
    setSearch,
  } = useUploadView(id);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/my_documents_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const formik: any = useFormik({
    initialValues: {
      times: [],
      document_type: "",
    },
    validationSchema: validationSchemaView,
    onSubmit: (values) => {
      const send = {
        times: values.times.map((item: any) => item.value),
        document_type: values.document_type,
      };
      if (send.document_type.length === 0 && send.times.length === 0) {
        setSearch(null);
      } else {
        setSearch(send);
      }
    },
  });
  return (
    <Grid container gap={4}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Colaboradores", value: "/employee/active" },
            { label: "Ativos", value: "/employee/active" },
            {
              label: "Ver documentos",
            },
          ]}
        />
        <InfoComponent
          user={data.user_data}
          instruction="Visualizar todos os documentos do colaborador."
        />
        <Filters
          formik={formik}
          setTypeCalendar={setTypeCalendar}
          calendar={typeCalendar}
          data={options}
        />
        {search === null ? (
          <EmptyFilter />
        ) : (
          <MUIDataTable
            title={""}
            data={data}
            columns={[
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                  customBodyRenderLite: (dataIndex: number) => {
                    return (
                      <Grid container gap={1}>
                        <Tooltip title={"Baixar arquivo"}>
                          <a
                            title={data[dataIndex].file.name}
                            href={data[dataIndex].file.url}
                            target="_blank"
                            style={{
                              padding: 8,
                              fontSize: 12,
                              borderRadius: 24,
                              fontWeight: 800,
                              textDecorationLine: "none",
                              display: "flex",
                              gap: 4,
                              alignItems: "center",
                              cursor: "download",
                            }}
                            download={data[dataIndex].file.name}
                            rel="noreferrer"
                          >
                            <Download color="primary" fontSize={"small"} />
                          </a>
                        </Tooltip>
                        <Tooltip title={"Deletar documento"}>
                          <IconButton
                            onClick={() => {
                              dispatch(updateLoading(true));
                              RemoveInstance(
                                data[dataIndex].uuid,
                                "/my_documents"
                              )
                                .then((response: any) => {
                                  dispatch(updateLoading(false));
                                  toast.success(response?.data?.message);
                                  formik.handleSubmit();
                                })
                                .catch((e: any) => {
                                  GenericError(e, dispatch);
                                });
                            }}
                            color="error"
                          >
                            <DeleteForeverOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    );
                  },
                },
              },
              ...columns2,
            ]}
            options={{
              ...optionsTable,
              searchPlaceholder: "Pesquise por tipo de documento",
              onSearchChange: (value: string) => {
                searchCall(value);
              },
              onColumnSortChange: (value: string, direction: string) => {
                setOrder(`${value} ${direction}`);
              },
              count: total,
              page: page,
              onChangePage: (page: number) => {
                setPage(page);
              },
              rowsPerPage: perPage,
              onChangeRowsPerPage: (perPage: number) => {
                setPerPage(perPage);
              },
            }}
          />
        )}
        <Grid />
        <Grid container gap={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Grid>
  );
}
