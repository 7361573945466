export const loadingLottie = (colors:any) => {
  return {
    v: "4.6.8",
    fr: 29.9700012207031,
    ip: 0,
    op: 40.0000016292334,
    w: 256,
    h: 256,
    nm: "Comp 1",
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: "Shape Layer 3",
        ks: {
          o: {
            a: 0,
            k: 100,
          },
          r: {
            a: 0,
            k: 0,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 20,
                s: [208.6, 127.969, 0],
                e: [208.6, 88, 0],
                to: [0, -6.66145849227905, 0],
                ti: [0, -0.00520833348855, 0],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 30,
                s: [208.6, 88, 0],
                e: [208.6, 128, 0],
                to: [0, 0.00520833348855, 0],
                ti: [0, -6.66666650772095, 0],
              },
              {
                t: 40.0000016292334,
              },
            ],
          },
          a: {
            a: 0,
            k: [-70, -0.5, 0],
          },
          s: {
            a: 0,
            k: [75, 75, 100],
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [33.75, 34.5],
                },
                p: {
                  a: 0,
                  k: [0, 0],
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: colors[0],
                },
                o: {
                  a: 0,
                  k: 100,
                },
                r: 1,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-70.125, -0.5],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            ix: 1,
            mn: "ADBE Vector Group",
          },
        ],
        ip: 0,
        op: 300.00001221925,
        st: 0,
        bm: 0,
        sr: 1,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: "Shape Layer 2",
        ks: {
          o: {
            a: 0,
            k: 100,
          },
          r: {
            a: 0,
            k: 0,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 15,
                s: [168.6, 128, 0],
                e: [168.6, 88, 0],
                to: [0, -6.66666650772095, 0],
                ti: [0, 0, 0],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 25,
                s: [168.6, 88, 0],
                e: [168.6, 128, 0],
                to: [0, 0, 0],
                ti: [0, -6.66666650772095, 0],
              },
              {
                t: 35.0000014255792,
              },
            ],
          },
          a: {
            a: 0,
            k: [-70, -0.5, 0],
          },
          s: {
            a: 0,
            k: [75, 75, 100],
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [33.75, 34.5],
                },
                p: {
                  a: 0,
                  k: [0, 0],
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: colors[1],
                },
                o: {
                  a: 0,
                  k: 100,
                },
                r: 1,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-70.125, -0.5],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            ix: 1,
            mn: "ADBE Vector Group",
          },
        ],
        ip: 0,
        op: 300.00001221925,
        st: 0,
        bm: 0,
        sr: 1,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: "Shape Layer 1",
        ks: {
          o: {
            a: 0,
            k: 100,
          },
          r: {
            a: 0,
            k: 0,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 10,
                s: [128.594, 127.969, 0],
                e: [128.594, 88, 0],
                to: [0, -6.66145849227905, 0],
                ti: [0, -0.00520833348855, 0],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 20,
                s: [128.594, 88, 0],
                e: [128.594, 128, 0],
                to: [0, 0.00520833348855, 0],
                ti: [0, -6.66666650772095, 0],
              },
              {
                t: 30.0000012219251,
              },
            ],
          },
          a: {
            a: 0,
            k: [-70, -0.5, 0],
          },
          s: {
            a: 0,
            k: [75, 75, 100],
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [33.75, 34.5],
                },
                p: {
                  a: 0,
                  k: [0, 0],
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: colors[2],
                },
                o: {
                  a: 0,
                  k: 100,
                },
                r: 1,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-70.125, -0.5],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            ix: 1,
            mn: "ADBE Vector Group",
          },
        ],
        ip: 0,
        op: 300.00001221925,
        st: 0,
        bm: 0,
        sr: 1,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: "Shape Layer 4",
        ks: {
          o: {
            a: 0,
            k: 100,
          },
          r: {
            a: 0,
            k: 0,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 5,
                s: [88.6, 127.969, 0],
                e: [88.6, 88, 0],
                to: [0, -6.66145849227905, 0],
                ti: [0, -0.00520833348855, 0],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 15,
                s: [88.6, 88, 0],
                e: [88.6, 128, 0],
                to: [0, 0.00520833348855, 0],
                ti: [0, -6.66666650772095, 0],
              },
              {
                t: 25.0000010182709,
              },
            ],
          },
          a: {
            a: 0,
            k: [-70, -0.5, 0],
          },
          s: {
            a: 0,
            k: [75, 75, 100],
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [33.75, 34.5],
                },
                p: {
                  a: 0,
                  k: [0, 0],
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: colors[3],
                },
                o: {
                  a: 0,
                  k: 100,
                },
                r: 1,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-70.125, -0.5],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            ix: 1,
            mn: "ADBE Vector Group",
          },
        ],
        ip: 0,
        op: 300.00001221925,
        st: 0,
        bm: 0,
        sr: 1,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: "Shape Layer 5",
        ks: {
          o: {
            a: 0,
            k: 100,
          },
          r: {
            a: 0,
            k: 0,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 0,
                s: [48.6, 127.969, 0],
                e: [48.6, 88, 0],
                to: [0, -6.66145849227905, 0],
                ti: [0, -0.00520833348855, 0],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                n: "0p667_1_0p333_0",
                t: 10,
                s: [48.6, 88, 0],
                e: [48.6, 128, 0],
                to: [0, 0.00520833348855, 0],
                ti: [0, -6.66666650772095, 0],
              },
              {
                t: 20.0000008146167,
              },
            ],
          },
          a: {
            a: 0,
            k: [-70, -0.5, 0],
          },
          s: {
            a: 0,
            k: [75, 75, 100],
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [33.75, 34.5],
                },
                p: {
                  a: 0,
                  k: [0, 0],
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: colors[4],
                },
                o: {
                  a: 0,
                  k: 100,
                },
                r: 1,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-70.125, -0.5],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            ix: 1,
            mn: "ADBE Vector Group",
          },
        ],
        ip: 0,
        op: 300.00001221925,
        st: 0,
        bm: 0,
        sr: 1,
      },
    ],
  };
};
