/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { FilterEmpty } from "../../../../../helpers/emptyData";

export function JobRoleNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [grades, setGrades] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [cboFamilies, setCboFamilies] = useState<any[]>([]);
  const [cboOcupations, setCboOcupations] = useState<any[]>([]);
  const [cboFamily, setCboFamily] = useState<any>(null);


  const formik: any = useFormik({
    initialValues: {
      name: "",
      code: "",
      salary_minimum: "",
      salary_maximum: "",
      grade_id: null,
      job_role_group_id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/job_roles")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/jobRoles");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/job_roles_form")
      .then((response: any) => {
        setGrades(response.data.data.grades);
        setLevels(response.data.data.job_role_levels);
        setCboFamilies(FilterEmpty(response.data.data.cbo_families));

        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cboFamily) {
      dispatch(updateLoading(true));
      GetInstance({ cbo_family_id: cboFamily }, "/cbo_occupations")
        .then((response: any) => {
          setCboOcupations(FilterEmpty(response.data.data));
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            GenericError(e, dispatch);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cboFamily]);

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Cargos",
              value: "/settings/jobRoles",
            },
            { label: "Novo cargo" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar novo grupo
            </Typography>
          </Grid>

          <Form
            formik={formik}
            grades={grades}
            levels={levels}
            cboFamilies={cboFamilies}
            cboOcupations={cboOcupations}
            setCboFamily={setCboFamily}
            setCboOcupations={setCboOcupations}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
