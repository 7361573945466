import { monthsCalendar } from "../config/constants";
function twoChar(str: any) {
  return str.toString().length === 1 ? "0" + str : str;
}
export function GetTimes() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const yearsWithMonths = [];

  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    const months = [];
    if (currentYear === year) {
      for (let month = 0; currentMonth >= month; month++) {
        months.push({
          value: `${twoChar(month+1)}/${year}`,
          label: `${monthsCalendar[month]?.label}/${year}`,
        });
      }
    } else {
      for (let month = 11; month >= 0; month--) {
        months.push({
          value: `${twoChar(month +1)}/${year}`,
          label: `${monthsCalendar[month]?.label}/${year}`,
        });
      }
    }
    yearsWithMonths.push(...months);
  }
  return yearsWithMonths;
}

export function GetYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    
    years.push({value: year.toString(), label: year.toString()});
  }
  return years;
}
