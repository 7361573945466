/** @format */

import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { PostInstante } from "../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  id: string | number | null | undefined;
  url: string;
  close?: () => void;
  beforeAction?: (onSubmit: any) => void;
  onlyAction?: (onSubmit: any) => void;
  values?: any;
}

export function ActionAproved({
  id,
  url,
  beforeAction,
  close,
  values,
  onlyAction,
}: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const companySettings = useSelector(
    (state: any) => state.user.companySettings
  );

  function Approve() {
    close && close();
    setOpen(false);
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "approved",
        action_justification: null,
        ...values,
      },
      "/" + url + "/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  function ApproveClose() {
    close && close();
    setOpen(false);
  }

  return (
    companySettings?.actions?.approved && (
      <form>
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
          <AlertDialog.Trigger>
            <ButtonComponent
              variant={"contained"}
              color={"success"}
              endIcon={<Send />}
            >
              Aprovar
            </ButtonComponent>
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>
              Deseja realmente aprovar esta solicitação?
            </AlertDialog.Title>
            <AlertDialog.Description size="2">
              Após a confirmação, não será possivel reverter o processo. Tenha
              absoluta certeza antes de realizar essa ação.
            </AlertDialog.Description>
            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <ButtonComponent
                  variant={"outlined"}
                  color="error"
                  endIcon={<Cancel />}
                >
                  Desistir
                </ButtonComponent>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <ButtonComponent
                  color="success"
                  variant={"contained"}
                  onClick={() =>
                    onlyAction
                      ? onlyAction(ApproveClose)
                      : beforeAction
                      ? beforeAction(Approve)
                      : Approve()
                  }
                  endIcon={<CheckCircle />}
                >
                  Confirmar
                </ButtonComponent>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    )
  );
}
