/** @format */

import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { requestPersonalTypes } from "../../../../../config/constants";
import { GetInstance } from "../../../../../services/api";
import { GenericError } from "../../../../../helpers/genericError";
import { PersonalForNewPositionNew } from '../helpers/newPosition/new';
import { PersonalForExistingPositionNew } from '../helpers/existingPosition/new';
import { updateLoading } from "../../../../../redux/reducers/common";
import { EmptyCustom } from "../../../../../components/emptyCustom";

export function PersonalNew() {
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const [personalTypes, setPersonalTypes]: any = useState([]);
  const [selectedType, setSelectedtype] = useState(params.get("type") || null);
  const [notTypes, setNotTypes] = useState(false);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        const requestTypes = response.data.data?.record?.personnel_requisitions_rules?.active_request_types || [];
        setNotTypes(requestTypes.length === 0);

        const filteredType: any = requestPersonalTypes.filter((item: any) =>
          (requestTypes.includes("request_personal_for_new_position") && item.value === "newPosition") ||
          (requestTypes.includes("request_personal_for_existing_position") && item.value === "existingPosition")
        );

        if (filteredType.length === 1) {
          setSelectedtype(filteredType[0].value);
        } else {
          setPersonalTypes(filteredType);
        }
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(personalTypes)

  return (

    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Requisições", value: "/request/personal" },
          { label: "Pessoal", value: "/request/personal" },
          { label: "Nova solicitação" },
        ]}
      />

      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar requisição de pessoal
            </Typography>
          </Grid>
         

          {personalTypes?.length > 1 && !selectedType && (
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <AutoCompleteField
                formik={{
                  setFieldValue: (_id: any, value :any) => setSelectedtype(value)
                }}
                label='Selecione o tipo de requisição de pessoal:'
                options={personalTypes}
                id='request_type'
              />
            </Grid>
          )}

          {selectedType === 'newPosition' && <PersonalForNewPositionNew />}
          {selectedType === 'existingPosition' && <PersonalForExistingPositionNew />}
        </Paper>
        {notTypes && <EmptyCustom message="Não foi encontrado nenhum método, por favor revise as parametrizações."/>}
      </Grid>
    </Grid>

  );
}
