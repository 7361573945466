import * as yup from "yup";

export const personnelRequisitionRequestTypes = [
  {
    label: 'Habilitar fluxo de requisição de pessoal com nova posição',
    value: 'request_personal_for_new_position',
  },
  {
    label: 'Habilitar fluxo de requisição de pessoal para posição existente',
    value: 'request_personal_for_existing_position',
  },
]

export const rhFieldsOptions = [
  {
    label: "Data prevista de início",
    value: "initial_date"
  }
]

export const managerFieldsOptions = [
  {
    label: "Data prevista de início",
    value: "initial_date"
  },
  {
    label: "Remuneração",
    value: "salary"
  }
]

export const validationSchema = yup.object().shape({
  position_search_method: yup
    .string()
    .required("Campo obrigatório")
});

