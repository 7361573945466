export const getUniquePersonnelRequisitions = (list: any[]) => {
  return Array.from(
    new Map(
      list.map((item) => [
        item.personnel_requisition?.id,
        item.personnel_requisition,
      ])
    ).values()
  );
};

export  const getPositionsFilteredByPersonnelRequisition = (positions: any[], personnelRequisition: string) => {
  return positions
    .filter((position) => position.personnel_requisition?.id === personnelRequisition);
};