/** @format */

import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useSharedPosition from "../../../../services/protected/hooks/useSharedPosition";
import { columns } from "./helpers/constants";

export function PositionShared() {
  const {
    data,
    total,
    perPage,
    page,
    setPage,
    setPerPage,
    searchCall,
    setOrder,
  } = useSharedPosition();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Colaboradores", value: "/employee/active" },
          { label: "Posições" },
        ]}
      />
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[...columns]}
          options={{
            ...optionsTable,
            download: true,
            searchPlaceholder: "Pesquise por nome",
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
