/** @format */

import { Download, UploadFile } from "@mui/icons-material";
import { Chip, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { getIn } from "formik";
import { useRef } from "react";
import { toast } from "react-toastify";

interface Props {
  formik: any;
  remove?: boolean;
  limit?: number;
  accept?: string;
  id?: string;
}

export const InputFile = ({
  formik,
  remove = true,
  limit,
  accept,
  id,
}: Props) => {
  const fileInputRef: any = useRef(null);
  const value = id ? getIn(formik.values, id) : formik.values.files;
  const error = id ? getIn(formik.errors, id) : formik.errors.files;
  const idKey = id ? id : "files";
  const theme = useTheme();
  const onFileDrop = (file: any) => {
    const tamanhoMaximo = 25 * 1024 * 1024;

    if (file.size > tamanhoMaximo) {
      toast.error("O arquivo excede o tamanho máximo de 25 MB");
      return;
    }

    const filesAll: any = value;
    filesAll.push(file);
    formik.setFieldValue(idKey, filesAll);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };
  return (
    <>
      {((limit !== undefined && limit > value?.length) ||
        limit === undefined) && (
        <Grid container mt={2}>
          <div
            style={{
              border: `1px ${
                error ? theme.palette.error.main : theme.palette.text.secondary
              } dashed`,
              color: error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
              cursor: "pointer",
              borderRadius: 10,
              width: "inherit",
            }}
            className="ContextMenuTrigger"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={openFileDialog}
          >
            <input
              type="file"
              accept={accept ?? "*"}
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
            <p style={{ display: "flex", justifyContent: "center" }}>
              <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
              arquivo aqui ou clique para selecionar um arquivo.
            </p>
          </div>
        </Grid>
      )}
      {error && (
        <Grid container mt={0.5} ml={2}>
          <Typography fontSize={"0.75rem"} textAlign={"center"} color={"error"}>
            {error}
          </Typography>
        </Grid>
      )}
      {value?.length > 0 && (
        <Grid container mt={2}>
          <Typography>
            {limit === 1 ? "Arquivo anexado:" : "Arquivos anexados:"}
          </Typography>
        </Grid>
      )}
      <Grid container mt={2} gap={2}>
        {remove
          ? value?.map((file: any, index: number) => (
              <Tooltip title={"Remover arquivo"}>
                <Chip
                  color={"primary"}
                  onDelete={() => {
                    const filesAll: any = value;
                    filesAll.splice(index, 1);
                    formik.setFieldValue(idKey, filesAll);
                  }}
                  label={file.name}
                  variant="outlined"
                />
              </Tooltip>
            ))
          : value?.map((file: any, index: number) => (
              <Tooltip title={"Baixar arquivo"}>
                <a
                  title={file.name}
                  href={file.url}
                  target="_blank"
                  style={{
                    border: theme.palette.primary.main + " 1px solid",
                    color: theme.palette.primary.main,
                    padding: 8,
                    fontSize: 12,
                    borderRadius: 24,
                    fontWeight: 800,
                    textDecorationLine: "none",
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}
                  download={file.name}
                  rel="noreferrer"
                >
                  <Download fontSize={"small"} />
                  {file.name}
                </a>
              </Tooltip>
            ))}
      </Grid>
    </>
  );
};
