/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { FormatDate } from "../../../../../helpers/formatDate";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { HistoryComponent } from "../helpers/historyComponent";

export function VacationDetails() {
  const { id } = useParams();
  const [data, setData]: any = useState({ vacations: [{}] });
  const userData = useSelector((state: any) => state.user);
  const isResendable =
    data?.status === "returned" &&
    (userData.data.id === data.created_by_id ||
      userData.data.id === data.target_user_id);
  const editManager =
    userData.data.name === data.manager_name &&
    data?.status === "waiting_manager_user";
  const statusAction =
    data?.status === "pending" ||
    data?.status === "waiting_manager_user" ||
    data?.status === "waiting_rh_user" ||
    editManager;
  const userAction = userData.data.id === data?.target_user_id;
  const edit =
    (statusAction && userAction) ||
    (userData.data.role === "rh" && data.solicitation_status !== "finished") ||
    isResendable ||
    editManager;
  const dispatch = useDispatch();
  const rule = data.company_rules?.approve || editManager;
  const formik: any = useFormik({
    initialValues: {
      vacation_items: [
        {
          start_date: null,
          end_date: null,
          days_quantity: "",
          thirteen_anticipation: "",
          abono_pecuniario: "",
          payment_date: null,
          description: "",
          assiduity_allowance: null,
        },
      ],
    },
    validationSchema: validationSchema(data, userData.data),
    validateOnBlur: !edit,
    validateOnChange: !edit,
    onSubmit: async (values: any) => {
      const sendValues = values;
      // eslint-disable-next-line array-callback-return
      await sendValues.vacation_items.map((item: any) => {
        item.days_quantity = Number(sendValues.days_quantity);
        item.abono_quantity =
          Number(sendValues.days_used) - Number(sendValues.days_quantity);
      });
      delete sendValues?.status;
      delete sendValues.vacation_items[0].vacation;
      dispatch(updateLoading(true));
      PutInstante(
        values.vacation_items[0].uuid,
        {
          ...sendValues.vacation_items[0],
        },
        "/vacation_items"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });
  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/vacation_items/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data.user_data,
          statusUser: response.data.data.user_data.status,
          ...response.data.data,
          ...response.data.data.record.vacation,
          ...response.data.data.record,
          ...response.data.data.company_rules,
          history: response.data.data.history,
          solicitation_data: response.data.data.solicitation_data,
          solicitation_status: response.data.data.record.status,
        });
        response.data.data.record.start_date = dayjs(
          response.data.data.record.start_date
        );
        response.data.data.record.end_date = dayjs(
          response.data.data.record.end_date
        );
        response.data.data.record.payment_date = dayjs(
          response.data.data.record.payment_date
        );

        const values: any = {};
        values.vacations_request_minimum_days =
          response.data.data.company_rules.vacations_request_minimum_days;
        values.vacation_items = [response.data.data.record];
        // ...response.data.data.record,
        values.days_init = response.data.data.record.vacation.days_available;
        values.days_used =
          response.data.data.record.vacation.days_available === 0
            ? 30
            : response.data.data.record.vacation.days_used +
              response.data.data.record.abono_quantity;
        values.days_available =
          response.data.data.record.vacation.days_available
        values.days =
          response.data.data.record.vacation.days_available +
          response.data.data.record.vacation.days_used;
        values.abono_quantity = response.data.data.record.abono_quantity;

        values.concessive_period_start = new Date(
          response.data.data.record.vacation.concessive_period_start
        );
        values.concessive_period_end = new Date(
          response.data.data.record.vacation.concessive_period_end
        );
        formik.setValues(values);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/vacation" },
            { label: "Férias", value: "/request/vacation" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          user={{ status: data.statusUser, ...data }}
          vacation={{ opt: [data.vacation], formik }}
          instructionMap={[
            "Sempre que houver mais de 1 periodo, o mais antigo sempre é o disponivel para programação.",
            "Mínimo de 5 dias para cada período.",
            "Se dividir o saldo em 3 períodos, pelo menos um deve ter 14 dias ou mais.",
            "Ao selecionar 'Sim' para abono pecuniário, 1/3 dos dias disponíveis será convertido em remuneração, e o saldo será reduzido proporcionalmente.",
            "As férias serão definidas pela disponibilidade de dias, sendo seu início dois dias antes de um feriado ou do Descanso Semanal Remunerado (DSR).",
            userData?.data?.role !== "rh" && !userAction
              ? `As férias devem ser solicitadas com pelo menos ${formik.values?.vacations_request_minimum_days} dias de antecedência`
              : "As férias podem ser criadas a qualquer momento pelo seu perfil.",
          ]}
        />

        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da programação para o período aquisitivo (
                {FormatDate(data?.vacation?.acquisition_period_start) +
                  " - " +
                  FormatDate(data?.vacation?.acquisition_period_end)}
                )
              </Typography>
            </Grid>
            <Form formik={formik} user={data} edit={!edit} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"vacation_items"}
                  disabled={!formik.isValid}
                  onSubmit={async (resendActionCallback: any) => {
                    const sendValues = formik.values;
                    // eslint-disable-next-line array-callback-return
                    await sendValues.vacation_items.map((item: any) => {
                      item.days_quantity = Number(sendValues.days_quantity);
                      item.abono_quantity =
                        Number(sendValues.days_used) -
                        Number(sendValues.days_quantity);
                    });
                    delete sendValues?.status;
                    delete sendValues.vacation_items[0].vacation;
                    dispatch(updateLoading(true));
                    PutInstante(
                      formik.values.vacation_items[0].uuid,
                      {
                        ...sendValues.vacation_items[0],
                      },
                      "/vacation_items"
                    )
                      .then((response: any) => {
                        resendActionCallback();
                        dispatch(updateLoading(false));
                        window.history.back();
                      })
                      .catch((e: any) => {
                        GenericError(e, dispatch);
                      });
                  }}
                />
              )}
              {edit && (
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}
                >
                  Editar
                </ButtonComponent>
              )}
              {rule && (
                <>
                  <StatusPermittedByRoleContainer
                    rhAllowedStatuses={[
                      "pending",
                      "waiting_manager_user",
                      "waiting_rh_user",
                    ]}
                    managerAllowedStatuses={["pending", "waiting_manager_user"]}
                    status={data?.solicitation_status}
                  >
                    <ActionReturned id={id} url={"vacation_items"} />
                    <ActionDisapproved id={id} url={"vacation_items"} />
                    <ActionAproved id={id} url={"vacation_items"} />
                  </StatusPermittedByRoleContainer>
                  <StatusPermittedByRoleContainer
                    rhAllowedStatuses={["accounting_return"]}
                    status={data?.solicitation_status}
                  >
                    <ActionReturned id={id} url={"vacation_items"} />
                    <ActionDisapproved id={id} url={"vacation_items"} />
                  </StatusPermittedByRoleContainer>
                </>
              )}
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data?.status}
              >
                <ActionCompleted id={id} url={"vacation_items"} />
                <ActionAccountingDisapproved id={id} url={"vacation_items"} />
              </StatusPermittedByRoleContainer>
            </Grid>
            <HistoryComponent data={data?.history} />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
