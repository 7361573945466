/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../services/api";
import { validationSchema, validationSchemaRH } from "../helpers/constants";
import { Form } from "../helpers/form";

export function LeaveNew() {
  const { id } = useParams();
  const [user, setUser]: any = useState({});
  const [valide, setValide] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state: any) => state.user.data);
  const formik = useFormik({
    initialValues: {
      absence_date: null,
      return_date: null,
      e_social_motive: "",
      observations: "",
      files: [],
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema:
      userState.role === "rh" ? validationSchemaRH : validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, { ...values, collaborator_id: user.uuid }, "/absences")
        .then((response: any) => {
          if (values.files.length > 0) {
            const formData = new FormData();
            // eslint-disable-next-line array-callback-return
            formik.values.files.map((file: any) => {
              formData.append("files[]", file);
            });
            PostInstanteFormData(
              null,
              formData,
              "/absences/" +
                response.data.data.absence_item_id +
                "/upload_files"
            )
              .then(() => {
                dispatch(updateLoading(false));
                window.history.back();
                toast.success(response?.data?.message);
              })
              .catch((e: any) => {
                if (e.code !== "ERR_CANCELED") {
                  RemoveInstance(id, "/absences")
                    .then(() => {
                      dispatch(updateLoading(false));
                      window.history.back();
                    })
                    .catch((e: any) => {
                      GenericError(e, dispatch);
                    });
                }
              });
          } else {
            dispatch(updateLoading(false));
            window.history.back();
            toast.success(response?.data?.message);
          }
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });
  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/absences_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/leave" },
            { label: "Afastamentos", value: "/request/leave" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent user={user} />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar afastamento
              </Typography>
            </Grid>
            <Form formik={formik} />
            <InputFile formik={formik} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                onSubmit={() => {
                  setValide(true);
                }}
                endIcon={<AddCircle />}
              >
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
