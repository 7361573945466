/** @format */

import { Edit, Undo, Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrencyInt,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { IsPermissions } from "../../../../../helpers/isPermission";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { HistoryComponent } from "../helpers/historyComponent";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { LabelFormat } from "../../../../../helpers/labelFormat";
import { position_type } from "../../../../../config/constants";
import { ConfirmSalaryDialog } from "../helpers/confirmSalaryDialog";
import { getSalaryRangeByJobRole } from '../helpers/getSalaryRangeByJobRole';

const validationSchema = yup.object({
  function: yup.string().nullable().required("Função é obrigatório"),
  quantity: yup
    .number()
    .nullable()
    .min(1, "Quantidate mínima: 1")
    .required("Quantidade é obrigatório"),
  salary: yup.string().nullable().required("Remuneração é obrigatório"),
  description: yup
    .string()
    .nullable()
    .required("Responsabilidades é obrigatório"),
});

export function PositionDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({ solicitation_data: {} });
  const [salaryDialogSettings, setSalaryDialogSettings]: any = useState({});
  const [options, setOptions] = useState<any>({
    manager: [],
    business_unit: [],
    department: [],
    cost_center: [],
    reason_solicitation: [],
    job_role: [],
  });

  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPositionDetailsEdit") !== -1 &&
      (data?.solicitation_data.status === "pending" ||
        data?.solicitation_data.status === "waiting_rh_user" ||
        data?.solicitation_data.status === "accounting_return")) ||
    isResendable;

  const formik = useFormik({
    initialValues: {
      function: "",
      salary: "",
      quantity: "",
      description: "",
      business_unit_id: "",
      cost_center_id: "",
      reason_solicitation_id: "",
      manager_id: "",
      department_id: "",
      job_role_id: "",
      position_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values, null, true, true);
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/new_positions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData(data);
        formik.setValues({
          function: data.record.name,
          quantity: data.record.quantity,
          salary: data.record.salary,
          description: data.record.observations,
          business_unit_id: data.record.business_unit_id,
          cost_center_id: data.record.cost_center_id,
          reason_solicitation_id: data.record.reason_solicitation_id,
          manager_id: data.record.manager_id,
          department_id: data.record.department_id,
          job_role_id: data.record.job_role_id,
          position_type: `${data.record.position_type}`,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/new_positions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          ...response.data.data.options,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/reason_solicitations")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions((prevState: any) => ({
          ...prevState,
          reason_solicitation: response.data,
        }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any, callback: any, hasToast: boolean = false, historyBack: boolean = false) => {
    const request = () => {
      dispatch(updateLoading(true));
      PutInstante(
        id,
        {
          ...values,
          position_type: Number(values.position_type),
          salary: ClearCurrencyInt(values.salary),
        },
        "/new_positions"
      )
        .then((response: any) => {
          if (callback) {
            callback();
          } else {
            dispatch(updateLoading(false));
          }
          if (historyBack) window.history.back();
          if (hasToast) toast.success(response?.data?.message);
          
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }

    const { maximum, minimum } = getSalaryRangeByJobRole(formik.values.job_role_id, options?.job_role);
    if (user.role === "rh" &&
      ((minimum && Number(ClearCurrencyInt(`${minimum}`)) > Number(ClearCurrencyInt(formik.values.salary))) ||
        (maximum && Number(ClearCurrencyInt(`${maximum}`)) < Number(ClearCurrencyInt(formik.values.salary))))) {

      setSalaryDialogSettings({
        open: true,
        maximum: FormatCurrency({ target: { value: `${maximum}` } }),
        minimum: FormatCurrency({ target: { value: `${minimum}` } }),
        onConfirm: () => request()
      });
    }
    else {
      request();
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Requisições", value: "/request/position" },
            { label: "Posições", value: "/request/position" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent solicitation={data.solicitation_data} />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da posição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <AutoCompleteField
                disabled={!edit || user.role === "manager"}
                id="manager_id"
                label="Gestor"
                formik={formik}
                options={options.manager.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
              <AutoCompleteField
                id="department_id"
                disabled={!edit}
                label={LabelFormat("department")}
                formik={formik}
                options={options.department.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="cost_center_id"
                disabled={!edit}
                label="Centro de custo"
                formik={formik}
                options={options.cost_center.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.description}`,
                }))}
              />
              <InputTextField
                formik={formik}
                id="function"
                disabled={!edit}
                label="Função" />
              <InputTextField
                type="number"
                formik={formik}
                id="quantity"
                disabled={!edit}
                label="Quantidade"
              />
              <AutoCompleteField
                id="business_unit_id"
                disabled={!edit}
                label={LabelFormat("business_unit")}
                formik={formik}
                options={options.business_unit?.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />

              <AutoCompleteField
                id="job_role_id"
                disabled={!edit || user.role !== "rh"}
                label="Cargo"
                onChange={(_e: any, newValue: any) => {
                  const { minimum = null } = getSalaryRangeByJobRole(newValue.value, options?.job_role);
                  formik.setFieldValue('salary', FormatCurrency({ target: { value: `${minimum}` } }));
                }}
                formik={formik}
                options={options?.job_role?.map((jobRole: any) => ({
                  ...jobRole,
                  label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}` : jobRole.label,
                  value: jobRole.value,
                }))}
              />
              <InputCurrencyField
                disabled={!formik?.values?.job_role_id || !edit || user.role !== "rh"}
                formik={formik}
                id="salary"
                label="Remuneração"
              />
              <AutoCompleteField
                id="reason_solicitation_id"
                disabled={!edit}
                label="Motivo da solicitação"
                formik={formik}
                options={options.reason_solicitation?.map((item: any) => ({
                  value: item.id,
                  label: `${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="position_type"
                disabled={!edit}
                label="Tipo de posição"
                formik={formik}
                options={position_type}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <InputTextField
                formik={formik}
                multiline={true}
                rows={4}
                id="description"
                disabled={!edit}
                label="Responsabilidades"
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"new_positions"}
                  disabled={!formik.isValid}
                  onSubmit={(callback: any) => onSubmit(formik.values, callback)}
                />
              )}
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              {data.solicitation_data.status === "waiting_rh_user" && (
                <>
                  {edit && (
                    <ButtonComponent
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Edit />}
                    >
                      Editar
                    </ButtonComponent>
                  )}
                </>
              )}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}
              >
                <ActionReturned id={id} url={"new_positions"} />
                <ActionDisapproved id={id} url={"new_positions"} />
                <ActionAproved
                  id={id}
                  url={"new_positions"}
                  beforeAction={(callback: any) => onSubmit(formik.values, callback)}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["approved"]}
                managerAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}
              >
                <IsPermissions keyPrivate={"requestPositionDetailsView"}>
                  <ButtonComponent
                    onClick={() =>
                      router("/request/position/details/letter/" + id)
                    }
                    variant={"contained"}
                    type={"submit"}
                    endIcon={<Visibility color="primary" />}
                  >
                    Ver carta de comunicação
                  </ButtonComponent>
                </IsPermissions>
              </StatusPermittedByRoleContainer>
            </Grid>

            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={options}
            />
          </Paper>
        </Grid>
      </Grid>
      <ConfirmSalaryDialog
        open={salaryDialogSettings?.open}
        onConfirm={salaryDialogSettings?.onConfirm}
        minSalary={salaryDialogSettings?.minimum}
        maxSalary={salaryDialogSettings?.maximum}
        onClose={() => setSalaryDialogSettings({})} />
    </form>
  );
}
