/** @format */

export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "job_role_name", label: "Cargo" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "initial_date", label: "Data do início pretendida" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "has_new_position", label: "Nova posição ?" },
  { name: "solicitation_status", label: "Status" },
];
