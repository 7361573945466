/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../../../components/buttonComponent";
import { GenericError } from "../../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { recruitment_types } from "../../../../../../../config/constants";
import { InputTextField } from "../../../../../../../components/inputs/inputTextField";
import { AutoCompleteField } from "../../../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../../../components/inputs/inputDateField";
import { InputCurrencyField } from "../../../../../../../components/inputs/inputCurrencyField";

export function PersonalForExistingPositionNew() {
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState<any>({
    job_role: [],
    position: [],
  });
  const params = new URLSearchParams(window.location.search);
  const position = params.get("position");

  const user = useSelector((state: any) => state.user.data);
  const companySettings = useSelector((state: any) => state.user.companySettings);
  const requiredFields = user.role === "manager"
    ? companySettings?.personnel_requisitions_rules?.manager_fields ?? []
    : companySettings?.personnel_requisitions_rules?.rh_fields ?? [];

  const formik = useFormik({
    initialValues: {
      job_role_id: "",
      job_role_group_id: "",
      position_id: "",
      salary: "",
      initial_date: null,
      vacancy_date: null,
      recruitment_type: '',
      observations: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema({ userRole: user.role, options: options, companySettings: companySettings }),
    onSubmit: (values) => {
      setValide(false)
      dispatch(updateLoading(true));
      PostInstante(null, { ...values, has_new_position: false }, "/personnel_requisitions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data.data.options);
        if (position) {
          PostInstante(null, null, `/positions/${position}/current_position`)
            .then((res) => {
              formik.setFieldValue("position_id", res.data.id);
              formik.setFieldValue("job_role_id", res.data.job_role_id);
              formik.setFieldValue("salary", res.data.salary);
              dispatch(updateLoading(false));
            })
            .catch((e) => {
              GenericError(e, dispatch);
            });
        }
        else {
          dispatch(updateLoading(false));
        }
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid width={"inherit"}>
        <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
          {position ? (
            <InputTextField
              formik={formik}
              label={"Posição"}
              id={"position_id"}
            />
          ) : (
            <AutoCompleteField
              formik={formik}
              onChange={(event: any, newValue: any) => {
                const value: any = options?.position.find(
                  (p: any) => p.id === newValue.value
                );

                formik.setFieldValue("job_role_id", value?.job_role_id);
                formik.setFieldValue("salary", value?.salary);
                formik.setFieldValue("vacancy_date", null);
              }}
              label="Posição"
              id="position_id"
              options={options?.position?.map((item: any) => ({
                value: item.id,
                label: `${item.code} - ${item.job_role}`,
              }))}
            />
          )}

          <AutoCompleteField
            formik={formik}
            id="recruitment_type"
            label="Tipo de recrutamento"
            options={recruitment_types}
          />

          {options?.position.find((position: any) => position.id === formik.values.position_id)?.status === 'occupied' &&
            <InputDateField
              formik={formik}
              id="vacancy_date"
              label="Data prevista para desocupação da posição"
            />
          }

          {user.role === "rh" && <>
            <AutoCompleteField
              formik={formik}
              id="job_role_id"
              label="Cargo"
              options={options?.job_role?.map((jobRole: any) => ({
                label: jobRole?.code ? `${jobRole?.code} - ${jobRole.label}` : jobRole.label,
                value: jobRole.value,
              }))}
            />
          </>}

          {(user.role !== 'manager' || requiredFields.includes("salary")) && (
            <InputCurrencyField formik={formik} id="salary" label="Remuneração" />
          )}

          {requiredFields.includes("initial_date") && (
            <InputDateField
              formik={formik}
              id="initial_date"
              label="Data prevista de início"
            />
          )}

        </Grid>
        <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
          <InputTextField
            formik={formik}
            id="observations"
            label="Responsabilidades"
            multiline={true}
            rows={4}
          />
        </Grid>
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            type={"submit"}
            endIcon={<AddCircle />}>
            Cadastrar
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
