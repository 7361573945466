/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { RemoveInstance } from "../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  id: string | number | null | undefined;
  url: string;
  statusList: string;
  close?: () => void;
  beforeAction?: (onSubmit: any) => void;
}

export function ActionRemove({
  id,
  url,
  statusList,
  close,
  beforeAction,
}: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const user = useSelector((state: any) => state.user.data);
  const userPermission = user?.role 
  const userRh = userPermission === "rh" || userPermission === "admin";
  const statusDiv: any =
    statusList === "Pendente" ||
    statusList === "Aberta" ||
    statusList === "Aguardando gestor" ||
    (statusList === "Aguardando RH" && userRh);

  function onDelete() {
    close && close();
    setOpen(false);
    dispatch(updateLoading(true));
    RemoveInstance(id, url)
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }
  return (
    statusDiv && (
      <form>
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
          <AlertDialog.Trigger>
            <Tooltip title='Cancelar'>
              <IconButton>
                <Cancel color='error' />
              </IconButton>
            </Tooltip>
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth='450px'>
            <AlertDialog.Title>
              Deseja realmente cancelar esta solicitação?
            </AlertDialog.Title>
            <AlertDialog.Description size='2'>
              Ao cancelar, está solicitação não poderá mais ser reaberta e será
              irreversível. Caso deseje realizar essa solicitação no futuro,
              deve ser feito todo o processo novamente.
            </AlertDialog.Description>
            <Flex gap='3' mt='4' justify='end'>
              <AlertDialog.Cancel>
                <ButtonComponent
                  variant={"outlined"}
                  color={"error"}
                  endIcon={<Cancel />}>
                  Desistir
                </ButtonComponent>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <ButtonComponent
                  variant={"contained"}
                  onClick={beforeAction ? beforeAction(onDelete) : onDelete}
                  color='success'
                  endIcon={<CheckCircle />}>
                  Confirmar
                </ButtonComponent>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    )
  );
}
