import MUIDataTable from "mui-datatables";
import { InfoComponent } from "../../../../components/infoComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { columnsError } from "./constants";
import { Grid } from "@mui/material";
import useUploadError from "../../../../services/protected/hooks/useUploadError";

export function TabUploadError() {
  const {
    data,
    setOrder,
    setPage,
    setPerPage,
    searchCall,
    total,
    perPage,
    page,
  } = useUploadError();
  return (
    <Grid container gap={2}>
      <InfoComponent instruction="Verifique todos os documentos já desmembrados e com falhas." />
      <MUIDataTable
        title={""}
        data={data}
        columns={[...columnsError]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por tipo do documento",
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page: page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
