/** @format */

import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import {
  ClearCurrency,
  ClearCurrencyInt,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { GradeItemPositionPercentage } from "../../../../../helpers/gradeitemPositionPercentage";

export function Form({ formik, data, edit = true, companySettings }: any) {
  const user = useSelector((state: any) => state.user.data);
  const isRh = user.role === "rh";
  const isAccountant = user.role === "accountant";

  const { fields: requiredFields = [] } =
    companySettings?.promotion_requisitions_rules || {};

  useEffect(() => {
    const jobRole = data.options?.job_role?.find(
      (item: any) => item.value === formik.values?.target_job_role_id
    );
    const salary = Number(ClearCurrencyInt(formik.values?.target_salary));
    const gradeItemsPercentage = GradeItemPositionPercentage(
      jobRole?.grade_items,
      salary
    );

    if (
      formik.values?.grade_item_position_percentage !== gradeItemsPercentage
    ) {
      formik.setFieldValue(
        "grade_item_position_percentage",
        gradeItemsPercentage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values?.target_job_role_id,
    formik.values?.target_salary,
    data.options?.job_role,
  ]);

  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <SelectField
          options={aviso_e_social}
          formik={formik}
          id="promote_position"
          label="A promoção será para a mesma posição?"
          disabled={!edit}
          onChange={(event: any) => {
            if (event.target.value === true) {
              const option =
                data.options.positions.filter(
                  (item: any) => item.value === data.user_data.position_id
                )[0] ?? {};
              formik.setFieldValue(
                "target_position_id",
                data.user_data.position_id
              );
              formik.setFieldValue(
                "target_position",
                `${data.user_data.position_code} - ${data.user_data.position_name}`
              );
              formik.setFieldValue("target_job_role_id", option.job_role_id);
              formik.setFieldValue("target_sector_id", option.sector_id);
              formik.setFieldValue(
                "target_cost_center_id",
                option.cost_center_id
              );
              formik.setFieldValue(
                "target_business_unit_id",
                option.business_unit_id
              );
            }
          }}
        />
        {formik.values.promote_position ? (
          <InputTextField
            formik={formik}
            disabled
            id="target_position"
            label="Posição destino"
          />
        ) : (
          <AutoCompleteField
            formik={formik}
            id="target_position_id"
            label="Posição destino"
            disabled={formik.values.promote_position || !edit}
            options={data.options.positions.map((option: any) => ({
              ...option,
              value: option.value,
              label: `${option.code} - ${option.job_role_name}`,
            }))}
            onChange={(event: any, newValue: any) => {
              const option =
                data.options.positions.filter(
                  (item: any) => item.value === newValue.value
                )[0] ?? {};
              formik.setFieldValue("target_job_role_id", option.job_role_id);
              formik.setFieldValue("target_sector_id", option.sector_id);
              formik.setFieldValue(
                "target_cost_center_id",
                option.cost_center_id
              );
              formik.setFieldValue(
                "target_business_unit_id",
                option.business_unit_id
              );
            }}
          />
        )}
        <AutoCompleteField
          formik={formik}
          disabled={
            (formik.values.promote_position !== true &&
              formik.values.promote_position !== undefined &&
              formik.values.promote_position !== null) ||
            !edit
          }
          id="target_job_role_id"
          label="Cargo destino"
          options={data.options?.job_role?.map((jobRole: any) => ({
            label: jobRole?.code
              ? `${jobRole?.code} - ${jobRole.label}`
              : jobRole.label,
            value: jobRole.value,
          }))}
        />
        <InputDateField
          formik={formik}
          id="movement_date"
          disabled={!edit}
          label="Data da movimentação"
        />
        <InputTextField
          formik={formik}
          disabled={!edit}
          id="percent"
          type={"number"}
          label="Porcentagem (%)"
          onChange={(event: any) => {
            if (event.target.value.length === 0) {
              formik.setFieldValue("target_salary", MaskCurrency(0));
            } else {
              formik.setFieldValue(
                "target_salary",
                MaskCurrency(
                  ((parseFloat(event.target.value) + 100) *
                    parseFloat(formik.values.actual_salary)) /
                    100
                )
              );
            }
          }}
        />
        <InputCurrencyField
          formik={formik}
          disabled={!edit}
          id="target_salary"
          label="Salário futuro"
          onChange={(event: any) => {
            formik.setFieldValue(
              "percent",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
          }}
        />

        <InputTextField
          formik={formik}
          id="grade_item_position_percentage"
          disabled={true}
          label="Posicionamento da faixa"
        />

        {(isRh || isAccountant) && (
          <>
            {requiredFields.includes("has_medical_examination") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_medical_examination"}
                label="Exame médico ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_benefits_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_benefits_change"}
                label="Mudança de benefícios ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_union_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_union_change"}
                label="Mudança de sindicato ?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_union_change") &&
              formik?.values?.has_union_change && (
                <AutoCompleteField
                  formik={formik}
                  disabled={!edit}
                  labelcode={true}
                  id="union_id"
                  label="Sindicato"
                  options={data.options.union}
                />
              )}
            {requiredFields.includes("has_work_schedule_change") && (
              <SelectField
                formik={formik}
                disabled={!edit}
                id={"has_work_schedule_change"}
                label="Mudança na jornada de trabalho?"
                options={aviso_e_social}
              />
            )}
            {requiredFields.includes("has_work_schedule_change") &&
              formik?.values?.has_work_schedule_change && (
                <AutoCompleteField
                  formik={formik}
                  disabled={!edit}
                  labelcode={true}
                  id="work_schedule_id"
                  label="Jornada de trabalho"
                  options={data.options.work_schedule}
                />
              )}
          </>
        )}
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          disabled={!edit}
          id="observations"
          label="Comentários"
          multiline={true}
          rows={4}
        />
      </Grid>
    </>
  );
}
