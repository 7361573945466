/** @format */

import { Edit } from "@mui/icons-material";
import { Avatar, Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { updatePhoto } from "../../../../redux/reducers/user";
import { PostInstanteFormData } from "../../../../services/api";

export function PhotoPage() {
  const user = useSelector((state: any) => state.user.data);
  const photo = useSelector((state: any) => state.user.photo);
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleImageAvatar = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    if (e.target.files[0]) {
      dispatch(updateLoading(true));
      PostInstanteFormData(null, formData, `/users/${user.uuid}/upload_avatar`)
        .then((res) => {
          toast.success(res.data.message)
          dispatch(updatePhoto(res.data.data))
          setTimeout(() => {
            dispatch(updateLoading(false));
          }, 2500);
        })
        .catch((error) => GenericError(error, dispatch));
    }
  };

  return (
    <Grid>
      <InfoComponent instruction='Nesta tela é possível realizar a troca de foto de perfil. Clique em "Alterar foto" para selecionar outra.' />
      <Paper sx={{ p: 3, mt: 2 }}>
        <Grid gap={1} container flexDirection='column'>
          <Typography fontSize={18} fontWeight={600} mb={2}>
            Foto de perfil
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            justifyContent={"center"}
            mb={2}
            flexDirection='row'>
            <Grid
              item
              style={{
                width: isMobile ? "140px" : "400px",
                height: isMobile ? "140px" : "420px",
                background: `linear-gradient(to bottom left, transparent 0%, ${theme?.palette.primary.main} 150%)`,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "50%",
              }}>
              <Grid
                item
                style={{
                  width: isMobile ? "100px" : "380px",
                  height: isMobile ? "100px" : "400px",
                  background: `linear-gradient(to top right, transparent 0%, ${theme?.palette.primary.main} 200%)`,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "50%",
                }}>
                <Avatar
                  alt={user?.social_name?.length > 1 ? user?.social_name : user?.name}
                  src={photo ?? "/image.png"}
                  sx={{
                    width: isMobile ? 128 : 380,
                    height: isMobile ? 128 : 400,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <input
              style={{ display: "none" }}
              type='file'
              id='input-avatar'
              onChange={handleImageAvatar}
            />
            <label htmlFor='input-avatar'>
              <ButtonComponent
                component='span'
                endIcon={<Edit />}
                variant='contained'>
                Alterar foto
              </ButtonComponent>
            </label>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
